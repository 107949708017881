import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineDownload,
  AiOutlineFileExcel,
  AiOutlinePlus,
} from "react-icons/ai";
import {
  BsFileEarmarkCheck,
  BsFiletypeCsv,
  BsThreeDotsVertical,
} from "react-icons/bs";
import {useLocation, useNavigate} from "react-router-dom";
import {baseUrl, checkAvailability, paginationTemplate, tablePerPageOptions} from "../../Helper/helper";
import useData from "../../hooks/useData";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { VscPreview } from "react-icons/vsc";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { BiMapPin } from "react-icons/bi";
import { MdOutlineSpeakerNotesOff } from "react-icons/md";
import SectionHeading from "../SectionHeading/SectionHeading";

const FoodAllowance = () => {
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const { token } = useData().store;
  const { authPermissions } = useData().permissions;
  const [update, setUpdate] = useState(false);
  const [foodAllowances, setFoodAllowances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allowanceLocation, setAllowanceLocation] = useState({});
  const [showMap, setShowMap] = useState(false);
  const [selectedAllowances, setSelectedAllowances] = useState([]);
  const rowClick = false;
  const menuRight = useRef(null);

  //Pagination
  const [limitData, setLimitData] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const webLocation = useLocation();
  const searchPage = webLocation?.search?.split("=")[1];


  const [searchData, setSearchData] = useState({
    start_date: "",
    end_date: "",
    name: "",
    amount_start_range: "",
    amount_end_range: "",
    allowance_status: "",
  });
  const [searchedData, setSearchedData] = useState({
    start_date: "",
    end_date: "",
    name: "",
    amount_start_range: "",
    amount_end_range: "",
    allowance_status: "",
  });
  const [searched, setSearched] = useState();


  // Set default page query 1
  useEffect(() => {
    if(!searchPage){
      navigate(`?page=1`)
    }
    else{
      const calculatePage = (searchPage * limitData)-limitData
      setPageNumber(calculatePage)
    }

  }, [searchPage, navigate, limitData]);
  const handleSearchData = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };
  const isGetFoodAllowance = checkAvailability(
    authPermissions,
    "get-food-allowance"
  );
  const isCreateDeleteAllowance = checkAvailability(
    authPermissions,
    "create-delete-food-allowance"
  );
  const isAllowanceStatusUpdate = checkAvailability(
    authPermissions,
    "change-food-allowance-status"
  );
  const isExportFoodAllowance = checkAvailability(
    authPermissions,
    "export-food-allowance"
  );
  const isPaySlipDownload = checkAvailability(
    authPermissions,
    "food-allowance-payment-slip"
  );
  const toast = useRef(null);
  const callToast = (type, detail) => {
    toast.current.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    if (!isGetFoodAllowance) {
      return;
    }
    const {
      start_date,
      end_date,
      name,
      amount_start_range,
      amount_end_range,
      allowance_status,
    } = searchedData;
    const getAllowances = async () => {
      let food_url = `${baseUrl.url}/api/food-allowances/filter?per_page=${limitData}`;
      if (
        start_date ||
        end_date ||
        name ||
        amount_start_range ||
        amount_end_range ||
        allowance_status
      ) {
        food_url = `${
          baseUrl.url
        }/api/food-allowances/filter?page=${searchPage}&per_page=${limitData}${
          start_date && `&start_date=${start_date}`
        }${start_date && end_date && `&end_date=${end_date}`}${
          name && `&search=${name}`
        }${
          amount_start_range &&
          amount_end_range &&
          `&amount_start_range=${amount_start_range}`
        }${amount_end_range && `&amount_end_range=${amount_end_range}`}${
          allowance_status && `&status=${allowance_status}`
        }`;
      }
      setLoading(true);
      try {
        const res = await fetch(food_url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setFoodAllowances(data?.data);
          setSearched(data?.search);
        }
      } catch (error) {
        console.error(error?.message);
      } finally {
        setLoading(false);
      }
    };
    getAllowances().then();
  }, [token, isGetFoodAllowance, update, searchedData, limitData, searchPage]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchedData(searchData);
    navigate(`?page=1`)
  };

  const dateTemplate = (data) => {
    const dateString = data?.occurred_on;
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  };

  const statuses = [
    { name: "Pending", code: 0 },
    { name: "Paid", code: 1 },
    { name: "Rejected", code: 2 },
  ];

  const statusTemplate = (data) => {
    const status = data?.allowance_status;
    const id = data?.id;

    return (
      <div>
        {status === 0 ? (
          <>
            {isAllowanceStatusUpdate ? (
              <select
                defaultValue={status}
                onChange={(e) => handleChangeStatus(id, e.target.value)}
                className="bg-white focus:p-1 focus:outline-slate-500 dark:bg-slate-800 dark:text-white"
              >
                {statuses?.map((status, i) => (
                  <option key={i} value={status?.code}>
                    {status?.name}
                  </option>
                ))}
              </select>
            ) : (
              <p className="text-green-600">Pending</p>
            )}
          </>
        ) : status === 1 ? (
          <p className="text-green-600">Paid</p>
        ) : (
          <p className="text-red-500">Rejected</p>
        )}
      </div>
    );
  };
  const mapTemplate = (data) => {
    const { lat, lng } = data;

    return (
      <button
        onClick={() => {
          setAllowanceLocation({ lat, lng });
          setShowMap(!showMap);
        }}
      >
        {" "}
        <BiMapPin />
      </button>
    );
  };

  const actionTemplate = (data) => {
    const document = data?.document;
    const id = data?.id;
    return (
      <div className="flex items-center h-full">
        <div className="flex">
          <button
            onClick={() => handleViewDocument(document)}
            className="bg-sky-600 text-white font-semibold
      p-2"
          >
            <VscPreview />
          </button>
          <button
            className="bg-sky-500 hover:bg-red-500 text-white font-semibold p-2"
            onClick={() => handleDelete(id)}
          >
            <AiOutlineDelete />
          </button>
        </div>
      </div>
    );
  };

  const handleViewDocument = (document) => {
    if (document) {
      window.open(`${baseUrl.url}/${document}`);
    } else {
      callToast(false, "No document found");
    }
  };
  const handleChangeStatus = async (id, statusId) => {
    const updatedStatus = {
      status: statusId,
      allowances: [id],
    };

    try {
      const res = await fetch(
        `${baseUrl.url}/api/food-allowances/update-status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedStatus),
        }
      );
      const resData = await res.json();

      if (res.ok) {
        callToast(true, "Allowance Status updated successfully");
        setUpdate(!update);
      } else {
        callToast(false, resData?.error);
      }
    } catch (err) {
      callToast(false, err?.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await fetch(
        `${baseUrl.url}/api/food-allowances/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        callToast(true, "Allowance deleted successfully");
      } else {
        callToast(false, resData?.error);
      }
    } catch (err) {
      callToast(false, err?.message);
    }
  };

  const handleExportData = (type) => {
    if (!isExportFoodAllowance) {
      return;
    }
    const random_number = new Date().getTime();
    const exportData = async () => {
      const res = await fetch(
        `${baseUrl.url}/api/food-allowances/export/all?type=${type}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.blob();
      if (res.ok) {
        callToast(true, `Food  allowances ${type} download successfully`);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `food_allowances_${random_number}.${type}`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        callToast(false, data?.error);
      }
    };
    exportData().then();
  };

  let allItems = [
    {
      label: "Add FA",
      icon: <AiOutlinePlus />,
      permission: "create-delete-food-allowance",
      command: () => navigate("/add-fa"),
    },
    {
      label: "Export",
      permission: "export-food-allowance",
      items: [
        {
          label: "XLSX",
          icon: <AiOutlineFileExcel />,
          command: () => handleExportData("xlsx"),
        },
        {
          label: "CSV",
          icon: <BsFiletypeCsv />,
          command: () => handleExportData("csv"),
        },
      ],
    },
  ];
  const items = allItems?.filter((item) =>
    authPermissions?.find((perm) => perm?.name === item?.permission)
  );

  const itemsRight = [
    {
      items: [
        {
          label: "Download payslip",
          permission: "food-allowance-payment-slip",
          icon: <AiOutlineDownload />,
          command: async () => {
            if (!isPaySlipDownload) {
              return;
            }
            let downLoadSlipIds = [];
            const createdBy = selectedAllowances[0]?.created_by;
            for (let i = 0; i < selectedAllowances.length; i++) {
              const element = selectedAllowances[i];
              if (createdBy !== element?.created_by) {
                callToast(
                  false,
                  "At a time you can download only one person payslip"
                );
                return;
              }
              downLoadSlipIds.push(element.id);
            }
            const newObj = {
              allowances: downLoadSlipIds,
            };
            try {
              const res = await fetch(
                `${baseUrl.url}/api/food-allowance-payment-slip`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${token}`,
                  },
                  body: JSON.stringify(newObj),
                }
              );
              const data = await res.blob();
              if (res.ok) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `payslip.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                callToast(true, "Payslip download successfully");
                setSelectedAllowances([]);
              } else {
                callToast(false, "Payslip download failed");
              }
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "Mark as Paid",
          permission: "change-food-allowance-status",
          icon: <BsFileEarmarkCheck />,
          command: async () => {
            if (!isAllowanceStatusUpdate) {
              return;
            }
            let allowances = [];
            for (let i = 0; i < selectedAllowances.length; i++) {
              const element = selectedAllowances[i];
              if (element?.allowance_status !== 0) {
                callToast(
                  false,
                  "You can change only pending allowance status."
                );
                return;
              }
              allowances.push(element.id);
            }
            const newObj = {
              status: 1,
              allowances: allowances,
            };
            try {
              const res = await fetch(
                `${baseUrl.url}/api/food-allowances/update-status`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${token}`,
                  },
                  body: JSON.stringify(newObj),
                }
              );
              if (res.ok) {
                setUpdate(!update);
                callToast(true, "Allowance Status Updated Successfully");
                setSelectedAllowances([]);
              } else {
                const resData = await res.json();
                callToast(false, resData?.error);
              }
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "Mark as Rejected",
          permission: "change-food-allowance-status",
          icon: <MdOutlineSpeakerNotesOff />,
          command: async () => {
            if (!isAllowanceStatusUpdate) {
              return;
            }
            let allowances = [];
            for (let i = 0; i < selectedAllowances.length; i++) {
              const element = selectedAllowances[i];
              if (element?.allowance_status !== 0) {
                callToast(
                  false,
                  "You can change only pending allowance status."
                );
                return;
              }
              allowances.push(element.id);
            }
            const newObj = {
              status: 2,
              allowances: allowances,
            };
            try {
              const res = await fetch(
                `${baseUrl.url}/api/food-allowances/update-status`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${token}`,
                  },
                  body: JSON.stringify(newObj),
                }
              );
              if (res.ok) {
                setUpdate(!update);
                callToast(true, "Allowance Status Updated Successfully");
                setSelectedAllowances([]);
              } else {
                const resData = await res.json();
                callToast(false, resData?.error);
              }
            } catch (error) {
              console.error(error);
            }
          },
        },
      ],
    },
  ];

  const modifyMenuData = itemsRight?.map((menu) => {
    const items = menu?.items?.filter((item) =>
      authPermissions?.find((perm) => perm?.name === item?.permission)
    );
    return {
      items: items?.length > 0 ? items : [],
    };
  });

  const totalAmount = () => {
    return selectedAllowances?.reduce((acc, data) => {
      return data?.amount + acc
    }, 0)
  }
  return (
    <div>
      <Toast ref={toast} className="z-50" />
      <>
        <Dialog
          header="Locations"
          visible={showMap}
          modal={false}
          // style={{ width: "50vw" }}
          className="w-11/12 sm:w-10/12 xl:w-8/12 2xl:w-2/4"
          onHide={() => {
            setShowMap(false);
            setAllowanceLocation({});
          }}
        >
          {allowanceLocation?.lat && allowanceLocation?.lng ? (
            <iFrame
              className={"w-full"}
              style={{ height: "70vh" }}
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?q=${allowanceLocation?.lat},${allowanceLocation?.lng}&key=AIzaSyBwhrTgTkNHAoeXcPVaJZ8TZUoDqINXFmI`}
            ></iFrame>
          ) : (
            <div>
              <div>
                <img
                  src="https://s1.eestatic.com/2015/10/12/elandroidelibre/el_androide_libre_71003130_179986200_1706x960.jpg"
                  className="w-3/4 lg:w-1/4 mx-auto"
                  alt=""
                />
              </div>
              <h1 className="text-xl text-center">No location found</h1>
              <p className="text-base text-center">
                No coordinates added or found nothing
              </p>
            </div>
          )}
        </Dialog>
        <div className="flex items-center justify-between">
          <SectionHeading
            title={"Food Allowance"}
            text={"Food allowances are detailed bellow"}
          />

          {items?.length > 0 && (
            <div className="flex justify-end">
              <div className="zero">
                <Button
                  icon={<BsThreeDotsVertical className="dark:text-white" />}
                  onClick={(event) => menuLeft.current.toggle(event)}
                  aria-controls="popup_menu_left"
                  aria-haspopup
                />
              </div>
              <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
            </div>
          )}
        </div>

        <div className="w-full grid grid-cols-12 my-3">
          <form onSubmit={handleSearch} className="col-span-12 2xl:col-span-11">
            <div className="w-full lg:flex lg:flex-wrap items-center gap-2">
              <div className=" my-2 xl:my-0">
                <input
                  type="text"
                  name="name"
                  defaultValue={searched?.name}
                  onChange={(e) => handleSearchData(e)}
                  className="bg-white w-full text-sm border p-2 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white"
                  placeholder="Search by name"
                />
              </div>
              <div className=" flex lg:flex-none items-center my-2 xl:my-0">
                <input
                  type="date"
                  name="start_date"
                  defaultValue={searched?.start_date}
                  onChange={(e) => handleSearchData(e)}
                  className="bg-white w-full text-sm border px-1 py-2 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white date-input"
                />
                <span className="mx-2 dark:text-white">to</span>
                <input
                  type="date"
                  name="end_date"
                  defaultValue={searched?.end_date}
                  onChange={(e) => handleSearchData(e)}
                  className="bg-white w-full  text-sm border px-1 py-2 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white date-input"
                  disabled={!searchData?.start_date && true}
                />
              </div>

              <div className=" flex lg:flex-none items-center  my-2 xl:my-0">
                {/* <span className="text-sm">Amount</span> */}
                <input
                  type="number"
                  name="amount_start_range"
                  defaultValue={searched?.amount_start_range}
                  onChange={(e) => handleSearchData(e)}
                  className="bg-white w-full text-sm border px-1 py-2 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white"
                  placeholder="Start amount"
                />
                <span className="mx-2 dark:text-white">to</span>
                <input
                  type="number"
                  name="amount_end_range"
                  defaultValue={searched?.amount_end_range}
                  onChange={(e) => handleSearchData(e)}
                  className="bg-white w-full  text-sm border px-1 py-2 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white"
                  placeholder="End amount"
                />
              </div>
              <div className=" my-2 xl:my-0">
                <select
                  name="allowance_status"
                  defaultValue={searched?.status}
                  onChange={(e) => handleSearchData(e)}
                  className="bg-white w-full text-sm border px-1 py-2 focus:outline-none  dark:bg-gray-800 dark:text-white "
                >
                  <option className="text-gray-400">
                    Select status
                  </option>
                  {statuses?.map((status, i) => (
                    <option key={i} value={status?.code}>
                      {status?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <button
                  type="submit"
                  className=" cursor-pointer w-full text-white bg-sky-500 p-2 text-sm"
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>



              <div>
                <DataTable
                  value={foodAllowances?.data}
                  size={"small"}
                  dataKey="id"
                  showGridlines={true}
                  key={pageNumber}
                  className="datatable-responsive myTable"
                  emptyMessage="No food allownace found."
                  rowHover={true}
                  loading={loading}
                  lazy
                  paginator={foodAllowances?.total > 0 || false}
                  rows={limitData}
                  totalRecords={foodAllowances?.total || 0}
                  first={pageNumber}
                  onPage={(e) => {
                    setLimitData(e.rows);
                    let pages = Math.ceil(e.first / e.rows + 1);
                    navigate(`?page=${pages}`)

                  }}
                  paginatorTemplate={paginationTemplate}
                  pageLinkSize={10}
                  rowsPerPageOptions={tablePerPageOptions}
                  selectionMode={
                    rowClick
                      ? null
                      : modifyMenuData?.length > 0 &&
                        modifyMenuData[0]?.items?.length > 0 &&
                        "checkbox"
                  }
                  selection={selectedAllowances}
                  onSelectionChange={(e) =>
                    modifyMenuData?.length > 0 &&
                    modifyMenuData[0]?.items?.length > 0 &&
                    setSelectedAllowances(e.value)
                  }

                  footer={  <div className="w-full h-16 sm:h-10 flex flex-wrap items-center gap-4 justify-between">
                    {selectedAllowances?.length > 0 &&
                        modifyMenuData[0]?.items?.length > 0 && (
                            <div>
                              <Menu
                                  model={modifyMenuData}
                                  popup
                                  ref={menuRight}
                                  id="popup_menu_left"
                                  popupAlignment="left"
                              />
                              <Button  label={"Options"}  size={"small"}
                                       aria-controls="popup_menu_left"
                                       aria-haspopup
                                       onClick={(event) => menuRight.current.toggle(event)}/>
                            </div>
                        )}

                    {
                        selectedAllowances?.length > 0 && <div className={"flex justify-end"}>
                          <span className={" font-medium border bg-sky-500 text-sm sm:text-base p-1 sm:p-2 text-white"}>Total Amount : {totalAmount()}</span>
                        </div>
                    }


                  </div>}
                >
                  {modifyMenuData?.length > 0 &&
                    modifyMenuData[0]?.items?.length > 0 && (
                      <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "3rem" }}
                        className="dark:bg-gray-800 dark:text-white"
                      ></Column>
                    )}

                  <Column
                    header="Date"
                    body={dateTemplate}
                    className="dark:bg-gray-800 dark:text-white"
                  />
                  {foodAllowances?.length > 0 && foodAllowances?.data[0]?.name && (
                    <Column
                      header="Created By"
                      field="name"
                      className="dark:bg-gray-800 dark:text-white"
                    />
                  )}

                  <Column
                    header="Amount"
                    field="amount"
                    className="dark:bg-gray-800 dark:text-white"
                  />

                  <Column
                    header="Allowance Status"
                    body={statusTemplate}
                    className="dark:bg-gray-800 dark:text-white "
                  />
                  <Column
                    header="Map"
                    body={mapTemplate}
                    className="dark:bg-gray-800 dark:text-white"
                  />
                  {isCreateDeleteAllowance && (
                    <Column
                      header={"Action"}
                      body={actionTemplate}
                      className="flex justify-end dark:bg-gray-800 dark:text-white"
                    />
                  )}
                </DataTable>
              </div>



      </>
    </div>
  );
};

export default FoodAllowance;
