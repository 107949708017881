// import { func, number, oneOfType, string } from 'prop-types'
// import markerPin from './marker-pin.png'

const Marker = ({
  className,
  lat,
  lng,
  markerId,
  interest,
  onClick,
  ...props
}) => {
  return (
    <div className="group relative">
      <img
        className={className}
        src={
          +interest >= 0 && +interest <= 60
            ? "https://cdn-icons-png.flaticon.com/512/684/684908.png"
            : +interest > 60 && +interest <= 90
            ? "https://cdn-icons-png.flaticon.com/512/727/727606.png"
            : "https://cdn-icons-png.flaticon.com/512/149/149060.png"
        }
        // eslint-disable-next-line react/no-unknown-property
        lat={lat}
        // eslint-disable-next-line react/no-unknown-property
        lng={lng}
        onClick={(e) =>
          onClick ? onClick(e, { markerId, lat, lng, interest }) : null
        }
        style={{
          cursor: "pointer",
          fontSize: 10,
          height: "50px",
          width: "50px",
        }}
        alt={markerId}
        {...props}
      />
      <div className="hidden group-hover:block bg-white absolute bottom-full w-fit left-0 p-4">
        <h1 className="whitespace-nowrap">Name : {markerId}</h1>
        <p className="whitespace-nowrap">Interest : {interest}%</p>
      </div>
    </div>
  );
};

// Marker.propTypes = {
// 	className: string,
// 	/**
// 	 * The id of the marker.
// 	 */
// 	markerId: oneOfType([number, string]).isRequired,
// 	/**
// 	 * The latitude of the marker.
// 	 */
// 	lat: number.isRequired,
// 	/**
// 	 * The longitude of the marker.
// 	 */
// 	lng: number.isRequired,
// 	/**
// 	 * The function to call when the marker is clicked.
// 	 */
// 	onClick: func,
// }

export default Marker;
