import { Geolocation } from "@capacitor/geolocation";
import { useEffect, useState } from "react";
import { baseUrl, getToken } from "../Helper/helper";

const useGlobalContext = () => {
  // LOCAL STATES AND VARIABLES
  const [token, setToken] = useState(null);
  const [location, setLocation] = useState(null);
  const [newToken, setNewToken] = useState();
  const [isDarkMode, setIsDarkMode] = useState(false)


  // TOKEN REFRESHING FUNCTION IN USE-EFFECT TO RENDER AUTOMATICALLY AFTER 50 MINUTES
  useEffect(() => {
    const cToken = getToken();
    if (!token || token !== cToken) {
      setToken(getToken());
    }
    setNewToken(token);
    const tokenRefreshInterval = setInterval(async () => {
      try {
        const res = await fetch(`${baseUrl?.url}/api/refresh`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${newToken}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          document.cookie = `access_token=${
            resData.admin_access_token
          }; expires=${new Date(
            Date.now() + 60 * 60 * 1000
          ).toUTCString()}; path=/`;
          setNewToken(resData?.admin_access_token);
        }
      } catch (error) {
        console.error("error", error);
      }
    }, 50 * 60 * 1000);

    return () => clearInterval(tokenRefreshInterval);
  }, [newToken, token, setToken]);

  // BACK BUTTON FROM MOBILE NAVIGATION IMPLEMENTED WITH CAPACITOR
  useEffect(() => {
    document.addEventListener("ionBackButton", (ev) => {
      ev.detail.register(10, () => {
   
      });
    });
  }, []);

  // GEO LOCATION PERMISSION GOES FROM HERE IN THE PURPOSES FOR ADDING CLIENT
  useEffect(() => {
    const printCurrentPosition = async () => {
      const permission = await Geolocation.checkPermissions();
      const coordinates = await Geolocation.getCurrentPosition();
      if (permission?.location === "granted") {
        const { latitude, longitude } = coordinates.coords;
        setLocation({ latitude, longitude });
      }
    };
    printCurrentPosition().then();
  }, []);

  return { token, setToken, location, setIsDarkMode, isDarkMode };
};

export default useGlobalContext;
