import React from 'react';
import ReactApexChart from 'react-apexcharts';



const ChartFive = ({ data, isDarkMode}) => {
  //chart data get
    const transactions = data?.map(item => item.transactions);
    const monthsData = data?.map(item => item.month);

    const chartData = {
   
        options: {
          chart: {
            id: 'basic-line',
          },
          theme: {
            mode: isDarkMode ? "dark" : "light",
          },
          xaxis: {
            categories: monthsData,
          },
          tooltip: {
            theme: "dark",
            style: {
              background: "#FF5733",
              color: "#ffffff",
            },
          },
        },
        series: [
          {
            name: 'Sales',
            data: transactions,
          },
        ],
      };


  return (
    <div className=" rounded border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default sm:px-7.5 dark:bg-gray-900 dark:text-white dark:border-none">
        <h4 className="flex items-center justify-center font-bold text-xl dark:text-white pt-2 text-gray-800 ">Transactions</h4>
      <div className="line-chart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} />
    </div>
    </div>
  );
};

export default ChartFive;
