import React from "react";

const SectionHeading = ({title,text}) => {
  return (
    <div>
      <h1 className="text-base text-gray-700 dark:text-white font-semibold">
        {title}
      </h1>
      <p className="text-gray-600 text-sm dark:text-gray-200">{text}</p>
    </div>
  );
};

export default SectionHeading;
