import React, { useRef, useState } from 'react';
import useData from '../../hooks/useData';
import { baseUrl, checkAvailability } from '../../Helper/helper';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';

const FollowUpReminderModal = ({visible, setVisible, id, setId}) => {
    const {token} = useData().store
    const toast = useRef()
    const [data, setData] = useState({})

    const handleData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    } 
    const { authPermissions } = useData().permissions;
    const isCreateFollowup = checkAvailability(
        authPermissions,
        "create-client-follow-up-reminder"
      );
      const callToast = (type, detail) => {
        toast.current?.show({
          severity: type ? "success" : "error",
          summary: type ? "Success" : "Error",
          detail: detail,
          life: 4000,
        });
      };
      const handleSubmit = async() => {
        if(!isCreateFollowup){
            return
        }
        if(!data?.date_time){
            callToast(false, "Date is required")
            return
        }
          const postData = {
            client_id: id,
            followup_session: data.date_time?.split("T")?.join(" "),
            notes: data?.description || ""
          }
      
         try {
            const res = await fetch(`${baseUrl.url}/api/clients-follow-up-reminder`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(postData)
            });
            const resData = await res.json()
            if (res.ok) {
                callToast(true, "Follow up reminder added successfully");
                setTimeout(() => {
                    setVisible(false)
                }, 400)
              } else {

                callToast(false, resData?.error);
              }
        }
        catch(error){
            console.error(error)
        }
      }
    return (
        <div className="card flex flex-wrap justify-content-center w-full">
        <Toast ref={toast}/>
        <Dialog
          header="Add Follow up reminder"
          visible={visible}
          className="w-11/12 sm:w-10/12 lg:w-5/12 xl:w-1/4"
          onHide={() => {
            setVisible(false);
            setId(null)
          }}
        >
             <p className=" text-base py-2 font-semibold"></p>

         <div>
         <label className="text-gray-500 text-sm py-2 font-semibold">Date</label>
           <InputText
                type='datetime-local'
                name="date_time"
                onChange={handleData}
                className="w-full"
                placeholder='point'
              
              />
         </div>
         <div className='my-2'>
         <label className="text-gray-500 text-sm py-2 font-semibold">Notes</label>
           <InputTextarea
                type='text'
                name="description"
                onChange={handleData}
                className="w-full"
                placeholder='type here'
                rows={3} cols={30}
              />
         </div>
             <div  className='mt-4'>
            <Button onClick={handleSubmit} type="submit" size="small" label="Submit" severity="info"/>
            </div>
        </Dialog>
      </div>
    );
};

export default FollowUpReminderModal;