import { Menu } from 'primereact/menu';
import React, { useRef } from 'react';
import { BsPersonCheck } from 'react-icons/bs';
import {TbFriendsOff}from 'react-icons/tb'
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { baseUrl, checkAvailability } from '../../Helper/helper';
import useData from '../../hooks/useData';

const EmployeeStatusModal = ({visible, setVisible, toast, update, setUpdate,selectedEmployee}) => {
   
    const { token } = useData().store;
    const { authPermissions } = useData().permissions;
    const isUpdateEmployee = checkAvailability(authPermissions, "update-employee");
    const menuRight = useRef(null);
    const callToast = (type, detail) => {
      toast.current.show({
        severity: type ? "success" : "error",
        summary: type ? "Success" : "Error",
        detail: detail,
        life: 3000,
      });
    };
    const itemsRight = [
      {
        items: [
          {
            label: "Active",
            icon: <BsPersonCheck />,
            
            command: async () => {
              if (!isUpdateEmployee) {
                return;
              }
              
              const newObj = {
                is_active: 1,
                
              };
              try {
                const res = await fetch(
                  `${baseUrl.url}/api/employees-status-update/${selectedEmployee?.id}`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `bearer ${token}`,
                    },
                    body: JSON.stringify(newObj),
                  }
                );
                const resData = await res.json();
                if (res.ok) {
                  setUpdate(!update)
                  callToast(true, "Employee Status updated successfully");
                  setTimeout(() => {
                      setVisible(false)
                      setUpdate(!update)
                  }, 400)
                } else {
                  callToast(false, resData?.error);
                }
              } catch(error){
                callToast(false, error?.message)
               }
            },
          },
          {
            label: "In Active",
            icon: <TbFriendsOff />,
            command: async () => {
              if (!isUpdateEmployee) {
                return;
              }
              
              const newObj = {
                is_active: 0,
                
              };
              try {
                const res = await fetch(
                  `${baseUrl.url}/api/employees-status-update/${selectedEmployee?.id}`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `bearer ${token}`,
                    },
                    body: JSON.stringify(newObj),
                  }
                );
                const resData = await res.json();
                if (res.ok) {
                  setUpdate(!update)
                  callToast(true, "Employee Status updated successfully");
                  setTimeout(() => {
                      setVisible(false)
                      setUpdate(!update)
                  }, 400)
                } else {
                  callToast(false, resData?.error);
                }
              } catch(error){
                callToast(false, error?.message)
               }
            },
          },
          
          
        ],
      },
    ];
  return (
    <div>
         <Rodal
         visible={visible}
         onClose={() => setVisible(!visible)}
         animation={"fade"}
         customStyles={{
           minWidth:'400px',
           height: "auto",
           width: "55%",
           bottom: "auto",
           top: "50%",
           transform: "translateY(-50%)",
         }}
      >
        <div className='rodal-width p-4 text-slate-800'>
                   <h4><span className='font-bold text-lg'>{selectedEmployee?.user?.name}</span> Status Update</h4>
                  <div className="mt-3">
                    <Menu
                      model={itemsRight}
                      popup
                      ref={menuRight}
                      id="popup_menu_left"
                      popupAlignment="left"
                      className='flex gap-3'
                    />
                    <button
                      className="px-6 py-2 border bg-sky-500 rounded-md dark:text-white"
                      onClick={(event) => menuRight.current.toggle(event)}
                      aria-controls="popup_menu_left"
                      aria-haspopup
                    >
                      select Option
                    </button>
                  </div>
                
          
        </div>
          </Rodal>
    </div>
  )
}

export default EmployeeStatusModal