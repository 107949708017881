import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, {useRef, useState } from 'react';
import { baseUrl, checkAvailability} from '../../Helper/helper';
import useData from '../../hooks/useData';

const CreateRoleModal = ({visible, setVisible, role, update, setUpdate, permissions}) => {
    const [roleName, setRoleName] = useState("")
    const previousPermissions = role?.permissions
    const [selectedPermissions, setSelectedPermissions] = useState(role?.id ? previousPermissions : []);
    const {authPermissions} = useData().permissions
    const isUpdateRole=  checkAvailability(authPermissions, "update-role")
    const isCreateRole=  checkAvailability(authPermissions, "create-role")

    const toast= useRef()
    const {token} = useData().store
    const callToast = (type, detail) => {
        toast.current?.show({
          severity: type ? "success" : "error",
          summary: type ? "Success" : "Error",
          detail: detail,
          life: 3000,
        });
      };

      
      const handleSubmit = async(e) => {
        e.preventDefault()
        
        const ids = selectedPermissions?.map(permission => permission?.id)
        const postData = {
            role: roleName? roleName : role?.name,
            permissions: selectedPermissions?.length> 0 ? ids : null
        }
     if(!role?.id){
      if(!isUpdateRole){
        return
      }
      for(let i in postData){
        if(!postData[i]){
           if(i === "role"){
            callToast(false, "Role is Required")
            return
           }
          else if(i === "permissions"){
            callToast(false, "Permissions is Required")
            return
           }
        }
      }
     }
        const updatedData = {
          role: roleName? roleName : role?.name,
          permissions: ids
        }
  
       if(role?.id){
           try{
            const res = await fetch(`${baseUrl.url}/api/roles/${role?.id}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(updatedData),
            });
            const resData = await res.json();
            if (res.ok) {
              callToast(true, "Role Updated Successfully");
              setTimeout(() => {
                  setUpdate(!update)
                setVisible(false);
              }, 400);
            } else {
              callToast(false, resData?.error);
            }
           }
           catch(error){
            callToast(error?.message)
           }
       }
       else{
        if(!isCreateRole){
          return
        }
          try{
            const res = await fetch(`${baseUrl.url}/api/roles`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(postData),
            });
            const resData = await res.json();
            if (res.ok) {
              callToast(true, "Role Created Successfully");
              setTimeout(() => {
                setUpdate(!update)
                setVisible(false);
              }, 400);
            } else {
              callToast(false, resData?.error);
            }
          }
          catch(error){
            callToast(false, error?.message)
          }
       }
    }


  const handlePermission = (type, permission) => {
    if(type){
     if(selectedPermissions?.length > 1){
        selectedPermissions?.map(per => {
            if(permission?.id !== per?.id){
                const data = [...selectedPermissions, permission]
                setSelectedPermissions(data)
                return data
            }
          return permission
        })
     }
     else{
           const data = [...selectedPermissions, permission]
            setSelectedPermissions(data)
        }
    }
    else{
        const data = selectedPermissions?.filter((per) => per?.id !==permission?.id)
        setSelectedPermissions(data)
    }
  }

    return (
        <div className="card flex flex-wrap justify-content-center w-full">
        <Toast ref={toast} />
        <Dialog
          header={`${role?.id ? "Update Role": "Create Role"}`}
          visible={visible}
          className="w-11/12 sm:w-10/12 lg:w-8/12 2xl:w-1/2"
          onHide={() => {
            setVisible(false);
          }}
        >
         <form onSubmit={handleSubmit}>
         <label className="text-gray-500 text-sm p-2 font-semibold">Role Name</label>
         <InputText
              defaultValue={role?.id && role?.name}
              onChange={(e)=> setRoleName(e.target.value)}
              className="w-full"
              placeholder='Type name'
            />
            <p className='mt-4 mb-1'>Select Permissions:</p>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'>
                {
                    permissions?.map((permission, i) => {
                      const names = permission?.name?.split(("-"))
                      function capitalizeWords(arr) {
                        return arr.map(word => {
                          const firstLetter = word.charAt(0).toUpperCase();
                          const rest = word.slice(1).toLowerCase();
                          return firstLetter + rest;
                        });
                      }
                      const name = capitalizeWords(names)
                      const permission_name = name?.join(" ")
                      return (
                        <div key={i} className='flex items-center my-1 gap-2'>
                        <input type="checkbox" onClick={(e) =>handlePermission(e.target.checked, permission)} defaultChecked={previousPermissions?.find(perm => +perm.id === +permission.id) ? 'checked' : null}/>
                        <span className='whitespace-nowrap'>{permission_name}</span>
                    </div>
                      )
                    })
                }
            </div>

          <div  className='mt-4'>
          <Button type="submit" size="small" label="Submit" severity="info"/>
          </div>
         </form>
        </Dialog>
      </div>
    );
};

export default CreateRoleModal;