import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { baseUrl, checkAvailability } from "../../Helper/helper";
import useData from "../../hooks/useData";
import "rodal/lib/rodal.css";
import ReactQuill from "react-quill";
import { Dialog } from "primereact/dialog";
const AddPaymentCategoryModal = ({
  visible,
  setVisible,
  toast,
  update,
  setUpdate,
  selectedCategory,
}) => {
  const [categoryName, setCategoryName] = useState(false);
  const [categoryPrice, setCategoryPrice] = useState("");
  const [textValue, setTextValue] = useState("");
  const { token } = useData().store;
  const { authPermissions } = useData().permissions;
  const isCreateService = checkAvailability(authPermissions, "create-service");
  const isUpdateService = checkAvailability(authPermissions, "update-service");
  // const defaultValue = parse({selectedCategory?.description});
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const callToast = (type, detail) => {
    toast.current.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };
  const handleSubmit = async () => {
    const postData = {
      name: categoryName || selectedCategory?.name,
      price: categoryPrice || selectedCategory?.price,
      description: textValue || selectedCategory?.description,
    };
    if (selectedCategory?.id) {
      if (!isUpdateService) {
        return;
      }
      try {
        const res = await fetch(
          `${baseUrl.url}/api/services/${selectedCategory?.id}
            `,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData),
          }
        );
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "Payment category updated successfully");
          setTimeout(() => {
            setVisible(false);
            setUpdate(!update);
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
      } catch (error) {
        callToast(false, error?.message);
      }
    } else {
      try {
        if (!isCreateService) {
          return;
        }
        const res = await fetch(`${baseUrl.url}/api/services`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(postData),
        });
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "Payment category created successfully");
          setTimeout(() => {
            setVisible(false);
            setUpdate(!update);
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
      } catch (error) {
        callToast(false, error?.message);
      }
    }
  };

  return (
    <div className="card flex flex-wrap justify-content-center w-full">
      <Dialog
        header={selectedCategory?.id ? "Update Service" : "Add Service"}
        visible={visible}
        className="w-11/12 sm:w-10/12 lg:w-5/12 xl:w-1/2"
        onHide={() => {
          setVisible(false);
        }}
      >
        <div className="p-4 text-slate-800">
          <div>
            <label className="text-gray-500 text-sm p-2 font-semibold">
               Name
            </label>
            <InputText
              onChange={(e) => setCategoryName(e.target.value)}
              className="w-full"
              placeholder="Type name"
              defaultValue={selectedCategory?.id && selectedCategory?.name}
            />
          </div>
          <div className="my-2">
            <label className="text-gray-500 text-sm p-2 font-semibold">
              Price
            </label>
            <InputText
              onChange={(e) => setCategoryPrice(e.target.value)}
              className="w-full"
              placeholder="price"
              defaultValue={selectedCategory?.id && selectedCategory?.price}
            />
          </div>
          <div className="w-full mt-2">
            <label className="text-gray-500 text-sm p-2 font-semibold">
              Details
            </label>
            <div className="">
              <ReactQuill
                theme="snow"
                className="h-auto"
                onChange={setTextValue}
                modules={modules}
                formats={formats}
                defaultValue={
                  selectedCategory?.id && selectedCategory?.description
                }
              ></ReactQuill>
            </div>
          </div>
     
          <div className="mt-4">
            <Button
              onClick={handleSubmit}
              type="submit"
              size="small"
              label={selectedCategory?.id ? "Update" : "Add"}
              severity="info"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddPaymentCategoryModal;
