import React from "react";
import "./DataLoader.css";
const DataLoader = () => {
  return (
    <div className="h-[50vh] w-full flex items-center justify-center">
      <div className="box">
        <div className="loader-18"></div>
      </div>
    </div>
  );
};

export default DataLoader;
