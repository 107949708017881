import React, { useEffect, useState } from "react";
import useData from "../../hooks/useData";
import { checkAvailability, baseUrl } from "../../Helper/helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiEdit } from "react-icons/fi";
import UpdatePointTypeModal from "../Modal/UpdatePointTypeModal";
import SectionHeading from "../SectionHeading/SectionHeading";
import DataLoader from "../DataLoader/DataLoader";

const PointTypes = () => {
  const { token } = useData().store;
  const [showModal, setShowModal] = useState(false);
  const [pointTypes, setPointTypes] = useState([]);
  const [pointType, setPointType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const { authPermissions } = useData().permissions;
  const isGetPointTypes = checkAvailability(
    authPermissions,
    "get-point-type-list"
  );
  const isUpdatePointType = checkAvailability(
    authPermissions,
    "update-point-type"
  );
  useEffect(() => {
    if (!isGetPointTypes) {
      return;
    }
    const getAllowances = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${`${baseUrl.url}/api/point-types`}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setPointTypes(data?.data);
        }
      } catch (error) {
        console.error(error?.message);
      } finally {
        setLoading(false);
      }
    };
    getAllowances().then();
  }, [token, isGetPointTypes, update]);

  const optionTemplate = (data) => {
    return (
      <>
        {
          <div className="flex justify-end items-center">
            {isUpdatePointType && (
              <button
                onClick={() => {
                  setShowModal(!showModal);
                  setPointType(data);
                }}
                className="bg-sky-500 hover:bg-sky-600 text-white font-semibold p-2"
              >
                <FiEdit />
              </button>
            )}
          </div>
        }
      </>
    );
  };
  return (
    <div>
      {loading ? (
        <DataLoader />
      ) : (
        <div>
          <SectionHeading
            title={"Point Types"}
            text={"Point Types are detailed bellow"}
          />
          {pointTypes?.length > 0 && (
            <div className="mt-4">
              <DataTable
                value={pointTypes}
                dataKey="id"
                size="small"
                sortMode="multiple"
              >
                <Column
                  header="#"
                  headerStyle={{ width: "3rem" }}
                  body={(data, options) => options?.rowIndex + 1}
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="type"
                  header="Type"
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="point"
                  header="Point"
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>

                {isUpdatePointType && (
                  <Column
                    header="Options"
                    body={optionTemplate}
                    className="flex justify-end dark:bg-gray-800 dark:text-white"
                  ></Column>
                )}
              </DataTable>
            </div>
          )}
        </div>
      )}

      {showModal && (
        <UpdatePointTypeModal
          visible={showModal}
          setVisible={setShowModal}
          pointType={pointType}
          update={update}
          setUpdate={setUpdate}
        />
      )}
    </div>
  );
};

export default PointTypes;
