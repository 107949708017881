import React, { useEffect, useRef, useState } from "react";
import CreateRoleModal from "../Modal/CreateRoleModal";
import { baseUrl, checkAvailability } from "../../Helper/helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { Toast } from "primereact/toast";
import useData from "../../hooks/useData";
import NoContent from "../NoContent/NoContent";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import SectionHeading from "../SectionHeading/SectionHeading";
import DataLoader from "../DataLoader/DataLoader";

const RoleList = () => {
  const [showModal, setShowModal] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState();
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const menuLeft = useRef(null);
  const { token } = useData().store;
  const [permissions, setPermissions] = useState([]);
  const { authPermissions } = useData().permissions;
  const isDeleteRole = checkAvailability(authPermissions, "delete-role");
  const isUpdateRole = checkAvailability(authPermissions, "update-role");
  const isCreateRole = checkAvailability(authPermissions, "create-role");
  const IsGetRoleList = checkAvailability(authPermissions, "get-role-list");
  const toast = useRef();
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  // Get role permissions
  useEffect(() => {
    const isGetPermissions=  checkAvailability(authPermissions, "get-permission-list")
    if(!isGetPermissions){
      return
    }
    const permissions = async () => {
      try{
        const res = await fetch(
            `${baseUrl.url}/api/permissions`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
        );
        const data = await res.json();
        if (res.ok) {
          setPermissions(data?.data)
        }
      }
      catch(err){
        console.error(err.message)
      }
    };
    permissions().then();
  }, [token, authPermissions]);

  useEffect(() => {
    if (!IsGetRoleList) {
      return;
    }

    const roles = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/api/roles`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setRoleList(data?.data);
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    };
    roles().then();
  }, [token, update, setUpdate, IsGetRoleList]);

  const handleDelete = async (id) => {
    if (!isDeleteRole) {
      return;
    }
    try {
      const res = await fetch(`${baseUrl.url}/api/roles/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        const newRoles = roleList?.filter((role) => role.id !== id);
        setRoleList(newRoles);
        callToast(true, "Role deleted successfully");
      } else {
        callToast(true, resData?.error);
      }
    } catch (err) {
      callToast(false, err?.message);
    }
  };

  const optionTemplate = (data) => {
    const id = data?.id;
    const role = data?.name;
    return (
      <>
        {role?.toLowerCase() !== "super admin" ? (
          <div className="flex justify-end items-center">
            {isUpdateRole && (
              <button
                onClick={() => {
                  setShowModal(!showModal);
                  setRole(data);
                }}
                className="bg-sky-600 text-white font-semibold p-2"
              >
                <FiEdit />
              </button>
            )}

            {isDeleteRole && (
              <button
                onClick={() => {
                  handleDelete(id).then();
                }}
                className="bg-sky-500 hover:bg-red-500 text-white font-semibold p-2"
              >
                <AiOutlineDelete />
              </button>
            )}
          </div>
        ) : (
          <p className="">N/A</p>
        )}
      </>
    );
  };
  let items = [
    {
      label: "Create Role",
      icon: <AiOutlinePlus />,
      command: () => {
        setShowModal(!showModal);
        setRole(null);
      },
    },
  ];
  return (
    <div>
      <Toast ref={toast} />
      {loading ? (
        <DataLoader />
      ) : (
        <>
          <div className="grid grid-cols-12 justify-between">
            <div className="col-span-6">
              <SectionHeading title="Roles" text={'Role & permission management'}/>
            </div>
            {isCreateRole && (
              <div className="col-span-6 flex justify-end">
                <div className="zero">
                  <Button
                    icon={<BsThreeDotsVertical className="dark:text-white" />}
                    className="dark:bg-gray-800 "
                    onClick={(event) => menuLeft.current.toggle(event)}
                    aria-controls="popup_menu_left"
                    aria-haspopup
                  />
                </div>
                <Menu
                  model={items}
                  popup
                  ref={menuLeft}
                  id="popup_menu_left"
                  className="dark:bg-gray-800 "
                />
              </div>
            )}
          </div>
          {roleList?.length > 0 ? (
            <div className="mt-4">
              <DataTable
                value={roleList}
                dataKey="id"
                size="small"
                sortMode="multiple"
              >
                <Column
                  className="dark:bg-gray-800 dark:text-white"
                  header="#"
                  headerStyle={{ width: "3rem" }}
                  body={(data, options) => options?.rowIndex + 1}
                ></Column>
                <Column
                  field="name"
                  header="Name"
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                {isDeleteRole || isUpdateRole ? (
                  <Column
                    header="Options"
                    body={optionTemplate}
                    className="flex justify-end dark:bg-gray-800 dark:text-white"
                  ></Column>
                ) : null}
              </DataTable>
            </div>
          ) : (
            <NoContent data="role" />
          )}

          {showModal && (
            <CreateRoleModal
              visible={showModal}
              setVisible={setShowModal}
              role={role}
              update={update}
              setUpdate={setUpdate}
              permissions={permissions}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RoleList;
