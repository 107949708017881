import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartOne = ({ clients, isDarkMode}) => {
  const clientsData = clients?.map((item) => item.clients);
  const confirmedClientsData = clients?.map((item) => item.confirmed_clients);
  const monthsData = clients?.map((item) => item.month);
  //max axis findings
  const maxClientsValue = Math.max(...clientsData);
  const maxConfirmedClientsValue = Math.max(...confirmedClientsData);
  const maxYAxisValue = Math.max(maxClientsValue, maxConfirmedClientsValue);

  const options = {
    theme: {
      mode: isDarkMode ? "dark" : "light",
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#3C50E0", "#80CAEE"],

    chart: {
      fontFamily: "Satoshi, sans-serif",
      height: 335,
      type: "area",
      dropShadow: {
        enabled: true,
        color: "#623CEA14",
        top: 10,
        blur: 4,
        left: 0,
        opacity: 0.1,
      },
    

      toolbar: {
        show: true,
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 400,
          },
        },
      },
      {
        breakpoint: 1366,
        options: {
          chart: {
            height: 350,
          },
        },
      },
    ],
    stroke: {
      width: [2, 5],
      curve: "straight",
      colors: ["transparent"]
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: true,
      
    },
    markers: {
      size: 4,
      colors: "#fff",
      strokeColors: ["#3056D3", "#80CAEE"],
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      hover: {
        size: undefined,
        sizeOffset: 5,
       
      },
    },
    xaxis: {
      type: "category",
      categories: monthsData,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: "0px",
        },
      },
      min: 0,
      max: maxYAxisValue,
    },
   
    // title: {
    //   text: 'All clients and confirm clients',
    //   align: 'center'
    // },
    tooltip: {
      theme: "dark",
      style: {
        background: "#FF5733",
        color: "#ffffff",
      },
    },
  };


  const series =[
    {
      name: "Clients",
      data: clientsData,
    },
    {
      name: "Confirm Clients",
      data: confirmedClientsData,
    },
  ];

  console.log("ss", isDarkMode);
  return (
    <div className=" rounded border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default sm:px-7.5 dark:bg-gray-900 dark:border-none">
      <h4 className="flex items-center justify-center font-bold text-xl dark:text-white pt-2 text-gray-800 ">
        All clients and confirm clients
      </h4>

      <div>
        <div id="chartOne" className={`rounded p-4 `}>
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
            className="dark:bg-gray-900 dark:text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default ChartOne;
