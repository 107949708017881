import React from "react";
import {classNames} from "primereact/utils";
import {Ripple} from "primereact/ripple";

export const getToken = () => {
    const tokens = document.cookie.split(";");
    return tokens
        .find((token) => token.includes("access_token"))
        ?.split("=")[1];

};

export const callToast = (toast, type, msg) => {
    toast.current?.show({
        severity: type ? "success" : "error",
        summary: type ? "Successful" : "Error",
        detail: msg,
        life: 3000,
    });

}

export const setTokenInCookie = (token) => {
    if (document.cookie) {
        document.cookie = `access_token=''`;
    }
    // let expires = (new Date(Date.now()+ 60*60*1000)).toUTCString();
    // document.cookie = `access_token=${token}; expires=${expires}; path=/`;
    document.cookie = `access_token=${token};`;
};

export const baseUrl = {
    url: process.env.REACT_APP_BASE_URL,
};

export const tablePerPageOptions = [5, 10, 15, 20, 30, 50, 70, 100]

export function checkAvailability(arr, val) {
    return arr?.some(function (arrVal) {
        return val === arrVal?.name;
    });
}

export const modifyDateTime = (data) => {
    const dateObj = new Date(data);
    return dateObj.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: 'numeric',
        minute: 'numeric',
    })

}

export const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const timeDifferenceInSeconds = Math.floor((now - date) / 1000);
    if (timeDifferenceInSeconds < 60) {
        return "Just now";
    }

    const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);

    if (timeDifferenceInMinutes < 60) {
        return `${timeDifferenceInMinutes} minute${
            timeDifferenceInMinutes > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);
    if (timeDifferenceInHours < 24) {
        return `${timeDifferenceInHours} hour${
            timeDifferenceInHours > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInDays = Math.floor(timeDifferenceInHours / 24);
    if (timeDifferenceInDays < 30) {
        return `${timeDifferenceInDays} day${
            timeDifferenceInDays > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInMonths = Math.floor(timeDifferenceInDays / 30);

    if (timeDifferenceInMonths < 12) {
        return `${timeDifferenceInMonths} month${
            timeDifferenceInMonths > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInYears = Math.floor(timeDifferenceInMonths / 12);
    return `${timeDifferenceInYears} year${
        timeDifferenceInYears > 1 ? "s" : ""
    } ago`;
};

const random_number = new Date().getTime();
export const handleExportData = async (endPoint, token, id, toast, name, msg) => {
    try {
        const res = await fetch(`${baseUrl.url}/api/${endPoint}/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await res.blob();
        if (res.ok) {
            callToast(toast, true, msg);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${name}_${random_number}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else {
            callToast(false, data?.error);
        }
    } catch (err) {
        callToast(false, err?.message);
    }
};


//Pagination Template
export const paginationTemplate = {
    layout: "CurrentPageReport FirstPageLink PrevPageLink PageLinks  NextPageLink LastPageLink  RowsPerPageDropdown",
    CurrentPageReport: (options) => {
        return (
            <span>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
        );
    },

    PageLinks: (options) => {
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });

            return (
                <span className={className} style={{ userSelect: 'none' }}>
                        ...
                    </span>
            );
        }

        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
                <Ripple/>
            </button>
        );
    },
}