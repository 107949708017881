import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { HiBars3 } from "react-icons/hi2";
import { IoMdNotificationsOutline } from "react-icons/io";
import { baseUrl, formatTimeAgo } from "../../Helper/helper";
import useData from "../../hooks/useData";
import { SlDiamond, SlReload } from "react-icons/sl";
// import {BsMoonStars, BsSun} from "react-icons/bs";
// import { BsMoonStars, BsSun } from "react-icons/bs";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const { profile } = useData().profile;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState();
  const { token } = useData().store;
  const [showNot, setShowNot] = useState(false);
  const [update, setUpdate] = useState(false);
  const toast = useRef(null);
  // const { setIsDarkMode} = useData().store;

  
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    const getNotification = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/api/notifications`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          let arr = [];
          for (const i in data?.data) {
            const element = data?.data[i];
            arr.push(element);
          }
          setNotifications(arr);
        }
      } catch (error) {
        console.error(error?.message);
      }
    };
    getNotification().then();
    // VIBRATION ADD FUNCTION
    // if ("vibrate" in navigator) {
    //   window.navigator.vibrate(1000); // Vibrate for 1000ms (1 second)
    // }
  }, [token, update]);

  // new notification get
  // useEffect(() => {
  //   if (typeof EventSource !== "undefined") {
  //     const source = new EventSource(
  //       `${baseUrl.url}/api/new-notifications?token=${token}`
  //     );
  //     source.onmessage = function (event) {
  //       const data = event.data;
  //       const parsedData = data && JSON.parse(data);
  //       setNotifications((messages) => [parsedData, ...messages]);
  //     };
  //   } else {
  //     setNotifications([
  //       "Sorry, your browser does not support server-sent events...",
  //     ]);
  //   }
  // }, [token]);

  // DARK MODE CODE START

  // const [darkMode, setDarkMode] = useState(false);
  // useEffect(() => {
  //   const savedDarkMode = localStorage.getItem("darkMode");
  //   if (savedDarkMode) {
  //     setDarkMode(savedDarkMode === "true");
  //   } else {
  //     setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
  //   }
  // }, []);

  // console.log("mode", darkMode)
  // useEffect(() => {
  //   localStorage.setItem("darkMode", darkMode.toString());
  //   if (darkMode) {
  //     document.documentElement.classList.add("dark");
  //     setIsDarkMode(true)
  //   } else {
  //     document.documentElement.classList.remove("dark");
  //     setIsDarkMode(false)
  //   }
  // }, [darkMode, setIsDarkMode]);

  // const toggleDarkMode = () => {
  //   setDarkMode((prevMode) => !prevMode);
  // };

  // DARK MODE CODE END

  const readNotification = async (id, link) => {
    try {
      const res = await fetch(`${baseUrl.url}/api/notifications/read/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.ok) {
        setUpdate(!update);
        setShowNot(false);
        if (link) {
          navigate(link);
        }
      } else {
        const data = await res.json();
        callToast(false, data.error);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  const sliceNotification = notifications?.slice(0, 5);
  const readAllNotification = async () => {
    try {
      const res = await fetch(`${baseUrl.url}/api/notifications/read-all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.ok) {
        setUpdate(!update);
      } else {
        const data = await res.json();
        callToast(false, data.error);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  return (
    <div>
      <Sidebar show={show} setShow={setShow} />

      <div
        className={`transition-all duration-700 z-0 content-bg h-full relative ${
          show ? "ml-0" : " ml-0 lg:ml-64"
        }`}
      >
        <div className="dark:border-none flex bg-gray-100 dark:bg-gray-800 justify-between items-center sticky z-40 top-0 ">
          <div className="flex items-center gap-3 ">
            <div className="h-14 w-14 bg-gray-200 dark:bg-black flex items-center justify-center">
              <HiBars3
                size={25}
                className="text-gray-700 cursor-pointer dark:text-white relative z-50"
                onClick={() => setShow(!show)}
              />
            </div>
            <div className="flex items-center ">
              <div className="-mb-2 relative">
                <div onClick={() => setShowNot(!showNot)}>
                  <button>
                    <IoMdNotificationsOutline
                      className="text-gray-700 dark:text-white block"
                      size={23}
                    />
                    {notifications?.length > 0 && (
                      <span className="absolute -right-2 -top-1 text-xs bg-red-600 text-white px-1 rounded-full">
                        {notifications?.length}
                      </span>
                    )}
                  </button>
                </div>
              </div>
              <button
                className="mx-4 -mb-1"
                onClick={() => window.location.reload()}
              >
                <SlReload className="text-gray-700  dark:text-white block" size={18} />
              </button>
              {/*<div onClick={toggleDarkMode} className="cursor-pointer -mb-1">*/}
              {/*  {darkMode ? (*/}
              {/*    <BsSun size={18} className="text-white" />*/}
              {/*  ) : (*/}
              {/*    <BsMoonStars size={16} className="text-gray-700" />*/}
              {/*  )}*/}
              {/*</div>*/}
            </div>
          </div>

          <Link to="/profile" className="pr-2">
            <div className="flex items-center cursor-pointer">
              <div className="text-gray-700 text-xs dark:text-white mr-2 text-right">
                {profile?.name}
                <p className="text-xs flex justify-end items-center gap-1 font-bold text-gray-700 dark:text-gray-300">
                  <SlDiamond className="text-blue-500" size="15" />
                  {profile?.point_list_sum_points || "0"}
                </p>
              </div>
              <img
                className="w-8 h-8 rounded-full object-cover"
                src={`${baseUrl?.url}${profile?.avatar}`}
                alt="user"
              />
            </div>
          </Link>
        </div>
        {showNot && (
          <div className="bg-white w-11/12 sm:w-96 shadow fixed z-50 top-14 rounded">
            <div className="p-2 flex justify-between bg-gray-100">
              <p className="text-gray-800 font-semibold text-lg sm:text-xl">
                Notification
              </p>
              {notifications?.length > 0 && (
                <button
                  onClick={readAllNotification}
                  className="text-sm hover:text-sky-600"
                >
                  Mark all as read
                </button>
              )}
            </div>
            <div className="">
              {notifications?.length > 0 && (
                <div className="relative">
                  <div className="h-80 overflow-auto">
                    {sliceNotification?.map((notification, i) => {
                      // const read_at = notification?.read_at;
                      return (
                        <div
                        onClick={() =>
                          readNotification(
                            notification?.id,
                            notification?.data?.link
                          )
                        }
                        key={i}
                        className={`flex gap-2 items-start p-3 hover:bg-sky-50  ${
                          sliceNotification?.length - 1 !== i
                            ? "border-b"
                            : "mb-6"
                        }`}
                      >
                        <div>
                          <h3 className="text-white h-8 w-8 bg-blue-500 rounded-full sm:text-lg flex items-center justify-center">
                            {notification?.data?.auth_name
                              ? notification?.data?.auth_name[0]
                              : "S"}
                          </h3>
                        </div>
                        <div>
                          <p>{notification?.data?.message}</p>
                          <div className="flex items-center">
                            <span className="text-xs text-gray-500">
                              {formatTimeAgo(notification.created_at)}
                            </span>
                          </div>
                        </div>
                      </div>
                      )
                    }
                      
                    )}
                  </div>

                  <div className="absolute bottom-0 w-full bg-gray-200 py-2 flex justify-center">
                    <button className="cursor-pointer text-sm">
                      See All Notification
                    </button>
                  </div>
                </div>
              )}
            </div>
            {notifications?.length === 0 && (
              <div className="py-5">
                <p className="text-center text-red-500 font-medium">
                  No notification found
                </p>
              </div>
            )}
          </div>
        )}
        {showNot && (
          <div
            onClick={() => setShowNot(!showNot)}
            className="w-full fixed top-0 left-0 z-10 block h-screen"
          ></div>
        )}
        <div className="p-2 lg:p-3 relative overflow-auto dark:bg-gray-700">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
