import React, { useRef, useState } from "react";
import { baseUrl } from "../../Helper/helper";
import { Toast } from "primereact/toast";
import ChangePasswordModal from "../Modal/ChangePasswordModal";
import useData from "../../hooks/useData";
import { AiOutlineUpload } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { TfiEmail } from "react-icons/tfi";
import { TbListDetails } from "react-icons/tb";
import { IoLocationOutline } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import Loader from "../Loader/Loader";
import GoBack from "../GoBack/GoBack";
const Profile = () => {
  const { token } = useData().store;
  const toast = useRef();
  const [showModal, setShowModal] = useState(false);
  const { profile, loading, update, setUpdate } = useData().profile;
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    details: "",
  });
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };
  const handleChangeUserInfo = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    for (let i in userInfo) {
      if (!userInfo[i]) {
        userInfo[i] = profile[i];
      }
    }
    const res = await fetch(`${baseUrl.url}/api/update-info`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userInfo),
    });
    const resData = await res.json();
    if (res.ok) {
      callToast(true, "Profile Updated Successfully");
      setTimeout(() => {
        setUpdate(!update)
      }, 400)
    } else {
      callToast(false, resData?.error);
    }
  };
  const handleImageUpdate = async (file) => {
    const formData = new FormData();
    formData.append("avatar", file);

    const res = await fetch(`${baseUrl.url}/api/update-avatar`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const resData = await res.json();
    if (res.ok) {
      callToast(true, "Avatar Updated Successfully");
      setUpdate(!update);
    } else {
      callToast(false, resData?.error);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      {loading ? (
        <Loader />
      ) : (
        <>
        <GoBack/>
        <div className="grid grid-cols-12 items-start p-2 lg:p-5">
          <div className="col-span-12">
            <div className="w-full block lg:hidden h-36 rounded-none overflow-hidden">
              <img src="https://watermark.lovepik.com/photo/40013/7530.jpg_wh1200.jpg" alt="" className="h-full w-full object-cover" />
            </div>
            <div className="w-20 lg:w-40 h-20 lg:h-40 rounded-xl shadow-lg overflow-hidden relative group -mt-12 mx-auto lg:mx-0 lg:mt-0">
              <div className="absolute h-full w-full top-0 flex items-center opacity-5 justify-center group-hover:opacity-100 left-0">
                <div className="text-center">
                  <div className="flex items-center justify-center">
                    <AiOutlineUpload size={50} className="text-white font-black"/>
                  </div>
                  <p className="text-center text-white hidden lg:block">Upload photo</p>
                </div>
              </div>
              <img
                className="w-full h-full rounded-sm object-cover"
                src={`${baseUrl.url}${profile?.avatar}`}
                alt="admin"
              />
              <input
                type="file"
                className=" w-full h-full opacity-0 absolute z-10 top-0 left-0 cursor-pointer"
                onChange={(e) => handleImageUpdate(e.target.files[0])}
              />
            </div>
          </div>

          <div className="col-span-12 lg:col-span-8">
            <div className="flex my-3 items-center border-b border-dashed">
              <label className="mr-2"><FaRegUserCircle className="dark:text-white"/></label>
              <input
                name="name"
                onChange={(e) => handleChangeUserInfo(e)}
                className=" focus-input w-full dark:bg-gray-700 dark:text-white"
                defaultValue={profile?.name}
              />
            </div>

            <div className="flex my-3 items-center border-b border-dashed">
              <label className="mr-2"><TfiEmail className="dark:text-white"/></label>
              <input
                name="email"
                onChange={(e) => handleChangeUserInfo(e)}
                className=" focus-input w-full dark:bg-gray-700 dark:text-white"
                defaultValue={profile?.email}
              />
            </div>

            <div className="flex my-3 items-center border-b border-dashed">
              <label className="mr-2"><BsTelephone className="dark:text-white"/></label>
              <input
                name="phone"
                onChange={(e) => handleChangeUserInfo(e)}
                className=" focus-input w-full dark:bg-gray-700 dark:text-white"
                defaultValue={profile?.phone}
              />
            </div>
            <div className="flex my-3 items-center border-b border-dashed">
              <label className="mr-2"><HiOutlineUserGroup className="dark:text-white"/></label>
              <p className="dark:text-white">{profile?.roles[0]?.name}</p>
            </div>
            <div className="flex my-3 items-center border-b border-dashed">
              <label className="mr-2"><IoLocationOutline className="dark:text-white"/></label>
              <input
                name="address"
                onChange={(e) => handleChangeUserInfo(e)}
                className=" focus-input w-full dark:bg-gray-700 dark:text-white"
                defaultValue={profile?.address ? profile?.address : "N/A"}
              />
            </div>

            <div className="flex my-3 dark:border-b dark:border-dashed ">
              <label className="mr-2"><TbListDetails className="dark:text-white"/></label>
              <textarea
                name="details"
                onChange={(e) => handleChangeUserInfo(e)}
                className="focus-input w-full dark:bg-gray-700 dark:text-white "
                defaultValue={profile?.details ? profile?.details : "N/A"}
              />
            </div>

            <div className="flex lg:relative fixed bottom-0 w-full left-0 justify-start">
              {/* <Button
                onClick={handleSubmit}
                label="Update info"
                severity="info"
                size="small"
              /> */}
              <button
                onClick={handleSubmit} className="bg-sky-500 text-white px-5 py-2 w-1/2 lg:w-fit rounded-none py-1"
              >Update</button>
              <button
                type="button"
                className="bg-sky-600 text-white px-5 py-2 w-1/2 lg:w-fit lg:ml-4 rounded-none py-1"
                onClick={() => setShowModal(!showModal)}
              >
                Change Password
              </button>
            </div>
          </div>

          {showModal && (
            <ChangePasswordModal
              visible={showModal}
              setVisible={setShowModal}
            />
          )}
        </div>
        </>
      )}
    </div>
  );
};

export default Profile;
