import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { baseUrl, checkAvailability } from "../../Helper/helper";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useData from "../../hooks/useData";
import usePaymentTypes from "../../hooks/usePaymentTypes";

const Payment = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [clients, setClients] = useState(null);
  const [paymentTypes] = usePaymentTypes()
  const [services, setServices] = useState();
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const { token } = useData().store;
  const toast = useRef();
  const { authPermissions } = useData().permissions;
  const isCreateClientPayment = checkAvailability(
    authPermissions,
    "create-client-payment"
  );
  const navigate = useNavigate();

  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    const isGetUnpaidClients = checkAvailability(
      authPermissions,
      "get-unpaid-client-list"
    );
    if (!isGetUnpaidClients) {
      return;
    }
    const clients = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/api/get-unpaid-clients`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setClients(data?.data);
        }
      } catch (error) {
        console.error(error?.message);
      }
    };
    clients().then();
  }, [token, authPermissions]);



  useEffect(() => {
    const isGetPaymentTypes = checkAvailability(
      authPermissions,
      "get-service"
    );
    if (!isGetPaymentTypes) {
      return;
    }
    const services = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/api/services?status=1`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setServices(data?.data);
        }
      } catch (error) {
        console.error(error?.message);
      }
    };
    services().then();
  }, [token, authPermissions]);

  const selectedClientTemplate = (option, props) => {
    if (option) {
      return <div>{option.name}</div>;
    }

    return <span>{props.placeholder}</span>;
  };

  const clientOptionTemplate = (option) => {
    return <div>{option.name}</div>;
  };

  const onSubmit = async (data) => {
    if (!isCreateClientPayment) {
      return;
    }
    const postData = {
      client_id: selectedClient?.id,
      website_domain: data?.domain,
      payment_type_id: selectedPaymentType?.id,
      service_id: selectedService?.id,
   
    };
    if (selectedPaymentType && selectedPaymentType?.id !==1) {
     if(data?.transaction_id){
      postData.transaction_id = data?.transaction_id
     }
    }

    for (let i in postData) {
      if (!postData[i]) {
        if (i === "client_id") {
          callToast(false, "Client id is required");
          return;
        } else if (i === "service_id") {
          callToast(false, "Service is required");
          return;
        } else if (i === "website_domain") {
          callToast(false, "Website domain is required");
          return;
        } else if (i === "payment_type_id") {
          callToast(false, "Payment type is required");
          return;
        } else if (i === "transaction_id") {
          if (selectedPaymentType?.id !== 1) {
            callToast(false, "Transaction number is required");
            return;
          }
        }
      }
    }

    try {
      const res = await fetch(`${baseUrl.url}/api/clients-payments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });
      const resData = await res.json();

      if (res.ok) {
        callToast(true, "Payments Successfully");
        setTimeout(() => {
          navigate("/confirm-clients");
        }, 400);
      } else {
        callToast(false, resData?.error);
      }
    } catch (error) {
      callToast(false, error?.message);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="w-full lg:w-8/12 xl:w-1/2 border rounded-xl mx-auto p-3 lg:p-5 mt-3">
        <div className="mb-2">
          <p className="text-center text-lg dark:text-white"> Payment</p>
          <p className="text-xs text-center dark:text-white">
            Check all payment data
            <br /> entry very carefully{" "}
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
        <label
              htmlFor=""
              className="block lg:col-span-1 text-slate-800 pb-2 dark:text-white"
            >
             Clients <span className="text-red-500 text-xs"> *(Ensure clarity in all client documents; otherwise, you won't locate clients)</span>
            </label>
          <div className="card flex justify-content-center">
            <Dropdown
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
              options={clients}
              optionLabel="name"
              placeholder="Select Client"
              filter
              valueTemplate={selectedClientTemplate}
              itemTemplate={clientOptionTemplate}
              className="w-full md:w-14rem dark:bg-gray-800"
            />
          </div>
          <div className="mt-3">
          <label
              htmlFor=""
              className="block lg:col-span-1 text-slate-800 pb-2 dark:text-white"
            >
          Website Domain
            </label>
              <InputText
                type="url"
                className="w-full dark:bg-gray-800 dark:text-white"
                placeholder="Domain"
                {...register("domain")}
              />
              {/* {errors.domain?.type === "required" && (
                <p className="label-text-alt text-xs text-red-600 mt-2">
                  {errors.domain.message}
                </p>
              )} */}
            </div>
        
          <div className="my-2">
          <label
              htmlFor=""
              className="block lg:col-span-1 text-slate-800 pb-2 dark:text-white"
            >
            Services
            </label>
            <Dropdown
              optionLabel="name"
              className="w-full  dark:bg-gray-800"
              value={selectedService}
              onChange={(e) => setSelectedService(e.value)}
              options={services}
              placeholder="Select service"
            />
          </div>
          <div className="mt-3">
            <label
              htmlFor=""
              className="block lg:col-span-1 text-slate-800 pb-2 dark:text-white"
            >
              Amount
            </label>
            <InputText
              id="amount"
              type="number"
              placeholder="Amount"
              value={selectedService?.price}
              className="w-full  dark:bg-gray-800 dark:text-white"
              readOnly
              {...register("amount", {
                required: {
                  // value: true,
                  message: "Amount is Required",
                },
              })}
            />
            {errors.amount?.type === "required" && (
              <span className="label-text-alt text-xs text-red-600">
                {errors.amount.message}
              </span>
            )}
          </div>
          <div className="card my-2">
          <label
              htmlFor=""
              className="block lg:col-span-1 text-slate-800 pb-2 dark:text-white"
            >
             Payment Types
            </label>
            <Dropdown
              optionLabel="name"
              className="w-full dark:bg-gray-800"
              value={selectedPaymentType}
              onChange={(e) => setSelectedPaymentType(e.value)}
              options={paymentTypes}
              placeholder="Select payment type"
            />
          </div>
          {selectedPaymentType && selectedPaymentType?.id !== 1 && (
            <div className="my-2">
                  <label
              htmlFor=""
              className="block lg:col-span-1 text-slate-800 pb-2 dark:text-white"
            >
              Transaction
            </label>
              <InputText
                type="number"
                className="w-full dark:bg-gray-800 dark:text-white"
                placeholder="Transaction Number"
                {...register("transaction_id", {
                  required: {
                    // value: true,
                    message: "Transaction Number is Required",
                  },
                })}
              />
              {errors.transaction_id?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.transaction_id.message}
                </span>
              )}
            </div>
          )}
          <div className="mt-3">
            <Button type="submit" size="small" label="Submit" severity="info" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Payment;
