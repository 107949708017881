import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import React, {useEffect, useRef, useState} from "react";
import {AiOutlinePlus} from "react-icons/ai";
import {BsThreeDotsVertical} from "react-icons/bs";
import useData from "../../../hooks/useData";
import {
    baseUrl,
    checkAvailability,
    handleExportData,
    modifyDateTime,
    paginationTemplate,
    tablePerPageOptions
} from "../../../Helper/helper";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useLocation, useNavigate} from "react-router-dom";
import {Toast} from "primereact/toast";
import SectionHeading from "../../SectionHeading/SectionHeading";

const CustomBill = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bills, setBills] = useState([]);
    const {token} = useData().store;
    const {authPermissions} = useData().permissions;
    const isGetBills = checkAvailability(authPermissions, "get-bills");
    const [expandedRows, setExpandedRows] = useState();
    const toast = useRef();
    const menuLeft = useRef(null);

    //Pagination
    const [limitData, setLimitData] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);

    const webLocation = useLocation();
    const searchPage = webLocation?.search?.split("=")[1];

    // Set default page query 1
    useEffect(() => {
        if (!searchPage) {
            navigate(`?page=1`)
        } else {
            const calculatePage = (searchPage * limitData) - limitData
            setPageNumber(calculatePage)
        }

    }, [searchPage, navigate, limitData]);

    // Get Custom Bills
    useEffect(() => {
        if (!isGetBills) {
            return;
        }
        const getBills = async () => {
            try {
                setLoading(true);
                const res = await fetch(`${baseUrl.url}/api/custom-bills?page=${searchPage}&per_page=${limitData}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (res.ok) {
                    if (res.status === 200) {
                        const resData = await res.json();
                        setBills(resData?.data);
                    } else {
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        getBills().then();
    }, [token, isGetBills, limitData, searchPage]);

    // Menu  rights items array
    let items = [
        {
            label: "Create Bill",
            icon: <AiOutlinePlus/>,
            command: () => {
                navigate("/custom/billing");
            },
        },
    ];

    // Table Date Template
    const dateTemplate = (data) => {
        const formattedDate = modifyDateTime(data?.created_at)
        return <p>{formattedDate}</p>;
    };

    // Table action template
    const actionTemplate = (data) => {
        return (
            <div>
                <Button icon="pi pi-file-pdf" size={"small"} tooltip="Download payslip"
                        tooltipOptions={{position: 'top'}}
                        onClick={() => handleExportData("custom-bills/slip", token, data?.id, toast, "custom_bill", "Payslip download successfully")}/>
            </div>
        );
    };

    // Allow expansion function
    const allowExpansion = (rowData) => {
        return rowData;
    };

    // SubTotal template
    const subTotalTemplate = (data) => {
        const sub_total = data?.amount * data?.quantity
        return <span>৳ {sub_total}</span>
    }

    // Table row expansion template
    const rowExpansionTemplate = (data) => {
        const {name, email, phone, company} = data?.client
        return (
            <div className="p-3">
                <p className="text-lg font-semibold dark:text-gray-300">Client Information</p>
                <div>
                    <p className={"my-1 text-white"}><span
                        className={"font-semibold text-gray-400"}>Name : </span> {name}</p>
                    <p className={"my-1 text-white"}><span
                        className={"font-semibold text-gray-400"}>Email : </span> {email}</p>
                    <p className={"my-1 text-white"}><span
                        className={"font-semibold text-gray-400"}>Phone : </span>{phone}</p>
                    <p className={"my-1 text-white"}><span
                        className={"font-semibold text-gray-400"}>Company : </span>{company}</p>
                </div>

                <DataTable value={data.items}>
                    <Column
                        field="item"
                        header="Service"
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>
                    <Column
                        field="quantity"
                        header="Quantity"
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>
                    <Column
                        header="Amount"
                        className="dark:bg-gray-800 dark:text-white"
                        body={(data) => <span>৳ {data?.amount}</span>}
                    ></Column>
                    <Column
                        header="Sub Total"
                        body={subTotalTemplate}
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>

                </DataTable>
            </div>
        );
    };

    // Table amount template
    const AmountTemplate = (data) => {
        const total = data?.items?.reduce((acc, service) => {
            return acc + service?.amount * service?.quantity
        }, 0)
        return <p>৳ {total}</p>
    }


    return (
        <div>
            <Toast ref={toast}/>
            <div className="grid grid-cols-12 justify-between">
                <div className="col-span-6">
                    <SectionHeading title={"Custom Bills"} text={"Custom bills are detailed bellow"}/>
                </div>
                <div className="col-span-6 flex justify-end">
                    <div className="zero">
                        <Button
                            icon={<BsThreeDotsVertical className="dark:text-white"/>}
                            className="dark:bg-gray-800 "
                            onClick={(event) => menuLeft.current.toggle(event)}
                            aria-controls="popup_menu_left"
                            aria-haspopup
                        />
                    </div>
                    <Menu
                        model={items}
                        popup
                        ref={menuLeft}
                        id="popup_menu_left"
                        className="dark:bg-gray-800 "
                    />
                </div>
            </div>

            <div className="mt-4">
                <DataTable
                    value={bills?.data}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e?.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="id"
                    size="small"
                    sortMode="multiple"
                    showGridlines={true}
                    key={pageNumber}
                    className="datatable-responsive myTable"
                    emptyMessage="No client found."
                    rowHover={true}
                    loading={loading}
                    lazy
                    paginator={bills?.total > 0}
                    rows={limitData}
                    totalRecords={bills?.total}
                    first={pageNumber}
                    onPage={(e) => {
                        setLimitData(e.rows);
                        let pages = Math.ceil(e.first / e.rows + 1);
                        navigate(`?page=${pages}`)
                    }}
                    pageLinkSize={10}
                    paginatorTemplate={paginationTemplate}
                    rowsPerPageOptions={tablePerPageOptions}
                >
                    <Column
                        expander={allowExpansion}
                        style={{width: "5rem"}}
                        className="dark:bg-gray-800 dark:text-white"
                    />
                    <Column
                        header="#"
                        headerStyle={{width: "3rem"}}
                        body={(data, options) => options?.rowIndex + 1}
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>
                    <Column
                        field="bill_no"
                        header="Bill No"
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>
                    <Column
                        field="client.name"
                        header="Client"
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>
                    <Column
                        header="Total Amount"
                        className="dark:bg-gray-800 dark:text-white"
                        body={AmountTemplate}
                    ></Column>
                    <Column
                        header="Date"
                        className="dark:bg-gray-800 dark:text-white"
                        body={dateTemplate}
                    ></Column>
                    <Column
                        header="Action"
                        className="dark:bg-gray-800 dark:text-white"
                        body={actionTemplate}
                    ></Column>
                </DataTable>
            </div>

        </div>
    );
};

export default CustomBill;
