import {useEffect, useState} from "react";
import { baseUrl, getToken } from "../Helper/helper";



const useProfile = () =>{
    const token = getToken()
    const [update, setUpdate] = useState(false);
    const [profile, setProfile] = useState();
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        const loggedInProfile = async () => {
            setLoading(true);
            try {
              const res = await fetch(`${baseUrl.url}/api/profile`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
              const resData = await res.json();
              if (res.ok) {
                setProfile(resData?.data);
              }
            } catch (err) {
              console.error(err);
            } finally {
              setLoading(false);
            }
          };
          loggedInProfile().then();

    }, [update, token]);

    return {profile, loading, update, setUpdate};
}

export default useProfile;