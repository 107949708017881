import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useData from "../hooks/useData";
import Loader from "../Components/Loader/Loader";

const RequireAuth = ({ children }) => {
  const {profile,loading} = useData().profile;
  const location = useLocation();
  if (loading) {
    return <Loader/>
  }
  if (!profile?.email) {
    document.cookie = `access_token=`
    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
  }
  return children;
};

export default RequireAuth;
