import React, { useEffect, useRef, useState } from "react";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { baseUrl, checkAvailability} from "../../Helper/helper";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import AddFollowUpDateModal from "../Modal/AddFollowUpDateModal";
import { RxCross1 } from "react-icons/rx";
import useData from "../../hooks/useData";
import NoContent from "../NoContent/NoContent";
import Loader from "../Loader/Loader";
import GoBack from "../GoBack/GoBack";

const ViewFollowUpData = () => {
  const [events, setEvents] = useState();
  const {token} = useData().store
  const toast = useRef(null);
  const [update, setUpdate] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [followUp, setFollowUp] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {authPermissions } = useData().permissions
   const isUpdateClientFollowUp =  checkAvailability(authPermissions, "update-client-follow-up")
   const isDeleteClientFollowUp =  checkAvailability(authPermissions, "delete-client-follow-up")
  useEffect(() => {
    const isGetClientFollowUp =  checkAvailability(authPermissions, "get-client-follow-up")
    if(!isGetClientFollowUp){
      return
    }
    const events = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/api/clients-follow-up/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setEvents(data?.data);
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    };
    events().then();
  }, [token, update, setUpdate, id, authPermissions]);

  const customizedContent = (item) => {
    const id = item?.id;
    let data = item;
    let splitDate = item?.occurred_on?.split(" ");
    let date = new Date(splitDate[0]).toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return (
      <div className="prime_time_line relative">
        <Card className="" title={date}>
          <div className="flex items-center justify-start">
            {item?.status?.length>0 && <p className={` ${item?.status==="VALID" ? "bg-green-50 text-green-500" : "bg-red-50 text-red-500"}  text-sm p-2 inline`}>{item?.status}</p>}
          </div>
       {
        isDeleteClientFollowUp &&  <span
        className="absolute top-0 cursor-pointer right-0 flex justify-center items-center  hover:bg-red-500 hover:text-white py-1 px-2 rounded-tr-lg"
        onClick={() => handleDelete(id)}>
        <RxCross1 />
      </span>
       }
        <p className="text-justify">{item?.detail}</p>
        <div
          className={`flex gap-2 mt-2 justify-start`}
        >
       {
        isUpdateClientFollowUp &&    <button
        className="bg-sky-500 hover:bg-sky-600 text-white px-3 py-1 rounded-md"
        onClick={() => {
          setShowUpdateModal(!showUpdateModal);
          setFollowUp(data);
        }}
      >
        Update
      </button>
       }
        </div>
      </Card>
      </div>
    );
  };
  const handleDelete = async (id) => {
    const res = await fetch(`${baseUrl.url}/api/follow-up/${id}`, {
      method: "DELETE",
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const resData = await res.json();

    if (res.ok) {
      setUpdate(!update);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Follow up deleted",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${resData?.error}`,
        life: 3000,
      });
    }
  };
  return (
    <>
    <GoBack/>
    <div className="card w-full lg:w-10/12 xl:w-8/12 mx-auto">
      <Toast ref={toast} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {events?.length > 0 ? (
            <div className="m-0 w-full mx-auto">
              <div className="card w-full">
                <Timeline
                  value={events}
                  align="alternate"
                  className="customized-timeline"
                  content={customizedContent}
                />
              </div>
            </div>
          ) : <NoContent data="follow up data"/>}
        </>
      )}

      {showUpdateModal && (
        <AddFollowUpDateModal
          visible={showUpdateModal}
          setVisible={setShowUpdateModal}
          update={update}
          setUpdate={setUpdate}
          followUp={followUp}
        />
      )}
    </div>
    </>
  );
};

export default ViewFollowUpData;
