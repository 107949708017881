import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import {baseUrl, checkAvailability, paginationTemplate, tablePerPageOptions} from "../../../Helper/helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useData from "../../../hooks/useData";
import { AiOutlinePlus } from "react-icons/ai";
import { SiConvertio } from "react-icons/si";
import { FiEdit } from "react-icons/fi";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { BsThreeDotsVertical } from "react-icons/bs";
import SectionHeading from "../../SectionHeading/SectionHeading";


const Users = () => {
  const menuLeft = useRef(null);
  const [update, setUpdate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [convertUserInfo, setConvertUserInfo] = useState([]);
  const [inputJoiningDate, setInputJoiningDate] = useState("");
  const [inputSalary, setInputSalary] = useState("");
  const [inputKpi, setInputKpi] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const toast = useRef();
  const navigate = useNavigate();
  const { token } = useData().store;
  const { authPermissions } = useData().permissions;

  //Pagination
  const [limitData, setLimitData] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const webLocation = useLocation();
  const searchPage = webLocation?.search?.split("=")[1];
  const limit =  webLocation?.hash?.split("=")[1];

  // Check Permissions
  const isCreateUser = checkAvailability(authPermissions, "create-user");
  const isGetUserInfo = checkAvailability(authPermissions, "get-user-info");
  const isDeleteUser = checkAvailability(authPermissions, "delete-user");
  const isUpdateUser = checkAvailability(authPermissions, "update-user");
  const isConvertEmployee = checkAvailability(
    authPermissions,
    "create-user-employee-profile"
  );

  // Set default page query 1
  useEffect(() => {
    if(!searchPage){
      navigate(`?page=1#per_page=10`)
    }
    else{
      const calculatePage = (searchPage * limit)-limit
      setLimitData(Number(limit))
      setPageNumber(calculatePage)
    }

  }, [searchPage, navigate, limit]);


  // Toast Function
  const callToast = (type, detail) => {
    toast?.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  // Get User List
  useEffect(() => {
    const isGetUsers = checkAvailability(authPermissions, "get-user-list");
    if (!isGetUsers) {
      return;
    }
    const users = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/api/users?page=${searchPage}${limitData && `&per_page=${limitData}`}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setUserList(data?.data);
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    };
    users().then();
  }, [token, update, setUpdate, authPermissions, limitData, searchPage]);

  const nameTemplate = (data) => {
    let id = data?.id;
    let img = data?.avatar;
    return (
      <Link
        to={`${isGetUserInfo ? `/user/${id}` : ""}`}
        className=" cursor-pointer"
      >
        <div className="flex items-center">
          <img
            className="w-8 h-8 rounded-full"
            src={`${baseUrl?.url}${img}`}
            alt="user"
          />
          <span className="ml-1">{data.name}</span>
        </div>
      </Link>
    );
  };
  const roleTemplate = (data) => {
    const {roles} = data
    const role = roles[0]?.name;
    return <span>{role}</span>;
  };


  const statusTemplate = (data) => {
    const status = data?.is_active;
    return (
        <div
        onClick={() =>
          handleStatusChange(data?.id, status)
        }
        className={`h-5 rounded-full flex items-center w-9 cursor-pointer transition-all duration-300 ${
         status === 1
            ? "bg-sky-500"
            : "bg-gray-400 dark:bg-gray-500 justify-end"
        }`}
      >
        <div
          className={`h-4 w-4 rounded-full flex items-center justify-center mx-[1px] ${
          status === 1 ? "bg-white" : " bg-white"
          }`}
        ></div>
      </div>

    );
  };
  const optionTemplate = (data) => {
    const {id, is_active, employee} = data
    const isEmployee = employee?.id;
    return (
      <div className="flex items-center">
        {isConvertEmployee && is_active === 1 && !isEmployee && (
          <button
            onClick={() => {
              setVisible(true);
              setConvertUserInfo(data);
            }}
            className="bg-sky-500 hover:bg-green-500 text-white font-semibold p-2"
          >
            <SiConvertio />
          </button>
        )}
        {isUpdateUser && (
          <Link
            to={`${isGetUserInfo ? `/user/${id}` : ""}`}
            className=" cursor-pointer"
          >
            <button className="bg-sky-600 text-white font-semibold p-2">
              <FiEdit />
            </button>
          </Link>
        )}

      </div>
    );
  };

  let items = [
    {
      label: "Add User",
      icon: <AiOutlinePlus />,
      command: () => navigate("/add-user"),
    },
  ];
  const createEmployee = async () => {
    const formData = new FormData();
    if (!isConvertEmployee) {
      return;
    }
    formData.append("user_id", convertUserInfo?.id);
    formData.append("salary", inputSalary);
    formData.append("general_kpi", inputKpi);
    formData.append("document", selectedFile ? selectedFile : "");
    formData.append("joining_date", inputJoiningDate);

    try {
      const res = await fetch(`${baseUrl.url}/api/create-employee-profile `, {
        method: "POST",
        headers: {
          Authorization: `bearer ${token}`,
        },
        body: formData,
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        toast.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Convert User to Employee Successfully",
          life: 3000,
        });
        setVisible(false);
        setConvertUserInfo([]);
        setInputJoiningDate("");
        setInputKpi("");
        setInputSalary("");
        setSelectedFile(null);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: `${resData?.error}`,
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle User status change
  const handleStatusChange = async(id, status) => {
    try {
      const res = await fetch(
        `${baseUrl.url}/api/users-status/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          }
        }
      );
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update)
        callToast(true, `User ${status === 0 ? "activated" : "deactivated"} successfully`);
        setTimeout(() => {
            setVisible(false)
            setUpdate(!update)
        }, 400)
      } else {
        callToast(false, resData?.error);
      }
    } catch(error){
      callToast(false, error?.message)
     }
  }

  return (
    <div>
      <Toast ref={toast} />
      <Rodal
        visible={visible}
        onClose={() => setVisible(!visible)}
        animation={"fade"}
        customStyles={{
          minWidth: "400px",
          height: "auto",
          width: "45%",
          bottom: "auto",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <div className={`rodal-width p-4 text-slate-800 `}>
          <div>
            <h3 className="p-2">
              Convert{" "}
              <span className="text-green-500">{convertUserInfo?.name}</span> to
              Employee
            </h3>
            <div className="grid grid-cols-12">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-black col-span-2">
                <span className="text-red-500">*</span>Joining Date
              </label>
              <input
                type="date"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 col-span-10 date-input date-input"
                onChange={(e) => setInputJoiningDate(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-12">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-black col-span-2">
                <span className="text-red-500">*</span>Salary
              </label>
              <input
                type="number"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 bg-transparent col-span-10"
                placeholder="Salary"
                onChange={(e) => setInputSalary(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-12">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-black col-span-2 ">
                <span className="text-red-500">*</span>General Kpi
              </label>
              <input
                type="number"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 col-span-10"
                placeholder="General kpi"
                onChange={(e) => setInputKpi(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-12">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-black col-span-2">
                document
              </label>
              <input
                type="file"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 col-span-10"
                placeholder="General kpi"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </div>
            <button
              className="px-4 py-1 border cursor-pointer bg-sky-500 mt-3 rounded-md"
              onClick={createEmployee}
            >
              Convert
            </button>
          </div>
        </div>
      </Rodal>

        <div>
          <div className="grid grid-cols-12 justify-between">
            <div className="col-span-6">
              <SectionHeading title={'Users'} text={'Admin and user list here'}/>
            </div>
            {isCreateUser && (
              <div className="col-span-6 flex justify-end">
                <div className="zero">
                  <Button
                    icon={<BsThreeDotsVertical className="dark:text-white" />}
                    onClick={(event) => menuLeft.current.toggle(event)}
                    aria-controls="popup_menu_left"
                    aria-haspopup
                  />
                </div>
                <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
              </div>
            )}
          </div>


            <div className="mt-4">
              <DataTable
                value={userList?.data}
                sortMode="multiple"
                showGridlines={true}
                key={pageNumber}
                className="datatable-responsive myTable"
                emptyMessage="No user found."
                rowHover={true}
                loading={loading}
                lazy
                paginator={userList?.total > 0 }
                rows={limitData}
                totalRecords={userList?.total}
                first={pageNumber}
                onPage={(e) => {
                  setLimitData(e.rows);
                  let pages = Math.ceil(e.first / e.rows + 1);
                  navigate(`?page=${pages}#per_page=${e.rows}`)
                }}
                pageLinkSize={10}
                paginatorTemplate={paginationTemplate}
                rowsPerPageOptions={tablePerPageOptions}
              >
                <Column
                  header="#"
                  headerStyle={{ width: "3rem" }}
                  body={(data, options) => options?.rowIndex + 1}
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="name"
                  header="Name"
                  body={nameTemplate}
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="email"
                  header="Email"
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="phone"
                  header="Phone No"
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="point_list_sum_points"
                  header="Points"
                  className="dark:bg-gray-800 dark:text-white"
                  body={(data) => 
                {
                  const {point_list_sum_points} = data
                  return <p>{point_list_sum_points ? point_list_sum_points : 0}</p>
                }}
                />
                <Column
                  header="Role"
                  body={roleTemplate}
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  header="Status"
                  body={statusTemplate}
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                {isDeleteUser || isUpdateUser ? (
                  <Column
                    header="Options"
                    body={optionTemplate}
                    className="flex justify-end dark:bg-gray-800 dark:text-white"
                  ></Column>
                ) : null}
              </DataTable>

            </div>

        </div>


    </div>
  );
};

export default Users;
