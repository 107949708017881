import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { baseUrl, checkAvailability } from "../../Helper/helper";
import { Toast } from "primereact/toast";
import useData from "../../hooks/useData";
import GoBack from "../GoBack/GoBack";
import { IoLocationOutline } from "react-icons/io5";
import SectionHeading from "../SectionHeading/SectionHeading";
import usePaymentTypes from "../../hooks/usePaymentTypes";
import {Dropdown} from "primereact/dropdown";

const AddClient = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const { token, location } = useData().store;
  const toast = useRef(null);
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [file, setFile] = useState();
  const { authPermissions } = useData().permissions;
  const [isConfirmClient, setIsConfirmClient] = useState(false)
  const isClientUpdate = checkAvailability(authPermissions, "update-client");
  const isCreateClient = checkAvailability(authPermissions, "create-client");
  const [paymentTypes] = usePaymentTypes()
  const [selectedPaymentType, setSelectedPaymentType] = useState(null)
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };
  useEffect(() => {
    const isGetClientInfo = checkAvailability(
      authPermissions,
      "get-client-info"
    );
    if (!isGetClientInfo) {
      return;
    }
    if (!id) {
      setClient(null);
      return;
    }
    const clientsById = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/api/clients/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          console.log(data?.data)
          const confirmationDate = data?.data?.confirmation_date
          if(confirmationDate){
            setIsConfirmClient(true)
            setSelectedPaymentType(data?.data?.payment?.type)
          }
          setClient(data?.data);
        }
      } catch (err) {
        console.error(err?.message);
      }
    };
    clientsById().then();
  }, [token, id, authPermissions]);


  const onSubmit = async (data) => {
    let formData = new FormData();
    console.log("dd", data)
    const updatedData = { ...data };
    delete updatedData?.document;
    for (const d in updatedData) {
      if (client?.id) {
        if (!updatedData[d]) {
          updatedData[d] = client[d];
        }
      }
    }
    if(isConfirmClient){
      updatedData.payment_type_id = selectedPaymentType?.id
      if(selectedPaymentType?.id === 1){
        delete updatedData.transaction_id
      }
    }

    for (const i in data) {
      let name = data[i];
      if (i === "document") {
        let name2 = data[i][0];
        if (name2) {
          formData.append(`${i}`, name2);
          delete data.document;
        } else {
          delete data.document;
        }
      } else {
        formData.append(`${i}`, name);
      }
    }


    if (client?.id) {
      if (!isClientUpdate) {
        return;
      }
      try {
        const res = await fetch(
          `${baseUrl.url}/api/clients-info-update/${client?.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(updatedData),
          }
        );
        const resData = await res.json();

        if (res.ok) {
          callToast(true, "Client Updated");
          setTimeout(() => {
            if (client?.confirmation_date) {
              navigate("/confirm-clients");
            } else {
              navigate("/");
            }
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
      } catch (err) {
        callToast(false, err?.message);
      }
    } else {
      if (!isCreateClient) {
        return;
      }
      formData.append("longitude", `${location?.longitude}`);
      formData.append("latitude", `${location?.latitude}`);
      try {
        const res = await fetch(`${baseUrl.url}/api/clients`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "Client Added");
          setTimeout(() => {
            navigate("/");
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
      } catch (err) {
        callToast(false, err?.message);
      }
    }
  };

  const statuses = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const IsImportFile = checkAvailability(authPermissions, "import-client");
  const handleImportFile = async () => {
    if (!IsImportFile) {
      return;
    }
    if (file) {
      let formData = new FormData();
      const fileType = file?.name?.split(".")[1];
      if (fileType === "xlsx" || fileType === "csv") {
        formData.append(`file`, file);
      } else {
        callToast(false, "The file field must be a file of type: xlsx, csv.");
        return;
      }
      try {
        const res = await fetch(`${baseUrl.url}/api/import/clients`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "File Imported Successfully");
          setTimeout(() => {
            navigate("/clients");
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
      } catch (err) {
        callToast(false, err?.message);
      }
    }
  };

  console.log("dd", paymentTypes)


  return (
    <div className="w-full mx-auto">
      <Toast ref={toast} />
      <div className="flex items-center justify-between">
        <SectionHeading
          title={client?.id ? "Update  Client" : "Add  Client"}
          text={""}
        />
        <GoBack />
      </div>
      <div className="">
        {!client?.id && (
          <div className="flex justify-between border-b mt-4">
            <div className="flex">
              <button
                onClick={() => setActiveTab(1)}
                className={`flex justify-center text-sm p-2 ${
                  activeTab === 1
                    ? "bg-sky-500 text-white dark:bg-gray-800 dark:text-white "
                    : "bg-gray-100"
                }`}
              >
                Add Client
              </button>
              <button
                onClick={() => setActiveTab(2)}
                className={`flex justify-center text-sm p-2 ${
                  activeTab !== 1
                    ? "bg-sky-500 text-white dark:bg-gray-800 dark:text-white"
                    : "bg-gray-100"
                }`}
              >
                Import Client
              </button>
            </div>
            <div className="flex items-center dark:text-white">
              <IoLocationOutline className="mr-2 dark:text-white" />
              GPS{" "}
              {location?.latitude && location?.longitude ? (
                <span className="text-green-500 ml-2">enabled</span>
              ) : (
                <span className="text-red-500 ml-2">unavailable</span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="mt-4">
        {activeTab === 1 && (
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            {!client?.id && (
              <div className="flex lg:gap-4 my-3">
                <input
                  type="file"
                  {...register("document")}
                  className="dark:bg-gray-700 dark:text-white"
                />
              </div>
            )}
            <div className="my-3">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                Name
              </label>
              <input
                type="text"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                placeholder="Type name"
                defaultValue={client?.id && client?.name}
                {...register("name", {
                  required: {
                    value: !client?.id && true,
                    message: "Name is Required",
                  },
                })}
              />
              {errors.name?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.name.message}
                </span>
              )}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 my-2 items-center gap-2">
              <div className="">
                <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                  placeholder="Email Address"
                  defaultValue={client?.id && client?.email}
                  {...register("email")}
                />
              </div>
              <div className="">
                <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                  Phone Number
                </label>
                <input
                  type="number"
                  className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                  placeholder="Phone Number"
                  defaultValue={client?.id && client?.phone_no}
                  {...register("phone_no", {
                    required: {
                      value: !client?.id && true,
                      message: "Phone is Required",
                    },
                  })}
                />
                {errors.phone_no?.type === "required" && (
                  <span className="label-text-alt text-xs text-red-600">
                    {errors.phone_no.message}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 my-2 item-center gap-1 lg:gap-2">
              <div>
                <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                  Product Category
                </label>
                <input
                  type="text"
                  className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                  placeholder="Product Category"
                  defaultValue={client?.id && client?.product_type}
                  {...register("product_type", {
                    required: {
                      value: !client?.id && true,
                      message: "Product Category is Required",
                    },
                  })}
                />
                {errors.product_type?.type === "required" && (
                  <span className="label-text-alt text-xs text-red-600">
                    {errors.product_type.message}
                  </span>
                )}
              </div>
              <div className={"-mt-1"}>
                <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                  Interest Status
                </label>
                <select
                  className="w-full border-b focus:outline-none focus:border-blue-400 p-2 sm:mt-1 bg-transparent text-gray-800 dark:text-white"
                  {...register("interest_status")}
                    disabled={isConfirmClient}
                >
                  <option
                    defaultValue
                    className="text-gray-400 dark:text-white"
                    value=""
                  >
                    Select interest status
                  </option>
                  {statuses?.length > 0 &&
                    statuses?.map((option, i) => (
                      <option
                        value={option}
                        key={i}
                        selected={
                          option === Number(client?.interest_status) && true
                        }
                      >
                        {option}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="my-3">
              <label className="text-gray-500 text-sm p-2 font-semibold">
                Company
              </label>
              <input
                type="text"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                placeholder="Company Name"
                defaultValue={client?.id && client?.company}
                {...register("company", {
                  required: {
                    value: !client?.id && true,
                    message: "Company Name is Required",
                  },
                })}
              />
              {errors.company?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.company.message}
                </span>
              )}
            </div>
            <div className="my-3">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                Address
              </label>
              <input
                type="text"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                placeholder="Address"
                defaultValue={client?.id && client?.area}
                {...register("area", {
                  required: {
                    value: !client?.id && true,
                    message: "Area is Required",
                  },
                })}
              />
              {errors.area?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.area.message}
                </span>
              )}
            </div>
            {
              isConfirmClient && <div>
                  <div className="my-3">
                    <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                      Domain
                    </label>
                    <input
                        type="text"
                        className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                        placeholder="Domain"
                        defaultValue={isConfirmClient ? client?.website?.domain : ""}
                        {...register("domain", {
                          required: {
                            value: client?.id && true,
                            message: "Domain is Required",
                          },
                        })}
                    />
                    {errors.area?.type === "required" && (
                        <span className="label-text-alt text-xs text-red-600">
                  {errors.area.message}
                </span>
                    )}
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 my-2 item-center gap-1 lg:gap-2">
                    <div>
                      <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                        Payment Amount
                      </label>
                      <input
                          type="text"
                          className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                          placeholder="Amount"
                          defaultValue={isConfirmClient ? client?.payment?.amount : ""}
                          {...register("amount", {
                            required: {
                              value: client?.id && true,
                              message: "Amount is Required",
                            },
                          })}
                      />
                      {errors.amount?.type === "required" && (
                          <span className="label-text-alt text-xs text-red-600">
                    {errors.amount.message}
                  </span>
                      )}
                    </div>
                    <div className={""}>
                      <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                        Payment Type
                      </label>
                      <Dropdown value={selectedPaymentType} onChange={(e) => setSelectedPaymentType(e.value)} options={paymentTypes} optionLabel="name"
                                showClear placeholder="Select Type" className="w-full bottom-dropdown" />
                    </div>
                  </div>

                  {
                    selectedPaymentType?.id !== 1 &&  <div className="my-3">
                        <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                          Transaction Number
                        </label>
                        <input
                            type="text"
                            className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                            placeholder="Transaction number"
                            defaultValue={isConfirmClient? client?.payment?.transaction_id : ""}
                            {...register("transaction_id", {
                              required: {
                                value: client?.id && true,
                                message: "Transaction is Required",
                              },
                            })}
                        />
                        {errors.transaction_id?.type === "required" && (
                            <span className="label-text-alt text-xs text-red-600">
                  {errors.transaction_id.message}
                </span>
                        )}
                      </div>
                  }
                </div>
            }
            <div className="my-3">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                Customer Opinion
              </label>
              <textarea
                className="w-full border focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white "
                placeholder="Customer opinion"
                defaultValue={client?.id ? client?.client_opinion : ""}
                {...register("client_opinion")}
                rows={3}
              />
            </div>
            <div className="my-3">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                Officer Opinion
              </label>
              <textarea
                className="w-full border focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                placeholder="Officer opinion"
                defaultValue={client?.id ? client?.officer_opinion : ""}
                {...register("officer_opinion")}
                rows={3}
              />
            </div>
            <div>
              <Button
                className="w-full lg:w-fit rounded-none"
                size="small"
                severity="info"
                label={client?.id ? `Save` : "Submit"}
              />
            </div>
          </form>
        )}

        {activeTab !== 1 && (
          <div>
            <div className="py-4">
              <p className="sm:flex items-center gap-1 mb-5">
                <span className="text-gray-500 text-sm p-2 rounded-sm whitespace-nowrap block font-semibold dark:text-white">
                  Upload File:
                </span>{" "}
                <input
                  type="file"
                  onChange={(e) => setFile(e.target?.files[0])}
                  className="border p-2 focus:border-sky-500 focus:text-sky-600  dark:bg-gray-700 dark:text-white"
                />
              </p>
              <Button
                onClick={handleImportFile}
                size="small"
                label="Submit"
                severity="info"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddClient;
