import React, { createContext } from "react";
import useProfile from "../hooks/useProfile";
import useGlobalContext from "../hooks/useGlobalContext";
import usePermissions from "../hooks/usePermissions";
export const AllContext = createContext();
const ContextProvider = ({ children }) => {
  const profile = useProfile()
  const store = useGlobalContext()
  const permissions = usePermissions()
  return (
    <AllContext.Provider value={{profile: profile, store:store, permissions:permissions}}>{children}</AllContext.Provider>
  );
};

export default ContextProvider;
