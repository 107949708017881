import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { baseUrl} from '../../Helper/helper';
import useData from '../../hooks/useData';

const ChangePasswordModal = ({visible, setVisible}) => {
    const {register,handleSubmit,formState: { errors } } = useForm();
    const toast = useRef()
    const {token} = useData().store
    const callToast = (type, detail) => {
        toast.current.show({
          severity: type ? "success" : "error",
          summary: type ? "Success" : "Error",
          detail: detail,
          life: 3000,
        });
      };
    const onSubmit = async (data) => {
        if(data?.new_password !== data?.confirm_password){
            callToast(false, "New password and confirm password didn't match")
            return
        }
       try{
        const res = await fetch(`${baseUrl.url}/api/update-password`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        });
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "Password Changed Successfully");
          setTimeout(() => {
              setVisible(false)
          }, 400)
        } else {
          callToast(false, resData?.error);
        }
       }
       catch(error){
        callToast(false, error?.message)
       }
    }

    return (
        <div className="card flex flex-wrap justify-content-center w-full">
        <Toast ref={toast} />
        <Dialog
          header="Change Password"
          visible={visible}
          className="w-11/12 sm:w-10/12 lg:w-8/12 xl:1/2 2xl:w-1/3"
          onHide={() => {
            setVisible(false);
          }}
        >
         <form onSubmit={handleSubmit(onSubmit)}>
         <div>
         <InputText
              className="w-full"
              placeholder='Current Password'
              type="password"
              {...register("old_password", {
                required: {
                  value: true,
                  message: "Current Password is Required",
                },
              })} 
            />

            {errors.old_password?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.old_password.message}
                </span>
              )}
        
         </div>
         <div className='my-3'>
         <InputText
              type="password"
              className="w-full"
              placeholder='New Password'
              {...register("new_password", {
                required: {
                  value: true,
                  message: "New Password is Required",
                },
              })} 
            />

            {errors.new_password?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.new_password.message}
                </span>
              )}
        
         </div>
         <div className='my-3'>
         <InputText
               type="password"
              className="w-full"
              placeholder='Confirm Password'
              {...register("confirm_password", {
                required: {
                  value: true,
                  message: "Confirm Password is Required",
                },
              })} 
            />

            {errors.confirm_password?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.confirm_password.message}
                </span>
              )}
        
         </div>
          <div  className='mt-4'>
          <Button type="submit" size="small" label="Submit" severity="info"/>
          </div>
         </form>
        </Dialog>
      </div>
    );
};

export default ChangePasswordModal;