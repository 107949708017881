import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, checkAvailability } from "../../Helper/helper";

export const apiSlice = createApi({
  reducerPath: "commentall",
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl.url}` }),
  endpoints: (builder) => ({
    getClients: builder.query({
      query: ({
        value,
        perPageLimit,
        token,
        authPermissions,
        permission,
        params,
      }) => {
        const IsGetClient = checkAvailability(authPermissions, permission);
        const url = `/api/clients?${
          params ? `confirmed=0&search=${params}&per_page=${perPageLimit}` : value ? `confirmed=0&search=${value}&per_page=${perPageLimit}`: `confirmed=0&per_page=${perPageLimit}`
        }`;
        if (!IsGetClient) {
          return;
        }

        return {
          url: url,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    deleteClients: builder.mutation({
      query: ({ token, authPermissions, permission,ids }) => {
        const IsGetClient = checkAvailability(authPermissions, permission);
        if (!IsGetClient) {
          return;
        }
        return {
          url: `/api/clients/delete`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body:JSON.stringify({ids})
        };
      },
    }),
  }),
});

export const { useGetClientsQuery, useDeleteClientsMutation } = apiSlice;
