import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React from 'react';

const PayslipDownloadModal = ({visible, setVisible, btnLoading, btnLoading2, handleDownload, toast}) => {
    
    return (
   <div className="card flex flex-wrap justify-content-center w-full">
      <Toast ref={toast}/>
        <Dialog
          header={`Payslip Download`}
          visible={visible}
          className="w-11/12 sm:w-10/12 lg:w-8/12 2xl:w-1/4"
          onHide={() => {
            setVisible(false);
          }}
        >
         <div className="flex gap-2 mt-3">
        <button onClick={() => handleDownload(false)} className={` p-2 flex items-center ${btnLoading ? "bg-gray-300": "bg-sky-600 text-white"}`} disabled={btnLoading && true}>{btnLoading ? "loading..": "Only Download"}</button>
        <button onClick={() => handleDownload(true)} className={` p-2 flex items-center ${btnLoading2 ? "bg-gray-300": "bg-green-500 text-white"}`}  disabled={btnLoading2 && true}>{btnLoading2 ? "loading..": "Download + Mark as Paid"}</button>
       </div>

        </Dialog>
        </div>
 
    );
};

export default PayslipDownloadModal;