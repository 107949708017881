import { useEffect, useState } from "react";
import { baseUrl, getToken } from "../Helper/helper";

const usePermissions = () => {
const [authPermissions, setAuthPermissions] = useState()
 const token = getToken()

useEffect(()=> {
  const loggedInPermissions = async () => {
    try {
      const res = await fetch(`${baseUrl.url}/api/auth-permissions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setAuthPermissions(resData?.data);
      }
    } catch (err) {
      console.error(err);
  }
}
  loggedInPermissions().then();
}, [token])



    return {authPermissions}
};

export default usePermissions;