import React, { useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddDocumentModal from "../../Components/Modal/AddDocumentModal";
import AddFollowUpDateModal from "../../Components/Modal/AddFollowUpDateModal";
import {baseUrl, checkAvailability, paginationTemplate, tablePerPageOptions} from "../../Helper/helper";
import { Toast } from "primereact/toast";
import { VscAdd, VscPreview } from "react-icons/vsc";
import {
  AiOutlineFileExcel,
  AiOutlinePaperClip,
  AiOutlinePlus,
} from "react-icons/ai";
import { TfiReload } from "react-icons/tfi";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import useData from "../../hooks/useData";
import { Dialog } from "primereact/dialog";
import "./Clients.css";
import { BsFiletypeCsv, BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { BiMapPin } from "react-icons/bi";
import { MdOutlineNotificationAdd } from "react-icons/md";
import FollowUpReminderModal from "../../Components/Modal/FollowUpReminderModal";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";

const Clients = () => {
  const { authPermissions } = useData().permissions;
  const menuLeft = useRef(null);
  const [visible, setVisible] = useState(false);
  const [showAddFollowUpModal, setShowAddFollowUpModal] = useState(false);
  const [showAddReminderModal, setShowAddReminderModal] = useState(false);
  const { token } = useData().store;
  const [clients, setClients] = useState([]);
  const [id, setId] = useState(null);
  const toast = useRef(null);
  const [update, setUpdate] = useState(false);
  const [obj, setObj] = useState(null);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedClients, setSelectedClients] = useState(null);
  let rowClick = false;

  // Web Location
  const navigate = useNavigate();
  const webLocation = useLocation();
  const params = webLocation?.search?.split("=")[1];
  const searchPage = webLocation?.hash?.split("=")[1];

  //Pagination
  const [limitData, setLimitData] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  // Check Permissions
  const isClientUpdate = checkAvailability(authPermissions, "update-client");
  const isGetClientInfo = checkAvailability(authPermissions, "get-client-info");
  const isGetClientFollowUp = checkAvailability(
    authPermissions,
    "get-client-follow-up"
  );
  const isCreateClientFollowUp = checkAvailability(
    authPermissions,
    "create-client-follow-up"
  );

  const isCreatePendingFollowup = checkAvailability(
    authPermissions,
    "create-client-follow-up-reminder"
  );
  const isDeleteClient = checkAvailability(authPermissions, "delete-client");


 // Set default page query 1 and calculate prime page number
  useEffect(() => {
    if(!searchPage){
      navigate(`#page=1`)
    }
    else{
      const calculatePage = (searchPage * limitData)-limitData
      setPageNumber(calculatePage)
    }

  }, [searchPage, navigate, limitData]);

  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };


  useEffect(() => {
    const IsGetClient = checkAvailability(authPermissions, "get-client-list");
    if (!IsGetClient) {
      return;
    }
    const clients = async () => {
      setLoading(true);
      try {
        const res = await fetch(
          `${baseUrl.url}/api/clients?${params ? `search=${params}` : ""}&confirmed=0&page=${searchPage}&${limitData && `per_page=${limitData}`}`
          ,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res?.ok && res?.status !== 204) {
          const data = await res.json();
          setClients(data?.data);
        }
        else{
          setClients([])
        }
      } catch (err) {
        console.error(err?.message);
      } finally {
        setLoading(false);
      }
    };
    clients().then();
  }, [
    token,
    update,
    setUpdate,
    limitData,
    authPermissions,
    webLocation,
    params,
    searchPage
  ]);

  const handleSearch = (e) => {
    e.preventDefault();
    const IsGetClient = checkAvailability(authPermissions, "get-client-list");
    if (!IsGetClient) {
      return;
    }
      navigate(`?search=${value}#page=1`);

  };

  const handleReset = () => {
    setValue("");
    setUpdate(!update);
  };

  const handleDelete = async () => {
    if (!isDeleteClient) {
      return;
    }
    const ids = selectedClients?.map((client) => client?.id);
    const postData = {
      ids: ids,
    };
    try {
      const res = await fetch(`${baseUrl.url}/api/clients/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });
      const resData = await res.json();
      if (res.ok) {
        callToast(true, ` ${ids?.length} Client Deleted Successfully`);
        setSelectedClients(null);
        setTimeout(() => {
          setUpdate(!update);
        }, 400);
      } else {
        callToast(false, resData?.error);
      }
    } catch (err) {
      callToast(err?.message);
    }
  };

  const actionTemplate = (data) => {
    const id = data?.id;
    return (
      <div className="flex">
        <button
          onClick={() => {
            setVisible(true);
            setObj(data);
          }}
          className="bg-sky-600 text-white font-semibold
          p-2"
        >
          <AiOutlinePaperClip />
        </button>
        {isClientUpdate && (
          <Link to={`/client/${id}`}>
            <button
              onClick={() => {
                setVisible(true);
                setObj(data);
              }}
              className="bg-sky-500 text-white font-semibold
          p-2"
            >
              <FiEdit />
            </button>
          </Link>
        )}
      </div>
    );
  };

  const nameTemplate = (data) => {
    let id = data?.id;
    return (
      <Link
        to={`${isGetClientInfo ? `/client/${id}` : ""}`}
        className="underline curser-pointer"
      >
        {data.name}
      </Link>
    );
  };

  const interestTemplate = (data) => {
    const {interest_status} = data
    return (
      <p className="text-center">
        {Number(interest_status) === 0 ? interest_status : `${interest_status}%`}
      </p>
    );
  };

  const followUpTemplate = (data) => {
    let id = data?.id;
    return (
      <div className="flex">
        {isGetClientFollowUp && (
          <Link to={`/follow_up/${id}`}>
            <button className="bg-sky-500 text-white p-2">
              <VscPreview className="text-white" />
            </button>
          </Link>
        )}

        {isCreateClientFollowUp && (
          <button
            className="bg-sky-600 text-white font-semibold p-2"
            onClick={() => {
              setShowAddFollowUpModal(!showAddFollowUpModal);
              setId(id);
            }}
          >
            <VscAdd />
          </button>
        )}
        {isCreatePendingFollowup && (
          <button
            onClick={() => {
              setShowAddReminderModal(!showAddReminderModal);
              setId(id);
            }}
            className="bg-sky-500 text-white font-semibold p-2"
          >
            <MdOutlineNotificationAdd />
          </button>
        )}
      </div>
    );
  };

  const phoneTemplate = (data) => {
    const {phone_no} = data
    return <span>{phone_no.startsWith(1) ? `0${phone_no}` : phone_no}</span>;
  };
  const dateTemplate = (data) => {
    const dateString = data?.created_at;
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  };
  const [mapLocation, setMapLocation] = useState({});
  const [visibleMap, setVisibleMap] = useState(false);
  const mapButton = (data) => {
    const { latitude, longitude } = data;
    return (
      <div className="mapBtn flex justify-center">
        <button
          onClick={() => {
            if(!latitude && !longitude) {
              callToast(false, "Location latitude, longitude not found")
              return
            }
            setVisibleMap(true);
            setMapLocation({ latitude, longitude });
          }}
        >
          <BiMapPin />
        </button>
      </div>
    );
  };

  const handleExportData = (type) => {
    const random_number = new Date().getTime();
    const exportData = async () => {
      const res = await fetch(
        `${baseUrl.url}/api/clients/export/all?type=${type}&confirmed=0`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.blob();
      if (res.ok) {
        callToast(true, `Clients ${type} download successfully`);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `clients_${random_number}.${type}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        callToast(false, data?.error);
      }
    };
    exportData().then();
  };
  let allItems = [
    {
      label: "Add client",
      icon: <AiOutlinePlus />,
      permission: "create-client",
      command: () => navigate("/add-client"),
    },

    {
      label: "Export",
      permission: "export-client-list",
      items: [
        {
          label: "XLSX",
          icon: <AiOutlineFileExcel />,
          command: () => handleExportData("xlsx"),
        },
        {
          label: "CSV",
          icon: <BsFiletypeCsv />,
          command: () => handleExportData("csv"),
        },
      ],
    },
  ];
  const items = allItems?.filter((item) =>
    authPermissions?.find((perm) => perm?.name === item?.permission)
  );

  return (
      <div>
          <Toast ref={toast} />
          {mapLocation?.longitude && mapLocation?.latitude && (
            <Dialog
              header="Locations"
              visible={visibleMap}
              modal={false}
              className="w-11/12 sm:w-10/12 lg:w-8/12 2xl:w-2/4"
              onHide={() => {
                setVisibleMap(false);
                setMapLocation({});
              }}
            >
              {mapLocation?.longitude &&
              mapLocation?.latitude !== "undefined" ? (
                <iFrame
                  className={"w-full"}
                  style={{ height: "70vh" }}
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?q=${mapLocation?.latitude},${mapLocation?.longitude}&key=AIzaSyBwhrTgTkNHAoeXcPVaJZ8TZUoDqINXFmI`}
                ></iFrame>
              ) : (
                <div>
                  <div>
                    <img
                      src="https://s1.eestatic.com/2015/10/12/elandroidelibre/el_androide_libre_71003130_179986200_1706x960.jpg"
                      className="w-3/4 lg:w-1/4 mx-auto"
                      alt=""
                    />
                  </div>
                  <h1 className="text-xl text-center">No location found</h1>
                  <p className="text-base text-center">
                    No coordinates added or found nothing
                  </p>
                </div>
              )}
            </Dialog>
          )}

          <div>
            <div className="flex items-center justify-between">
              <SectionHeading
                title={"All Clients"}
                text={"Client list for ecommerce"}
              />

              {items?.length > 0 && (
                <div className="flex justify-end">
                  <div className="zero">
                    <Button
                      icon={<BsThreeDotsVertical className="dark:text-white" />}
                      onClick={(event) => menuLeft.current.toggle(event)}
                      aria-controls="popup_menu_left"
                      aria-haspopup
                    />
                  </div>
                  <Menu
                    model={items}
                    popup
                    ref={menuLeft}
                    id="popup_menu_left"
                  />
                </div>
              )}
            </div>

            <form onSubmit={handleSearch}>
              <div className="grid grid-cols-12 justify-between items-center just mb-1 sm:mb-3 gap-2 mt-3">
                  <div className="col-span-9 sm:col-span-8 lg:col-span-6 xl:col-span-5 flex border p-1 dark:bg-gray-800 dark:border-gray-700">
                    <input
                      type="search"
                      className="w-full focus:outline-0 pl-3 py-1 lg:py-0 dark:bg-gray-800 dark:text-white"
                      onChange={(e) => {
                        const inputValue = e.target.value
                        if(!inputValue) {
                          navigate(`#page=${searchPage}`)
                        }
                          setValue(inputValue)

                      }}
                      placeholder="Search by company/name/email/area"
                    />
                    <button
                      type="submit"
                      className="hidden lg:block h-8 cursor-pointer text-white bg-sky-500 px-2"
                    >
                      Search
                    </button>
                  </div>
                <div className="col-span-3 sm:col-span-4 lg:col-span-1 flex justify-start">
                  {clients?.data?.length > 0 && (
                    <button
                      onClick={handleReset}
                      className="bg-sky-600 text-white w-10 h-10 flex justify-center items-center rounded-sm"
                    >
                      <TfiReload size={20} />
                    </button>
                  )}

                  {isDeleteClient &&
                    clients?.data?.length > 0 &&
                    selectedClients?.length > 0 && (
                      <button
                        onClick={handleDelete}
                        className="lg:text-base ml-2 w-9 h-9 flex justify-center items-center bg-red-500 text-white"
                      >
                        <RiDeleteBinLine />
                      </button>
                    )}
                </div>
              </div>
            </form>

            <DataTable
                value={clients?.data}
                key={pageNumber}
                className="datatable-responsive myTable"
                emptyMessage="No client found."
                rowHover={true}
                loading={loading}
                lazy
                paginator={clients?.total > 0 }
                rows={limitData}
                totalRecords={clients?.total}
                first={pageNumber}
                onPage={(e) => {
                  setPageNumber(e.first);
                  setLimitData(e.rows);
                  let pages = Math.ceil(e.first / e.rows + 1);
                  navigate(`${value ? `?search=${value}` : ""}#page=${pages}`)
                }}
                pageLinkSize={10}
                rowsPerPageOptions={tablePerPageOptions}
                paginatorTemplate={paginationTemplate}
                sortMode="multiple"
                selection={selectedClients}
                onSelectionChange={(e) => setSelectedClients(e.value)}
                selectionMode={rowClick ? null : "checkbox"}
                showGridlines={true}
            >
              {isDeleteClient && (
                  <Column
                      selectionMode="multiple"
                      headerStyle={{ width: "3rem" }}
                      className="dark:bg-gray-800 dark:text-white"
                  ></Column>
              )}
              <Column
                  field="created_at"
                  header="Date"
                  body={dateTemplate}
                  className="dark:bg-gray-800 dark:text-white"
              ></Column>
              <Column
                  field="company"
                  header="Company Name"
                  className="dark:bg-gray-800 dark:text-white"
              ></Column>
              <Column
                  field="name"
                  header="Name"
                  body={nameTemplate}
                  className="dark:bg-gray-800 dark:text-white"
              ></Column>
              <Column
                  field="email"
                  header="Email"
                  className="dark:bg-gray-800 dark:text-white"
              ></Column>
              <Column
                  field="phone_no"
                  header="Phone"
                  body={phoneTemplate}
                  className="dark:bg-gray-800 dark:text-white"
              ></Column>
              <Column
                  field="interest"
                  header="Interest(%)"
                  body={interestTemplate}
                  className="dark:bg-gray-800 dark:text-white"
              ></Column>
              {isGetClientFollowUp || isCreateClientFollowUp ? (
                  <Column
                      field="followUp_date"
                      header="Follow Up"
                      body={followUpTemplate}
                      className="dark:bg-gray-800 dark:text-white"
                  ></Column>
              ) : null}
              <Column
                  header="Map"
                  body={mapButton}
                  className="dark:bg-gray-800 dark:text-white "
              ></Column>
              {isClientUpdate && (
                  <Column
                      header="Options"
                      body={actionTemplate}
                      className="dark:bg-gray-800 dark:text-white"
                  ></Column>
              )}
            </DataTable>


          </div>
          {visible && (
            <AddDocumentModal
              visible={visible}
              setVisible={setVisible}
              obj={obj}
              setObj={setObj}
              update={update}
              setUpdate={setUpdate}
            />
          )}
          {showAddFollowUpModal && (
            <AddFollowUpDateModal
              visible={showAddFollowUpModal}
              setVisible={setShowAddFollowUpModal}
              id={id}
              setId={setId}
            />
          )}
          {showAddReminderModal && (
            <FollowUpReminderModal
              visible={showAddReminderModal}
              setVisible={setShowAddReminderModal}
              id={id}
              setId={setId}
            />
          )}
        </div>
  );
};
export default Clients;
