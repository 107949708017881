import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import { HiArrowRight } from "react-icons/hi2";
import useData from "../../hooks/useData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl, checkAvailability } from "../../Helper/helper";
import { Geolocation } from "@capacitor/geolocation";
import { Toast } from "primereact/toast";
import Label from "../General/Label";
const AddAllowance = ({ props }) => {
  let { end } = props;
  const { id } = useParams();
  const [selectedTransportType, setSelectedTransportType] = useState(null);
  const { token } = useData().store;
  const [selectedClient, setSelectedClient] = useState(false);
  const [selectedFollowUp, setSelectedFollowUp] = useState(false);
  const [currentJourneyData, setCurrentJourneyData] = useState();
  const [visibleForm, setVisibleForm] = useState(!!id);
  const [selectedVisitType, setSelectedVisitType] = useState(null);
  const [followUpData, setFollowUpData] = useState([]);
  const [transportAllowance, setTransportAllowance] = useState(null);
  const { authPermissions } = useData().permissions;
  const isCreateUpdateTransportAllowances = checkAvailability(
    authPermissions,
    "create-update-transport-allowance"
  );
  const isGetCurrentAllowance = checkAvailability(
    authPermissions,
    "get-transport-allowance"
  );
  const isGetClientFollowUp = checkAvailability(
    authPermissions,
    "get-client-follow-up"
  );
  const [isUpdate, setIsUpdate] = useState(true);

  const [location, setLocation] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    const getCurrentPosition = async () => {
      try {
        const position = await Geolocation.getCurrentPosition();
        console.log("position..........", position)
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      } catch (error) {
        console.error("Error getting current position", error);
      }
    };
    getCurrentPosition().then();
  }, []);

  // const [url, setUrl] = useState(null);
  let url = null;
  // const [perPageLimit, setPerPageLimit] = useState(12);
  let perPageLimit = 12;
  const [clients, setClients] = useState(false);

  const webLocation = useLocation();
  const params = webLocation?.search?.split("=")[1];
  const [startData, setStartData] = useState({
    amount: "",
    document: "",
    notes: "",
    startForm: "",
    endTo: "",
  });

  const handleStartData = (e, value) => {
    setStartData({ ...startData, [e.target.name]: value });
  };

  // Get Ongoing Allowance
  useEffect(() => {
    if (!end) {
      return;
    }

    const getAllowances = async () => {
      if (!isGetCurrentAllowance || !end) {
        return;
      }
      try {
        const res = await fetch(
          `${baseUrl.url}/api/transport-allowances/current`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();

        if (res.ok) {
          const currentData = data?.data;
          setCurrentJourneyData(currentData);
          setSelectedVisitType({ name: data?.data?.visit_type });
          if (currentData) {
            setVisibleForm(true);
          }
        }
      } catch (error) {
        console.error(error?.message);
      }
    };
    getAllowances().then();
  }, [token, end, isGetCurrentAllowance]);

  // Get allowance details
  useEffect(() => {
    if (!id) {
      return;
    }
    const getPreviousAllowance = async () => {
      if (!isCreateUpdateTransportAllowances) {
        return;
      }
      try {
        const res = await fetch(
          `${baseUrl.url}/api/transport-allowances/get/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();

        if (res.ok) {
          const currentData = data?.data;
          const status = data?.data?.allowance_status;
          const isEnd = data?.data?.end_time;
          if (status !== 0) {
            setIsUpdate(false);
          } else {
            if (isEnd) {
              setIsUpdate(true);
            } else {
              setIsUpdate(false);
            }
          }
          setTransportAllowance(currentData);
          setSelectedVisitType({ name: data?.data?.visit_type });
          if (currentData) {
            setVisibleForm(true);
          }
        }
      } catch (error) {
        console.error(error?.message);
      }
    };
    getPreviousAllowance().then();
  }, [token, id, isCreateUpdateTransportAllowances]);

  //FollowUp data by client id
  useEffect(() => {
    if (!isGetClientFollowUp) {
      return;
    }
    const getFollowUpData = async () => {
      try {
        const res = await fetch(
          `${baseUrl.url}/api/clients-follow-up/${selectedClient?.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setFollowUpData(data?.data);
        }
      } catch (error) {
        console.error(error?.message);
      }
    };
    getFollowUpData().then();
  }, [token, selectedClient?.id, isGetClientFollowUp]);

  // FETCHING ALL CLIENTS
  useEffect(() => {
    const IsGetClient = checkAvailability(authPermissions, "get-client-list");
    if (!IsGetClient) {
      return;
    }
    const clients = async () => {
      try {
        const res = await fetch(
          `${
            url
              ? url + `&per_page=${perPageLimit}`
              : `${baseUrl.url}/api/clients?${
                  params && `confirmed=0&search=${params}`
                }&confirmed=0&${perPageLimit && `per_page=${perPageLimit}`}`
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setClients(data?.data);
        }
      } catch (err) {
        console.error(err?.message);
      } finally {
      }
    };
    clients().then();
  }, [token, url, perPageLimit, authPermissions, webLocation, params]);
  // FIELD VISIT TYPES
  const fieldVisitType = [
    {
      name: "Client Hunt",
    },
    {
      name: "Client Visit",
    },
    {
      name: "Both",
    },
  ];

  // TRANSPORTATION TYPES
  const transportType = [
    {
      name: "Bus",
    },
    {
      name: "CNG",
    },
    {
      name: "Uber",
    },
    {
      name: "Bike",
    },
    {
      name: "Rickshaw",
    },
    {
      name: "Train",
    },
    {
      name: "Plane",
    },
  ];
  // TEMPLATE FUNCTION FOR CLIENT SEARCH DROPDOWN
  const selectedClientTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option?.code?.toLowerCase()}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  // TEMPLATE FUNCTION FOR CLIENT SEARCH DROPDOWN
  const clientOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option?.code?.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const selectedFollowUpTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">{option?.occurred_on}</div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const followUpTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option?.occurred_on}</div>
      </div>
    );
  };
  const toast = useRef(null);
  const callToast = (type, detail) => {
    toast.current.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isCreateUpdateTransportAllowances) {
      return;
    }

    let formData = new FormData();

    if (currentJourneyData?.id) {
      const endData = {
        to_lat: location?.latitude,
        to_lng: location?.longitude,
        visit_type: selectedVisitType?.name
          ? selectedVisitType?.name
          : currentJourneyData?.visit_type,
        transport_type: selectedTransportType
          ? selectedTransportType?.name
          : currentJourneyData?.transport_type,
        document: startData?.document ? startData?.document : null,
        amount: startData?.amount
          ? startData?.amount
          : currentJourneyData?.amount,
        client_id: selectedClient ? selectedClient?.id : null,
        follow_up_id: selectedFollowUp
          ? selectedFollowUp?.id
          : currentJourneyData?.follow_up_id,
        note: startData?.notes ? startData?.notes : currentJourneyData?.notes,
        from_address: startData?.startForm
          ? startData?.startForm
          : currentJourneyData?.from_address
          ? currentJourneyData?.from_address
          : "",
        to_address: startData?.endTo
          ? startData?.endTo
          : currentJourneyData?.to_address
          ? currentJourneyData?.to_address
          : "",
      };

      if (!endData?.amount) {
        callToast(false, "Amount is required");
        return;
      } else if (!endData?.transport_type) {
        callToast(false, "Transport type is required");
        return;
      } else if (!selectedVisitType?.name) {
        callToast(false, "Visit type is required");
        return;
      } else if (!endData?.from_address) {
        callToast(false, "Start location must be needed");
        return;
      } else if (!endData?.to_address) {
        callToast(false, "End location must be needed");
        return;
      }
      for (let i in endData) {
        if (endData[i]) {
          formData.append(i, endData[i]);
        }
      }
      try {
        const res = await fetch(
          `${baseUrl.url}/api/transport-allowances/end/${currentJourneyData?.id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const resData = await res.json();

        if (res.ok) {
          callToast(true, "End journey successfully");
          setTimeout(() => {
            navigate("/ta");
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
      } catch (err) {
        callToast(false, err?.message);
      }
    } else {
      if (transportAllowance?.allowance_status === 0) {
        const updateData = {
          document: startData?.document ? startData?.document : null,
          note: startData?.notes ? startData?.notes : transportAllowance?.note,
        };
        if (updateData?.document) {
          formData.append("document", updateData?.document);
        }
        formData.append("note", updateData?.note);
        try {
          const res = await fetch(
            `${baseUrl.url}/api/transport-allowances/update/${id}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );
          const resData = await res.json();

          if (res.ok) {
            callToast(true, "Travel Allowance updated successfully");
            setTimeout(() => {
              navigate("/ta");
            }, 400);
          } else {
            callToast(false, resData?.error);
          }
        } catch (err) {
          callToast(false, err?.message);
        }
      } else {
        const postData = {
          from_lat: location?.latitude,
          from_lng: location?.longitude,
          visit_type: selectedVisitType?.name ? selectedVisitType?.name : null,
          transport_type: selectedTransportType?.name
            ? selectedTransportType?.name
            : null,
          document: startData?.document ? startData?.document : null,
          amount: startData?.amount ? startData?.amount : null,
          client_id: selectedClient?.id ? selectedClient?.id : null,
          follow_up_id: selectedFollowUp?.id ? selectedFollowUp?.id : null,
          note: startData?.notes ? startData?.notes : null,
          from_address: startData?.startForm ? startData?.startForm : null,
          to_address: startData?.endTo ? startData?.endTo : null,
        };
        for (let i in postData) {
          if (postData[i]) {
            formData.append(i, postData[i]);
          }
        }
        try {
          const res = await fetch(
            `${baseUrl.url}/api/transport-allowances/start`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );
          const resData = await res.json();
          if (res.ok) {
            callToast(true, "Start journey successfully");
            setTimeout(() => {
              navigate("/ta");
            }, 400);
          } else {
            callToast(false, resData?.error);
          }
        } catch (err) {
          callToast(false, err?.message);
        }
      }
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <h1 className="text-base text-sky-500 font-semibold">Travel Allowance</h1>

      <p className="text-slate-600 text-sm dark:text-white">
        Request for travel allowance bellow
      </p>
      {
        <div className="flex items-center mt-4">
          <button
            onClick={() => setVisibleForm(!visibleForm)}
            className="flex items-center text-xs lg:text-base bg-sky-500 text-white p-2 px-4 rounded"
          >
            {transportAllowance?.id
              ? transportAllowance?.end_time
                ? "Update"
                : "Ongoing Allowance"
              : currentJourneyData?.id
              ? "Current Allowance"
              : "  Start A Journey"}{" "}
            <HiArrowRight className="ml-2 lg:ml-4 " />
          </button>
        </div>
      }

      {visibleForm && (
        <form onSubmit={handleSubmit}>
          <div className="mt-8">
            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-2">
                <Label title={"Date"} required={true}/>
              </div>
              <div className="col-span-12 md:col-span-8">
                <input
                  type="date"
                  readOnly
                  value={new Date().toISOString().split("T")[0]}
                  className="p-3 border border-slate-300 w-48 rounded-md focus:ring-4 focus:outline-none focus:ring-indigo-200 focus:border-violet-500 dark:bg-gray-700 date-input
                   dark:text-white"
                />
              </div>
            </div>
            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-2">
                <Label title={"Field visit type"} required={true}/>
              </div>
              <div className="col-span-12 md:col-span-8">
                <Dropdown
                  value={selectedVisitType}
                  onChange={(e) => setSelectedVisitType(e.target.value)}
                  optionLabel="name"
                  placeholder={
                    currentJourneyData?.visit_type
                      ? currentJourneyData?.visit_type
                      : transportAllowance?.visit_type
                      ? transportAllowance?.visit_type
                      : "Select type"
                  }
                  className="w-full dark:bg-transparent "
                  options={fieldVisitType}
                  disabled={transportAllowance && true}
                />
              </div>
            </div>
            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-2">
                <Label title={"Transport type"} required={true}/>
              </div>
              <div className="col-span-12 md:col-span-8">
                <Dropdown
                  value={selectedTransportType}
                  onChange={(e) => setSelectedTransportType(e.target.value)}
                  optionLabel="name"
                  placeholder={
                    currentJourneyData?.transport_type
                      ? currentJourneyData?.transport_type
                      : transportAllowance?.transport_type
                      ? transportAllowance?.transport_type
                      : "Select type"
                  }
                  className="w-full dark:bg-transparent"
                  options={transportType}
                  disabled={transportAllowance && true}
                />
              </div>
            </div>
            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-2">
                <Label title={"Amount"} required={true}/>
              </div>
              <div className="col-span-12 md:col-span-8">
                <input
                  type="number"
                  name="amount"
                  defaultValue={
                    currentJourneyData?.amount
                      ? currentJourneyData?.amount
                      : transportAllowance
                      ? transportAllowance?.amount
                      : transportAllowance?.visit_type &&
                        transportAllowance?.visit_type
                  }
                  min={10}
                  max={1000}
                  onChange={(e) => handleStartData(e, e.target.value)}
                  className="w-full p-3 border border-slate-300 text-gray-800 rounded-md focus:ring-4 focus:outline-none focus:ring-indigo-200 focus:border-violet-500 dark:bg-gray-700 dark:text-white"
                  placeholder="00.00 tk"
                  disabled={transportAllowance?.id && true}
                />
              </div>
            </div>

            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-2">
                <Label title={"Form"} required={!!currentJourneyData?.id}/>
              </div>
              <div className="col-span-12 md:col-span-8">
                <input
                  type="text"
                  name="startForm"
                  defaultValue={
                    currentJourneyData?.from_address
                      ? currentJourneyData?.from_address
                      : transportAllowance
                      ? transportAllowance?.from_address
                      : ""
                  }
                  onChange={(e) => handleStartData(e, e.target.value)}
                  className="w-full p-3 border border-slate-300 rounded-md focus:ring-4 focus:outline-none focus:ring-indigo-200 focus:border-violet-500 dark:bg-gray-700 dark:text-white"
                  placeholder="From location"
                  disabled={transportAllowance && true}
                />
              </div>
            </div>

            <div className="grid grid-cols-12 mb-2">
              <div className="col-span-12 md:col-span-2">
                <Label title={"To"} required={!!currentJourneyData?.id}/>
              </div>
              <div className="col-span-12 md:col-span-8">
                <input
                  type="text"
                  name="endTo"
                  defaultValue={
                    currentJourneyData?.to_address
                      ? currentJourneyData?.to_address
                      : transportAllowance
                      ? transportAllowance?.to_address
                      : ""
                  }
                  onChange={(e) => handleStartData(e, e.target.value)}
                  className="w-full p-3 border border-slate-300 rounded-md focus:ring-4 focus:outline-none focus:ring-indigo-200 focus:border-violet-500 dark:bg-gray-700 dark:text-white"
                  placeholder="Destination location"
                  disabled={transportAllowance && true}
                />
              </div>
            </div>

            <div className="mt-12">
              <div className="grid grid-cols-12 mb-2">
                <div className="col-span-12 md:col-span-2">
                  <Label title={" Documents / File"}/>
                </div>
                <div className="col-span-12 md:col-span-8">
                  <input
                    type="file"
                    name="document"
                    onChange={(e) => handleStartData(e, e.target.files[0])}
                    className="dark:bg-gray-700 dark:text-white"
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 mb-2">
                <div className="col-span-12 md:col-span-2">
                  <Label title={"Clients"}/>
                </div>
                <div className="col-span-12 md:col-span-8">
                  <Dropdown
                    value={selectedClient}
                    onChange={(e) => setSelectedClient(e.target.value)}
                    optionLabel="name"
                    placeholder={
                      currentJourneyData?.follow_up
                        ? currentJourneyData?.client?.name
                        : transportAllowance?.client
                        ? transportAllowance?.client?.name
                        : "Select Client"
                    }
                    className="w-full dark:bg-transparent"
                    filter
                    valueTemplate={selectedClientTemplate}
                    itemTemplate={clientOptionTemplate}
                    options={clients?.data}
                    disabled={transportAllowance && true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 mb-2">
                <div className="col-span-12 md:col-span-2">
                  <Label title={" Follow up"}/>
                </div>
                <div className="col-span-12 md:col-span-8">
                  <Dropdown
                    value={selectedFollowUp}
                    onChange={(e) => setSelectedFollowUp(e.target.value)}
                    optionLabel="name"
                    placeholder={
                      currentJourneyData?.client
                        ? currentJourneyData?.follow_up?.occurred_on
                        : transportAllowance?.follow_up
                        ? transportAllowance?.follow_up?.occurred_on
                        : "Select Follow up"
                    }
                    className="w-full dark:bg-transparent "
                    itemTemplate={followUpTemplate}
                    valueTemplate={selectedFollowUpTemplate}
                    options={followUpData}
                    disabled={transportAllowance && true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 mb-2">

                <div className="col-span-12 md:col-span-2">
                  <Label title={"Remarks"}/>
                </div>
                <div className="col-span-12 md:col-span-8">
                  <textarea
                    name="notes"
                    defaultValue={
                      currentJourneyData?.note
                        ? currentJourneyData?.note
                        : transportAllowance?.note && transportAllowance?.note
                    }
                    onChange={(e) => handleStartData(e, e.target.value)}
                    placeholder="Comments for this day"
                    id=""
                    className="w-full border focus:outline-none border-slate-300 rounded-md p-2 dark:bg-gray-700 dark:text-white"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              {isUpdate && (
                <div className="grid grid-cols-12 mt-4">
                  <div className="col-span-2"></div>
                  <div className="col-span-8">
                    <button className="bg-sky-500 text-white p-2 px-4 rounded">
                      {currentJourneyData?.id ? "End Journey" : "Submit"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddAllowance;
