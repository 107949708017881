import React, { useEffect, useRef, useState } from "react";
import GoBack from "../../GoBack/GoBack";
import useData from "../../../hooks/useData";
import { baseUrl, checkAvailability } from "../../../Helper/helper";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdRemoveCircleOutline } from "react-icons/md";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

const CreateBill = () => {
  const [services, setServices] = useState([]);
  const [clients, setClients] = useState([]);
  const { authPermissions } = useData().permissions;
  const { token } = useData().store;
  const [selectedClient, setSelectedClient] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const navigate = useNavigate();
  const [notes, setNotes] = useState("");
  const toast = useRef();
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };
  const IsGetClient = checkAvailability(authPermissions, "get-client-list");
  const isGetService = checkAvailability(authPermissions, "get-service");
  console.log("sss", searchValue);
  useEffect(() => {
    if (!IsGetClient) {
      return;
    }
    const clients = async () => {
      try {
        const res = await fetch(
          `${baseUrl?.url}/api/clients?per_page=10&get_all=1${
            searchValue && `&search=${searchValue}`
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();

        if (res.ok) {
          const allClients = data?.data?.data;
          if (allClients.length > 0) {
            setClients(allClients);
          }
        }
      } catch (err) {
        console.error(err?.message);
      }
    };
    clients().then();
  }, [token, IsGetClient, searchValue]);
  useEffect(() => {
    if (!isGetService) {
      return;
    }
    const getServices = async () => {
      try {
        const res = await fetch(`${`${baseUrl.url}/api/services?status=1`}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setServices(data?.data);
        }
      } catch (error) {
        console.error(error?.message);
      }
    };
    getServices().then();
  }, [token, isGetService]);

  const selectedClientTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option?.code?.toLowerCase()}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const clientOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option?.code?.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const selectedServiceTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex items-center justify-between">
          <p className=" font-medium">{option?.name}</p>
          <p className="font-semibold"> ৳ {option?.price}</p>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const handleService = (e) => {
    const obj = e.target.value;
    if (!obj) {
      return;
    }
    const newServices = [...selectedServices, obj];
    if (selectedServices?.length > 1) {
      const ids = selectedServices?.map((service) => service?.id);
      if (!ids?.includes(obj?.id)) {
        setSelectedServices(newServices);
      } else {
        callToast(false, "Already selected this service!");
      }
    } else {
      setSelectedServices(newServices);
    }
  };

  const handleRemoveService = (id) => {
    const newServices = selectedServices?.filter((data) => data?.id !== id);
    setSelectedServices(newServices);
  };

  const actionTemplate = (data) => {
    return (
      <div className="flex justify-end items-center">
        <button
          onClick={() => {
            handleRemoveService(data?.id);
          }}
          className="text-red-600 font-semibold p-2"
        >
          <MdRemoveCircleOutline size="18" />
        </button>
      </div>
    );
  };
  const handleSubmit = async () => {
    if (!selectedClient?.id) {
      callToast(false, "Client is required");
      return;
    }
    if (selectedServices?.length < 1) {
      callToast(false, "No service selected");
      return;
    }
    const serviceIds = selectedServices?.map((service) => service?.id);
    const postData = {
      client_id: selectedClient?.id,
      services: serviceIds,
      remarks: notes ? notes : "",
    };
    try {
      const res = await fetch(`${baseUrl.url}/api/bills`, {
        method: "POST",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });
      const resData = await res.json();
      if (res.ok) {
        callToast(true, "Bill created successfully");
        navigate("/bill");
      } else {
        callToast(false, resData?.error);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  const total = selectedServices?.reduce(
    (accumulator, service) => accumulator + service?.price,
    0
  );

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex justify-between">
        <div>
          <h1 className="text-base text-sky-500 font-semibold">Billing</h1>
          <p className="text-slate-600 text-sm dark:text-white">
            Create a new bill
          </p>
        </div>
        <GoBack />
      </div>

      <div className="mt-4 w-full xl:w-7/12">
        <div>
          <p className="text-gray-500 text-sm py-2 font-semibold dark:text-white">
            Clients
          </p>
          <Dropdown
            value={selectedClient}
            onChange={(e) => {
              setSelectedClient(e.target.value);
            }}
            onFilter={(e) => {
              setSearchValue(e.filter);
            }}
            optionLabel="name"
            placeholder={"Select Client"}
            className="w-full dark:bg-gray-800"
            filter
            filterValue={searchValue}
            filterPlaceholder="Search"
            valueTemplate={selectedClientTemplate}
            itemTemplate={clientOptionTemplate}
            options={clients}
          />
        </div>
        <div className="my-3">
          <p className="text-gray-500 text-sm py-2 font-semibold dark:text-white">
            Services
          </p>
          <Dropdown
            onChange={(e) => {
              handleService(e);
            }}
            placeholder={"Select service"}
            className="w-full dark:bg-gray-800"
            // filter
            // filterPlaceholder="Search"
            // valueTemplate={selectedServiceTemplate}
            itemTemplate={selectedServiceTemplate}
            options={services}
          />
          {selectedServices?.length > 0 && (
            <div className="mt-4 w-full">
              <DataTable
                value={selectedServices}
                tableStyle={{ minWidth: "50rem" }}
                size="small"
              >
                <Column
                  field="name"
                  header="Service"
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  header="Price"
                  className="dark:bg-gray-800 dark:text-white"
                  body={(data) => {
                    return <p> ৳ {data?.price}</p>;
                  }}
                ></Column>
                <Column
                  header="Action"
                  className=" flex justify-end h-full dark:bg-gray-800 dark:text-white"
                  body={actionTemplate}
                ></Column>
              </DataTable>
              <p className="bg-slate-50 text-right p-2 text-lg font-semibold text-gray-700">
                Total Amount: ৳ {total}
              </p>
            </div>
          )}
        </div>

        <div className="my-2">
          <label className="text-gray-500 text-sm py-2 font-semibold dark:text-white">
            Notes
          </label>
          <InputTextarea
            type="text"
            name="description"
            onChange={(e) => setNotes(e.target.value)}
            className="w-full dark:bg-transparent dark:text-white"
            placeholder="type here"
            rows={3}
            cols={30}
          />
        </div>
        <div className="mt-4">
          <Button
            onClick={handleSubmit}
            type="submit"
            size="small"
            label="Submit"
            severity="info"
          />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default CreateBill;
