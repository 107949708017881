import React, {useRef, useState} from 'react';
import {Toast} from "primereact/toast";
import GoBack from "../../GoBack/GoBack";
import {InputText} from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {baseUrl, callToast} from "../../../Helper/helper";
import useData from "../../../hooks/useData";
import {useNavigate} from "react-router-dom";

const CreateCustomBill = () => {
    const { token } = useData().store;
    const navigate = useNavigate()
    const toast = useRef()
    const [data, setData] = useState({})
    const [services, setServices] = useState([
        {
            id: 1,
            item: "",
            quantity: 1,
            amount: ""
        }
    ])
    const handleData = (e) => {
         setData({
             ...data,
             [e.target.name] : e.target.value
         })
    }
    const generateUniqueId = () => {
        return Math.floor(Math.random() * 900) + 10;
    };
const handlePlus = () => {
        const newService = {
             id: generateUniqueId(),
            item: "",
            quantity: 1,
            amount: ""
        }
        const updatedServices = [...services, newService]
        setServices(updatedServices)
}
const removeRow = (id) => {
    const updatedData = services.filter(service => service?.id !==id)
    setServices(updatedData)
}
const handleServiceData = (id, name, value) => {
     const newData = services?.map(service => {
         if(service?.id === id){
             return {
                 ...service,
                 [name] : value
             }
         }
         return service
     })
    setServices(newData)
}


const serviceNameTemplate = (row) => {
    return  <div className={"w-full"}>
        <InputText name="item" onChange={(e) => handleServiceData(row?.id, "item", e.target.value)} className={"w-full"}/>
    </div>
}

const quantityTemplate = (row) => {
    return <div className={"w-full"}>
        <InputNumber name="quantity" className={"w-1/3"} value={row?.quantity} mode="decimal" showButtons min={1}  onChange={(e) => handleServiceData(row?.id, "quantity", e.value)}/>
    </div>
}


const amountTemplate = (row) => {
    return  <div className={"w-full"}>
        <InputNumber name="amount" className={"w-full"}  onChange={(e) => handleServiceData(row?.id, "amount", e.value)}/>
    </div>
}
    const actionTemplate = (row) => {
        return (
            <Button
                icon="pi pi-times"
                rounded
                outlined
                severity="danger"
                aria-label="Cancel"
                onClick={() => removeRow(row.id)}
            />
        );
    };

const handleCreateBill = async() => {
    // validation
    if(!data?.client_name){
        callToast(toast, false, "Client name is required")
        return
    }
    if(!data?.client_email){
        callToast(toast, false, "Client email is required")
        return
    }
    if(!data?.client_phone){
        callToast(toast, false, "Client phone is required")
        return
    }
    if(!data?.client_company){
        callToast(toast, false, "Client company is required")
        return
    }
    // check if services length
    if (services?.length === 0) {
        callToast(toast, false, "A service must have at least one field.");
        return;
    }
    // check if service name is empty
    if (services?.some((service) => service.item === "")) {
        callToast(toast, false, "All the service names are required.");
        return;
    }

    // check if service amount is empty
    if (services?.some((service) => service.amount === "")) {
        callToast(toast, false, "All the service amounts are required.");
        return;
    }

    // check if Service names are unique
    let hasDuplicateServiceName = services?.some(
        (item, index) =>
            services?.findIndex(
                (objItem) => objItem?.item === item.item
            ) !== index
    );
    if (hasDuplicateServiceName) {
        callToast(toast, false, "Duplicate service name are not allowed.");
        return;
    }
    const modifyServices = services?.map(service => {
        return {
            item: service?.item,
            quantity: service?.quantity,
            amount: service?.amount
        }
    })
    const postData = {...data, items: modifyServices}
    console.log("pp", postData)

    try {
        const res = await fetch(`${baseUrl.url}/api/custom-bills`, {
            method: "POST",
            headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData),
        });
        const resData = await res.json();
        if (res.ok) {
            callToast(toast,true, "Bill created successfully");
            navigate("/custom/bill");
        } else {
            callToast(toast,false, resData?.error);
        }
    } catch (error) {
        console.error(error?.message);
    }
}


    return (
        <div>
            <Toast ref={toast} />
            <div className="flex justify-between">
                <div>
                    <h1 className="text-base text-sky-500 font-semibold">Custom Billing</h1>
                    <p className="text-slate-600 text-sm dark:text-white">
                        Create a new custom bill
                    </p>
                </div>
                <GoBack />
            </div>
            <div className="mt-4 w-full">
               <div className={"flex gap-3"}>
                   <div className={"w-full"}>
                       <p className="text-gray-500 text-sm py-2 font-semibold dark:text-white">
                           Client Name
                       </p>
                       <InputText name="client_name" onChange={handleData} className={"w-full"}/>
                   </div>
                   <div className={"w-full"}>
                       <p className="text-gray-500 text-sm py-2 font-semibold dark:text-white">
                           Client Email
                       </p>
                       <InputText type={"email"} name="client_email" onChange={handleData} className={"w-full"}/>
                   </div>
               </div>
                <div className={"flex gap-3"}>
                    <div className={"w-full"}>
                        <p className="text-gray-500 text-sm py-2 font-semibold dark:text-white">
                            Client Phone
                        </p>
                        <InputText type={"number"} name="client_phone"  min={0} className={"w-full"} onChange={handleData}

                       />
                    </div>
                    <div className={"w-full"}>
                        <p className="text-gray-500 text-sm py-2 font-semibold dark:text-white">
                            Client Company
                        </p>
                        <InputText maxLength={100} name="client_company" onChange={handleData} className={"w-full"}/>
                    </div>
                </div>
                <div className={"my-3"}>
                    <DataTable
                        value={services}
                        emptyMessage="No service found."
                    >
                        <Column header="Service Name" body={serviceNameTemplate}></Column>
                        <Column header="Amount" body={amountTemplate}></Column>
                        <Column header="Quantity" body={quantityTemplate}></Column>
                        <Column header="Actions" body={actionTemplate}></Column>
                    </DataTable>
                    <div className={"mt-3 flex justify-end"}>
                        <Button onClick={handlePlus} icon="pi pi-plus" aria-label="Filter" className="h-10 w-4"/>
                    </div>
                </div>
                <div>
                    <Button label={"Submit"} severity={"info"} onClick={handleCreateBill}/>
                </div>
            </div>
        </div>
    );
};

export default CreateCustomBill;