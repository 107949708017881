import { useEffect, useState } from 'react';
import useData from './useData';
import { baseUrl, checkAvailability } from '../Helper/helper';

const usePaymentTypes = () => {
    const { token } = useData().store;
    const { authPermissions } = useData().permissions;
    const [paymentTypes, setPaymentTypes] = useState();
    const isGetPaymentTypes = checkAvailability(
        authPermissions,
        "get-payment-type"
      );
    useEffect(() => {
        if (!isGetPaymentTypes) {
          return;
        }
        const paymentTypes = async () => {
          try {
            const res = await fetch(`${baseUrl.url}/api/payment_types`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            });
            const data = await res.json();
        
            if (res.ok) {
              setPaymentTypes(data?.data);
            }
          } catch (error) {
            console.error(error?.message);
          }
        };
        paymentTypes().then();
      }, [token, isGetPaymentTypes]);

    return [paymentTypes];
};

export default usePaymentTypes;