import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { baseUrl} from "../../Helper/helper";
import { Toast } from "primereact/toast";
import { Geolocation } from "@capacitor/geolocation";
import useData from "../../hooks/useData";

const AddFollowUpDateModal = ({
  visible,
  setVisible,
  id,
  followUp,
  update,
  setUpdate,
}) => {
  const [location,setLocation] = useState(null)
  const [date, setDate] = useState(null);
  const [description, setDescription] = useState(null);

  const {token} = useData().store
  const followDate = new Date(followUp?.occurred_on);
  const today = new Date();
  const timePart = today
    ?.toLocaleString("en-US", { hour12: false })
    .split(",")[1];



  const formattedDate = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2) + "" +
    timePart;
  const toast = useRef(null);
  const callToast = (type, detail) => {
    toast.current.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };
  const handleSubmit = async () => {
    //  if(!followUp?.id){
    //   if(!date){
    //     toast.current.show({severity:'error', summary: 'Error', detail:'Date is required', life: 3000});
    //     return
    //   }
    //  }
    const postData = {
      client_id: id,
      occurred_on: formattedDate,
      detail: description,
    };

    const updatedData = {
      occurred_on: date ? formattedDate : followUp?.occurred_on,
      detail: description ? description : followUp?.detail,
    };

    if (followUp?.id) {
      try {
        const res = await fetch(
          `${baseUrl.url}/api/follow-up/${followUp?.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(updatedData),
          }
        );
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "Follow Up Updated");
          setTimeout(() => {
            setUpdate(!update);
            setVisible(false);
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
      } catch (error) {
        callToast(false, error?.message)
      }
    } else {
      postData.latitude = `${location.latitude}`
      postData.longitude = `${location.longitude}`
      try{
        const res = await fetch(`${baseUrl.url}/api/follow-up`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(postData),
        });
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "Follow Up Data Added");
          setTimeout(() => {
            setVisible(false);
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
      }
      catch(error){
        callToast(error?.message)
      }
    }
  };
  useEffect(()=>{
    const printCurrentPosition = async () => {
      const coordinates = await Geolocation.getCurrentPosition({enableHighAccuracy:true});
      const location = coordinates.coords
      setLocation(location)
    };
    printCurrentPosition().then()
  },[])
  return (
    <div className="card flex justify-center">
      <Toast ref={toast} />
      <Dialog
        header="Add Follow Up Data"
        visible={visible}
        className="w-11/12 sm:w-10/12 lg:w-5/12 xl:w-1/3"
        onHide={() => {
          setVisible(false);
        }}
      >
        <div className="m-0">
          <div className="card">
            <label className="my-1 block">Date</label>
            <Calendar
              value={followUp?.id ? followDate : today}
              onChange={(e) => setDate(e.value)}
              showIcon
              showTime
              className="w-full"
              severity="info"
              disabled
            />
          </div>
          <div className="mt-3">
            <label className="my-1 block">Description</label>
            <InputTextarea
              defaultValue={followUp?.id && followUp?.detail}
              className="w-full"
              onChange={(e) => setDescription(e.target.value)}
              rows={3}
            />
          </div>
          <Button
            onClick={handleSubmit}
            size="small"
            label="Submit"
            severity="info" 
            className=""
          />
        </div>
      </Dialog>
    </div>
  );
};

export default AddFollowUpDateModal;
