import React from 'react';

const Label = ({title, required=false}) => {
    return (
        <label htmlFor="" className="text-sm dark:text-white">
            {title} {required && <span className={"text-red-500"}>*</span>}
        </label>
    );
};

export default Label;