import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';




const ChartTwo = ({ points, isDarkMode}) => {
    const pointsSumData = points?.map(item => item.points_sum);
const usernameData = points?.map(item => item.username);

const maxpointsSumData = Math.max(...pointsSumData);
// const maxConfirmedClientsValue = Math.max(...confirmedClientsData);
// const maxYAxisValue = Math.max(maxClientsValue, maxConfirmedClientsValue);

  

const options = {
    colors: ['#3C50E0', '#80CAEE'],
    theme: {
      mode: isDarkMode ? "dark" : "light",
    },
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'bar',
      height: 335,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
  
    responsive: [
      {
        breakpoint: 1536,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: '25%',
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '25%',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    dataLabels: {
      enabled: false,
    },
  
    xaxis: {
      categories: usernameData,
    },
    yaxis: {
      title: {
        style: {
          fontSize: '0px',
        },
      },
      min: 0,
      max: maxpointsSumData, 
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'Satoshi',
      fontWeight: 500,
      fontSize: '14px',
  
      markers: {
        radius: 99,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark",
      style: {
        background: "#FF5733",
        color: "#ffffff",
      },
    },
  };

    
    const [series] = useState([
        {
          name: 'Points',
          data: pointsSumData,
        }
      
      ]);

  return (
    <div className="  rounded border border-stroke bg-white px-5 pt-7.5 pb-10 shadow-default sm:px-7.5 dark:bg-gray-900 dark:border-none">
        <h4 className="flex items-center justify-center font-bold text-xl dark:text-white pt-2 text-gray-800 ">
           User Points Report
          </h4>

     
        <div id="chartTwo" className="rounded p-1.5 ">
          <ReactApexChart
           options={options}
           series={series}
           type="bar"
           height={350}
           className="dark:bg-gray-900 dark:text-white"
          />
        </div>
   
    </div>
  );
};

export default ChartTwo;
