import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const ChartThree = ({ kpis, isDarkMode }) => {
  const clientsCountData =
    kpis?.length > 0 && kpis?.map((item) => item?.user?.clients_count);
  const usernameData =
    kpis?.length > 0 && kpis?.map((item) => item?.user?.name);

  const options = {
    chart: {
      type: "donut",
    },
    theme: {
      mode: isDarkMode ? "dark" : "light",
    },
    colors: ["#10B981", "#375E83", "#259AE6", "#FFA70B"],
    labels: usernameData,
    legend: {
      show: true,
      position: "bottom",
    },
    tooltip: {
      theme: "dark",
      style: {
        background: "#FF5733",
        color: "#ffffff",
      },
    },

    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          background: "transparent",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 380,
          },
        },
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  // const options = {
  //   chart: {
  //     type: 'donut',
  //   },
  //   colors: ['#10B981', '#375E83', '#259AE6', '#FFA70B'],
  //   labels: usernameData,
  //   legend: {
  //     show: true,
  //     position: 'bottom',
  //   },
  //   plotOptions: {
  //     pie: {
  //       donut: {
  //         size: '65%',
  //         background: 'transparent',
  //       },
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   series: clientsCountData, // Use clientsCountData as the series data
  //   responsive: [
  //     {
  //       breakpoint: 2600,
  //       options: {
  //         chart: {
  //           width: 380,
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 640,
  //       options: {
  //         chart: {
  //           width: 200,
  //         },
  //       },
  //     },
  //   ],
  // };

  const [series] = useState(clientsCountData);

  return (
    <div className=" rounded border border-stroke bg-white px-5 pt-7.5 pb-24 shadow-default sm:px-7.5 dark:bg-gray-900 dark:text-white dark:border-none ">
      <h4 className="flex items-center justify-center font-bold text-xl dark:text-white pt-2 text-gray-800 ">
        Employee Kpi reports
      </h4>

      <div className="mb-2">
        <div id="chartThree" className=" flex justify-center rounded p-0">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            className="dark:bg-gray-900 dark:text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default ChartThree;
