import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import useData from '../../hooks/useData';
import { checkAvailability, baseUrl} from '../../Helper/helper';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const UpdatePointTypeModal = ({visible, setVisible, pointType, update, setUpdate}) => {
    const [point, setPoint] = useState(false)
    const {token} = useData().store
    const toast = useRef()
    const { authPermissions } = useData().permissions;
    const isCreatePaymentCategory = checkAvailability(
        authPermissions,
        "create-payment-category"
      );
      const callToast = (type, detail) => {
        toast.current?.show({
          severity: type ? "success" : "error",
          summary: type ? "Success" : "Error",
          detail: detail,
          life: 3000,
        });
      };
      const handleSubmit = async() => {
        if(!isCreatePaymentCategory){
            return
        }
        const postData = {
            point: point || pointType?.point
        }
     
        if(pointType?.id){
            try{
                const res = await fetch(`${baseUrl.url}/api/point-types/${pointType?.id}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify(postData),
                });
                const resData = await res.json();
                if (res.ok) {
                  callToast(true, "Point updated successfully");
                  setTimeout(() => {
                      setVisible(false)
                      setUpdate(!update)
                  }, 400)
                } else {
                  callToast(false, resData?.error);
                }
               }
               catch(error){
                callToast(false, error?.message)
               }
        }

    }

    return (
        <div className="card flex flex-wrap justify-content-center w-full">
        <Toast ref={toast}/>
        <Dialog
          header="Update Point"
          visible={visible}
          className="w-11/12 sm:w-10/12 lg:w-5/12 xl:w-1/4"
          onHide={() => {
            setVisible(false);
          
          }}
        >
             <p className=" text-base py-2 font-semibold">Type: {pointType?.type}</p>

         <div>
         <label className="text-gray-500 text-sm py-2 font-semibold">Point</label>
           <InputText
               defaultValue={pointType?.point}
                onChange={(e)=> setPoint(e.target.value)}
                type='number'
                className="w-full"
                placeholder='point'
              
              />
         </div>
             <div  className='mt-4'>
            <Button onClick={handleSubmit} type="submit" size="small" label="Submit" severity="info"/>
            </div>
        </Dialog>
      </div>
    );
};

export default UpdatePointTypeModal;