import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Clients from "../Pages/Clients/Clients";
import AddClient from "../Components/AddClient/AddClient";
import Login from "../Pages/Login/Login";
import Payment from "../Components/Payment/Payment";
import ViewFollowUpData from "../Components/ViewFollowUpData/ViewFollowUpData";
import ConfirmedClients from "../Components/ConfirmedClients/ConfirmedClients";
import RequireAuth from "../Layouts/RequireAuth";
import RoleList from "../Components/Role/RoleList";
import AddUser from "../Components/User/AddUser/AddUser";
import Users from "../Components/User/Users/Users";
import Profile from "../Components/Profile/Profile";
import NoContent from "../Components/NoContent/NoContent";
import MapC from "../Components/MapC/MapC";
import AddTAllowance from "../Components/TAllowance/AddTAllowance";
import TAllowance from "../Components/TAllowance/TAllowance";
import FoodAllowance from "../Components/FoodAllowance/FoodAllowance";
import AddFoodAllowance from "../Components/FoodAllowance/AddFoodAllowance";
import Services from "../Components/Payment/Services";
import PointTypes from "../Components/Point/PointTypes";
import Home from "../Pages/Home/Home";
import Employee from "../Components/Employee/Employee"
import Salary from "../Components/Salary/Salary";
import SearchedClients from "../Pages/SearchedClients/SearchedClients";
import FollowupReminder from "../Components/ViewFollowUpData/FollowupReminder";
import KpiLookUp from "../Components/KPI/KpiLookUp";
import ConfirmedClientDetails from "../Components/ConfirmedClients/ConfirmedClientDetails";
import Bills from "../Components/Payment/Bill/Bills";
import Todo from "../Components/Todo/Todo";
import CreateBill from "../Components/Payment/Bill/CreateBill";
import Transactions from "../Components/Payment/Transaction/Transactions";
import Invoices from "../Components/TAllowance/Invoices";
import CustomBill from "../Components/Payment/Bill/CustomBill";
import CreateCustomBill from "../Components/Payment/Bill/CreateCustomBill";


export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/search-clients",
        element: <SearchedClients />,
      },
      {
        path: "/clients",
        element: <Clients />,
      },
      {
        path: "/confirm-clients",
        element: <ConfirmedClients />,
      },
      {
        path: "/confirm-client/:id",
        element: <ConfirmedClientDetails/>,
      },
      {
        path: "/add-client",
        element: <AddClient />,
      },
      {
        path: "/employee",
        element: <Employee/>
      },
      {
        path: "/salary",
        element: <Salary/>
      },
      {
        path: "/client/:id",
        element: <AddClient />,
      },
      {
        path: "/payment",
        element: <Payment />,
      },
      {
        path: "/transaction",
        element: <Transactions />,
      },
      {
        path: "/bill",
        element: <Bills/>,
      },
      {
        path: "/create-bill",
        element: <CreateBill/>,
      },
      {
        path: "/custom/bill",
        element: <CustomBill/>,
      },
      {
        path: "/custom/billing",
        element: <CreateCustomBill/>,
      },
      {
        path: "/services",
        element: <Services/>,
      },
      {
        path: "/follow_up/:id",
        element: <ViewFollowUpData />,
      },
      {
       path: "/pending/followup",
       element: <FollowupReminder/>
      },
      {
        path: "/role-list",
        element: <RoleList/>
      },
      {
        path: "/add-user",
        element: <AddUser/>
      },
      {
        path: "/user/:id",
        element: <AddUser/>
      },
      {
        path: "/user",
        element: <Users/>
      },
      {
        path: "/todo",
        element: <Todo/>
      },
      {
        path: "/map",
        element: <MapC/>
      },
      {
        path: "/add-ta",
        element: <AddTAllowance  props={{end: null, update: null}}/>
      },
      {
        path: "/ta/:id",
        element: <AddTAllowance  props={{end: null, update: "update"}}/>
      },
      {
        path: "/ta/end",
        element: <AddTAllowance props={{end: "end", update: null}}/>
      },
      {
        path: "/ta",
        element: <TAllowance/>
      },
      {
        path: "/fa",
        element: <FoodAllowance/>
      },
      {
        path: "/add-fa",
        element: <AddFoodAllowance/>
      },
      {
        path: "/ta/invoice",
        element: <Invoices/>
      },
      {
        path: "/point-types",
        element: <PointTypes/>
      },
      {
        path: "/kpi",
        element: <KpiLookUp/>
      },

      {
        path: "/profile",
        element: <Profile/>
      }
      
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <NoContent/>
  }
]);
