import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Link} from "react-router-dom";
import {baseUrl, checkAvailability} from "../../Helper/helper";
import useData from "../../hooks/useData";
import NoContent from "../NoContent/NoContent";
import {InputTextarea} from "primereact/inputtextarea";
import SectionHeading from "../SectionHeading/SectionHeading";
import DataLoader from "../DataLoader/DataLoader";
import {AiOutlineFileExcel} from "react-icons/ai";
import {
    BsFiletypeCsv,
    BsFiletypePdf,
    BsThreeDotsVertical,
} from "react-icons/bs";
import {Menu} from "primereact/menu";
import {Checkbox} from "primereact/checkbox";
import {Calendar} from "primereact/calendar";
import moment from "moment";

const Salary = () => {
    const [months, setMonths] = useState([]);
    const [filteredMonths, setFilteredMonths] = useState([])
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    let rowClick = false;
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [adminNote, setAdminNote] = useState("");
    const {authPermissions} = useData().permissions;
    const isCreateSalary = checkAvailability(authPermissions, "store-salary");
    const [isGetActiveEmployee, setIsGetActiveEmployee] = useState(true)
    const toast = useRef(null);
    const menuLeft = useRef(null);
    const [year, setYear] = useState(new Date())
    const {token} = useData().store;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    // Toast Function
    const callToast = (type, detail) => {
        toast.current?.show({
            severity: type ? "success" : "error",
            summary: type ? "Success" : "Error",
            detail: detail,
            life: 3000,
        });
    };


    //GET CURRENT MONTH
    const getCurrentMonthName = () => {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return months[currentMonth];
    };
    const currentMonthName = getCurrentMonthName();

    const selectedYear = year ? moment(year).format("YYYY") : new Date().getFullYear();

    //GET MONTHS
    useEffect(() => {
        const getMonth = async () => {
            const res = await fetch(`${baseUrl.url}/api/months`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.ok) {
                const resData = await res.json();
                setMonths(resData.data)

            }
        };
        getMonth().then();
    }, [token]);

    // Filter Months by year
    useEffect(() => {
        if (months?.length > 0) {
            if (Number(selectedYear) === new Date().getFullYear()) {
                const filterData = months?.filter(
                    (month) => month?.id < currentMonth + 2
                );
                setFilteredMonths(filterData)
            } else {
                setFilteredMonths(months);
            }
            const findCurrentMonth = months?.find(
                (month) => month?.id === currentMonth + 1
            );

            setSelectedMonth(findCurrentMonth);
        }
    }, [selectedYear, months, currentMonth])


    //GET ALL EMPLOYEE
    useEffect(() => {
        const getEmployees = async () => {

            try {
                setLoading(true);
                const res = await fetch(
                    `${baseUrl.url}/api/employees?salary=1${isGetActiveEmployee ? "&is_active=1" : ""}&year_name=${
                       selectedYear
                    }&month_id=${
                        selectedMonth?.id ? selectedMonth?.id : new Date().getMonth() + 1
                    }`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (res.ok) {
                    if (res.status === 200) {
                        const resData = await res.json();
                        setEmployeeList(resData?.data);
                    } else {
                        setEmployeeList([]);
                    }
                    setSelectedEmployee([]);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        getEmployees().then();
    }, [token, update, authPermissions, year, selectedMonth?.id, isGetActiveEmployee, selectedYear]);

    // Employee name template
    const nameTemplate = (data) => {
        const {is_active, avatar, email} = data?.user

        return (
            <Link
                to={"#"}
                className=" cursor-pointer"
            >
                <div className="flex items-center">
                    <img
                        className="w-8 h-8 rounded-full"
                        src={`${baseUrl?.url}${avatar}`}
                        alt="user"
                    />
                    <div>
                        <div className="flex w-full">
                            <p className="ml-1">{data?.user.name}</p>
                            {is_active === 0 && <span
                                className="text-xs ml-1 -mt-1 w-12 h-4 bg-red-500 text-white px-1 rounded">inactive</span>}
                        </div>
                        <p className="text-xs">{email}</p>
                    </div>
                </div>
            </Link>
        );
    };

    //STATUS TEMPLATE
    const statusTemplate = (data) => {
        const {salary_data} = data
        return (
            <span>
        {salary_data?.length > 0 ? (
            <p className="text-green-500">Paid</p>
        ) : (
            <p className="text-red-500">Not paid yet</p>
        )}
      </span>
        );
    };

    // Kpi template
    const kpiTemplate = (data) => {
        const {kpi_payable} = data
        const isSelected = selectedEmployee?.find(
            (employee) => employee?.id === data?.id
        );
        return (
            <div className="flex items-center gap-1">
                {isSelected?.id && (
                    <input
                        onChange={(e) => handleKpiCheck(data?.id, e.target.checked)}
                        checked={isSelected?.check}
                        type="checkbox"
                    />
                )}
                <span>{kpi_payable}</span>
            </div>
        );
    };

    // Calculate total payable amount template
    const totalPayableTemplate = (data) => {
        const {salary, kpi_payable} = data
        const total = salary + kpi_payable;
        return <span>{total}</span>;
    };

    // Total paid template
    const totalPaidTemplate = (data) => {
        const {salary_data, id} = data
        const isSelected = selectedEmployee?.find(
            (employee) => employee?.id === data?.id
        );
        const paid_amount = salary_data[0]?.paid_amount;
        return (
            <>
                {isSelected ? (
                    <input
                        value={isSelected?.paid_amount}
                        onChange={(e) => handlePaidAmount(id, e.target.value)}
                        className="border w-28 px-2 py-1 rounded focus:border-purple-700 bg-transparent focus:outline-none"
                        placeholder="amount"
                    />
                ) : (
                    <span>{paid_amount || 0}</span>
                )}
            </>
        );
    };


    // Handle Paid Data Function
    const handlePaidAmount = (id, value) => {
        const newArray = selectedEmployee?.map((data) => {
            if (data?.id === id) {
                return {
                    ...data,
                    paid_amount: value,
                };
            }
            return data;
        });
        setSelectedEmployee(newArray);
    };

    // KPI Check Function
    const handleKpiCheck = (id, value) => {
        const newArray = selectedEmployee?.map((data) => {
            const {salary, paid_amount, kpi_payable} = data
            const total = salary + kpi_payable;
            if (data?.id === id) {
                if (value) {
                    return {
                        ...data,
                        check: value,
                        paid_amount: total,
                    };
                } else {
                    const paid = paid_amount - kpi_payable;
                    console.log("uncheck", paid);
                    return {
                        ...data,
                        check: false,
                        paid_amount: paid,
                    };
                }
            }
            return data;
        });
        setSelectedEmployee(newArray);
    };

    //SALARY CREATE
    const createSalary = async () => {
        if (!isCreateSalary) {
            return;
        }
        let newEmployee = [];
        for (let i = 0; i < selectedEmployee.length; i++) {
            const element = selectedEmployee[i];
            const obj = {
                id: element?.id,
                amount: Number(element?.paid_amount),
            };
            newEmployee.push(obj);
        }

        const postData = {
            employees: newEmployee,
            year_name: selectedYear,
            month_id: selectedMonth?.id || new Date().getMonth() + 1,
            remarks: adminNote,
        };

        try {
            const res = await fetch(`${baseUrl.url}/api/employees/salary`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${token}`,
                },
                body: JSON.stringify(postData),
            });
            const resData = await res.json();
            if (res.ok) {
                setUpdate(!update);
                // callToast(true, "Employee Salary Status updated");
                setSelectedEmployee([]);
            } else {
                callToast(false, resData?.error);
            }
        } catch (error) {
            console.error(error);
        }
    };


    const selectedMonthTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div className="dark:text-white">{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    // Export Items
    let items = [
        {
            label: "Export",
            items: [
                {
                    label: "PDF",
                    icon: <BsFiletypePdf/>,
                    command: () => handleExportData("pdf")
                },
                {
                    label: "XLSX",
                    icon: <AiOutlineFileExcel/>,
                    command: () => handleExportData("xlsx")
                },
                {
                    label: "CSV",
                    icon: <BsFiletypeCsv/>,
                    command: () => handleExportData("csv")
                },
            ],
        },
    ];

    const handleExportData = (type) => {
        const exportData = async () => {
            const res = await fetch(
                `${
                    baseUrl.url
                }/api/employees/salary/export?type=${type}&salary=1${isGetActiveEmployee ? "&is_active=1" : ""}&year_name=${
                    selectedYear ? selectedYear : new Date().getFullYear()
                }&month_id=${
                    selectedMonth?.id ? selectedMonth?.id : new Date().getMonth() + 1
                }`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await res.blob();
            if (res.ok) {
                callToast(true, `Salary data ${type} download successfully`);
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `${selectedMonth?.name}_Salary Data_${selectedYear}.${type}`
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                callToast(false, data?.error);
            }
        };
        exportData().then();
    };

    return (
        <div>
            <Toast ref={toast}/>

            <div className="flex items-center justify-between">
                <SectionHeading title={"Salary"} text={"Add Salary"}/>

                <div className="flex justify-end">
                    <div className="zero">
                        <Button
                            icon={<BsThreeDotsVertical className="dark:text-white"/>}
                            onClick={(event) => menuLeft.current.toggle(event)}
                            aria-controls="popup_menu_left"
                            aria-haspopup
                        />
                    </div>
                    <Menu model={items} popup ref={menuLeft} id="popup_menu_left"/>
                </div>
            </div>
            <div className="grid grid-cols-12 gap-2 items-center mt-3">
                <div className="col-span-12 lg:col-span-6">
                    <label className="text-gray-500 text-sm block font-semibold dark:text-white">
                        Year
                    </label>
                    <Calendar value={year} className={"w-full"} onChange={(e) => setYear(e.value)} view="year"
                              dateFormat="yy" maxDate={new Date()} />
                    {/*<InputText*/}
                    {/*    type="number"*/}
                    {/*    className="w-full border focus:outline-none focus:border-blue-400 p-2 dark:bg-transparent dark:text-white "*/}
                    {/*    placeholder="Year"*/}
                    {/*    value={selectedYear}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        // if(Number(selectedYear) < new Date().getFullYear()){*/}
                    {/*        //   setSelectedYear(e.target.value)*/}
                    {/*        // }*/}
                    {/*        setSelectedYear(e.target.value)*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
                <div className="col-span-12 lg:col-span-6">
                    <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                        Month
                    </label>

                        <Dropdown
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.value)}
                            options={filteredMonths}
                            optionLabel="name"
                            placeholder={currentMonthName}
                            valueTemplate={selectedMonthTemplate}
                            className="w-full h-full border focus:outline-none focus:border-blue-400 dark:bg-transparent dark:text-white"
                        />

                </div>
            </div>

            <div className="my-2 flex gap-2">
                <Checkbox checked={isGetActiveEmployee} onChange={e => setIsGetActiveEmployee(e.checked)}/>
                <label className="text-sm text-gray-700">Salary data for all active employees</label>

            </div>

            <div>
                {loading ? (
                    <DataLoader/>
                ) : (
                    <div>
                        {employeeList?.length > 0 ? (
                            <div className="mt-4">
                                <DataTable
                                    value={employeeList}
                                    selectionMode={rowClick ? null : "checkbox"}
                                    selection={selectedEmployee}
                                    onSelectionChange={(e) => {
                                        const result = e?.value?.map((data) => {
                                            const {salary, kpi_payable,check,paid_amount} = data
                                            const total = salary + kpi_payable;
                                            return {
                                                ...data,
                                                check: check === undefined ? true : check,
                                                paid_amount: paid_amount
                                                    ? paid_amount
                                                    : total,
                                            };
                                        });
                                        const modifyResult = result?.filter(data => data?.user?.is_active === 1)
                                        setSelectedEmployee(modifyResult);
                                    }}
                                    dataKey="id"
                                    size="small"
                                    sortMode="multiple"
                                    tableStyle={{minWidth: "50rem"}}
                                >
                                    <Column
                                        header="#"
                                        headerStyle={{width: "3rem"}}
                                        body={(data, options) => options?.rowIndex + 1}
                                        className="dark:bg-gray-800 dark:text-white"
                                    ></Column>
                                    <Column
                                        selectionMode="multiple"
                                        headerStyle={{width: "3rem"}}
                                        className="dark:bg-gray-800 dark:text-white"
                                    ></Column>

                                    <Column
                                        field="name"
                                        header="Name"
                                        body={nameTemplate}
                                        // sortable
                                        className="dark:bg-gray-800 dark:text-white"
                                    ></Column>
                                    <Column
                                        field="salary"
                                        header="Salary"
                                        sortable
                                        className="dark:bg-gray-800 dark:text-white"
                                    ></Column>
                                    <Column
                                        field="kpi_payable"
                                        header="KPI"
                                        // sortable
                                        className="dark:bg-gray-800 dark:text-white"
                                        body={kpiTemplate}
                                    ></Column>
                                    <Column
                                        header="Total Payable"
                                        // sortable
                                        className="dark:bg-gray-800 dark:text-white"
                                        body={totalPayableTemplate}
                                    ></Column>
                                    <Column
                                        header="Paid Amount"
                                        // sortable
                                        className="dark:bg-gray-800 dark:text-white"
                                        body={totalPaidTemplate}
                                    ></Column>
                                    <Column
                                        header="Status"
                                        body={statusTemplate}
                                        className="dark:bg-gray-800 dark:text-white"
                                    ></Column>
                                </DataTable>
                                {selectedEmployee?.length > 0 && (
                                    <div className="mt-2">
                                        <div className="my-2">
                                            <label
                                                className="text-gray-500 text-sm py-2 font-semibold block dark:text-white">
                                                Notes
                                            </label>
                                            <InputTextarea
                                                type="text"
                                                name="description"
                                                onChange={(e) => setAdminNote(e.target.value)}
                                                className="w-full lg:w-8/12 dark:bg-transparent dark:text-white"
                                                placeholder="type here"
                                                rows={3}
                                                cols={30}
                                            />
                                        </div>
                                        <Button
                                            label="Submit"
                                            size="small"
                                            severity="info"
                                            onClick={createSalary}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <NoContent data="employee"/>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Salary;
