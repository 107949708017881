import React, { useEffect, useRef, useState } from "react";
import { baseUrl, checkAvailability } from "../../Helper/helper";
import useData from "../../hooks/useData";
import NoContent from "../NoContent/NoContent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Menu } from "primereact/menu";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { FiEdit } from "react-icons/fi";
import SectionHeading from "../SectionHeading/SectionHeading";
import DataLoader from "../DataLoader/DataLoader";

const KpiLookUp = () => {
  const { token } = useData().store;
  const { authPermissions } = useData().permissions;
  const menuLeft = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [kpiData, setKpiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState({});
  const toast = useRef();
  const isGetKpiData = checkAvailability(authPermissions, "update-kpi-lookup");
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    if (!isGetKpiData) {
      return;
    }
    const getKpi = async () => {
      try {
        setLoading(true);
        const res = await fetch(`${baseUrl.url}/api/kpi-lookups`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.ok) {
          if (res.status === 200) {
            const resData = await res.json();
            setKpiData(resData?.data);
          } else {
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getKpi().then();
  }, [token, update, isGetKpiData]);
  let items = [
    {
      label: "Create Kpi",
      icon: <AiOutlinePlus />,
      command: () => {
        setShowModal(!showModal);
        setData({});
      },
    },
  ];
  const handleDelete = async (id) => {
    if (!isGetKpiData) {
      return;
    }
    try {
      const res = await fetch(`${baseUrl.url}/api/kpi-lookups/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        callToast(true, "Kpi deleted successfully");
      } else {
        callToast(true, resData?.error);
      }
    } catch (err) {
      callToast(false, err?.message);
    }
  };
  const optionTemplate = (data) => {
    const id = data?.id;
    return (
      <div className="flex justify-end items-center">
        <button
          onClick={() => {
            setShowModal(!showModal);
            setData(data);
          }}
          className="bg-sky-600 text-white font-semibold p-2"
        >
          <FiEdit />
        </button>

        <button
          onClick={() => {
            handleDelete(id);
          }}
          className="bg-sky-500 hover:bg-red-500 text-white font-semibold p-2"
        >
          <AiOutlineDelete />
        </button>
      </div>
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isGetKpiData) {
      return;
    }
    const per_client_amount = e.target?.per_client_amount.value;
    const client_count = e.target?.client_count.value;
    const amount = e.target?.amount.value;

    if (data?.id) {
      const updatedData = {
        per_client_amount: per_client_amount
          ? Number(per_client_amount)
          : data?.per_client_amount,
        client_count: client_count ? Number(client_count) : data?.client_count,
        amount: amount ? Number(amount) : data?.amount,
      };
      try {
        const res = await fetch(`${baseUrl.url}/api/kpi-lookups/${data?.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          body: JSON.stringify(updatedData),
        });
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "Kpi look up updated successfully");
          setUpdate(!update);
          setShowModal(false);
        } else {
          callToast(false, resData?.error);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      if (!per_client_amount) {
        callToast(false, "Per Client amount is required");
        return;
      } else if (!client_count) {
        callToast(false, "Client number is required");
        return;
      } else if (!amount) {
        callToast(false, "Amount is required");
      }
      const postData = {
        per_client_amount: per_client_amount,
        client_count: client_count,
        amount: amount,
      };
      try {
        const res = await fetch(`${baseUrl.url}/api/kpi-lookups`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          body: JSON.stringify(postData),
        });
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "Kpi look up created successfully");
          setUpdate(!update);
          setShowModal(false);
        } else {
          callToast(false, resData?.error);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      {showModal && (
        <div className="card flex flex-wrap justify-content-center w-full">
          <Dialog
            header={`${data?.id ? "Update KPI" : "Create KPI"}`}
            visible={showModal}
            className="w-11/12 sm:w-10/12 lg:w-8/12 2xl:w-1/3"
            onHide={() => {
              setShowModal(false);
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="my-2">
                <label className="text-gray-500 text-sm p-2 font-semibold">
                  Per Client Amount
                </label>
                <InputText
                  type="number"
                  name="per_client_amount"
                  defaultValue={data?.per_client_amount}
                  className="w-full"
                />
              </div>
              <div className="my-2">
                <label className="text-gray-500 text-sm p-2 font-semibold">
                  Client Number
                </label>
                <InputText
                  type="number"
                  name="client_count"
                  defaultValue={data?.client_count}
                  className="w-full"
                />
              </div>
              <div className="my-2">
                <label className="text-gray-500 text-sm p-2 font-semibold">
                  Amount
                </label>
                <InputText
                  type="number"
                  name="amount"
                  defaultValue={data?.amount}
                  className="w-full"
                />
              </div>

              <div className="mt-4">
                <Button
                  type="submit"
                  size="small"
                  label="Submit"
                  severity="info"
                />
              </div>
            </form>
          </Dialog>
        </div>
      )}
      <div className="grid grid-cols-12 justify-between">
        <div className="col-span-6">
          <SectionHeading
            title={"KPI"}
            text={"KPI look ups are detailed bellow"}
          />
        </div>
        <div className="col-span-6 flex justify-end">
          <div className="zero">
            <Button
              icon={<BsThreeDotsVertical className="dark:text-white" />}
              className="dark:bg-gray-800 "
              onClick={(event) => menuLeft.current.toggle(event)}
              aria-controls="popup_menu_left"
              aria-haspopup
            />
          </div>
          <Menu
            model={items}
            popup
            ref={menuLeft}
            id="popup_menu_left"
            className="dark:bg-gray-800 "
          />
        </div>
      </div>
      {loading ? (
        <DataLoader />
      ) : (
        <>
          {kpiData?.length > 0 ? (
            <div className="mt-4">
              <DataTable
                value={kpiData}
                dataKey="id"
                size="small"
                sortMode="multiple"
              >
                <Column
                  className="dark:bg-gray-800 dark:text-white"
                  header="#"
                  headerStyle={{ width: "3rem" }}
                  body={(data, options) => options?.rowIndex + 1}
                ></Column>
                <Column
                  field="per_client_amount"
                  header="Per Client Amount"
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="client_count"
                  header="Client Number"
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="amount"
                  header="Amount"
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  header="Actions"
                  className="flex justify-end dark:bg-gray-800 dark:text-white"
                  body={optionTemplate}
                ></Column>
              </DataTable>
            </div>
          ) : (
            <NoContent data={"kpi data"} />
          )}
        </>
      )}
    </div>
  );
};

export default KpiLookUp;
