import React, { useState, useEffect, useRef } from "react";
import {baseUrl, checkAvailability, paginationTemplate, tablePerPageOptions} from "../../Helper/helper";
import useData from "../../hooks/useData";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { BiMessageEdit } from "react-icons/bi";
import EmployeeStatusModal from "./EmployeeStatusModal";
import SectionHeading from "../SectionHeading/SectionHeading";

const Salary = () => {
  const [employees, setEmployees] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const { authPermissions } = useData().permissions;
  const isUpdateEmployee = checkAvailability(
    authPermissions,
    "update-employee"
  );
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const [update, setUpdate] = useState(false);
  const [employeeStatusModal, setEmployeeStatusModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const { token } = useData().store;
  useEffect(() => {
    const getEmployees = async () => {
      try {
        setLoading(true);
        const res = await fetch(`${baseUrl.url}/api/employees`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setEmployees(resData?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getEmployees().then();
  }, [token, update, authPermissions]);



  ///////////////////////////////////////--------------------START OF TABLE DATA SHOW TEMPLATE-----------------------------------------///////////////////////////////
  const nameTemplate = (data) => {
    let img = data?.user?.avatar;
    const email = data?.user?.email;
    return (
      <Link
        to={`#`}
        className=" cursor-pointer"
      >
        <div className="flex items-center gap-1">
          <Image
            className="w-8 h-auto rounded"
            src={`${baseUrl?.url}${img}`}
            alt="user"
            preview
          />

          <div>
            <p className="ml-1">{data?.user.name}</p>
            <span className="text-sm">{email}</span>
          </div>
        </div>
      </Link>
    );
  };

  const phoneTemplate = (data) => {
    const phone = data?.user?.phone;
    return <span>{phone}</span>;
  };
  const addressTemplate = (data) => {
    const address = data?.user?.address;
    return (
      <span>
        {address ? (
          <p>{address}</p>
        ) : (
          <p className="text-red-500">No Address</p>
        )}
      </span>
    );
  };
  const detailsTemplate = (data) => {
    const details = data?.user?.details;
    return (
      <span>
        {details ? (
          <p>{details}</p>
        ) : (
          <p className="text-red-500">No Details</p>
        )}
      </span>
    );
  };
  const salaryTemplate = (data) => {
    const {salary} = data
    return <span>{salary && <p>{salary} ৳</p>}</span>;
  };
  const statusTemplate = (data) => {
    const status = data?.user?.is_active;
    return (
      <span>
        {status === 1 ? (
          <p className="text-green-500">Active</p>
        ) : (
          <p className="text-red-500">Inactive</p>
        )}
      </span>
    );
  };
  const optionTemplate = (data) => {
    return (
      <div className="flex items-center">
        {isUpdateEmployee && (
          <button
            onClick={() => {
              setEmployeeStatusModal(!employeeStatusModal);
              setSelectedEmployee(data);
            }}
            className="bg-sky-500 hover:bg-green-500 text-white font-semibold p-2 logo"
          >
            <BiMessageEdit />
          </button>
        )}
      </div>
    );
  };
  const documentTemplate = (data) => {
    let img = data?.document;
    return (
      <div className=" cursor-pointer">
        {img && img ? (
          <div className="flex items-center">
            <Link to={`${baseUrl?.url}${img}`} target="_blank" download>
              <img
                className="w-8 h-8 rounded-full"
                src={`${baseUrl?.url}${img}`}
                alt="user"
              />
            </Link>
          </div>
        ) : (
          <p className="text-red-500">No Document Added</p>
        )}
      </div>
    );
  };
  ///////////////////////////////////////--------------------END OF TABLE DATA SHOW TEMPLATE-----------------------------------------///////////////////////////////
  const allowExpansion = (rowData) => {
    return rowData;
  };
  //ROW EXPAND TEMPLATE
  const rowExpansionTemplate = (data) => {
    let expandData = [];
    expandData.push(data);

    return (
      <div className="p-3">
        <DataTable value={expandData}>
          <Column
            header="Address"
            body={addressTemplate}
            className="dark:bg-gray-800 dark:text-white"
          ></Column>
          <Column
            header="Details"
            body={detailsTemplate}
            className="dark:bg-gray-800 dark:text-white"
          ></Column>
          <Column
            field="joining_date"
            header="Joining Date"
            className="dark:bg-gray-800 dark:text-white"
          ></Column>
          <Column
            header="Document"
            body={documentTemplate}
            className="dark:bg-gray-800 dark:text-white"
          ></Column>
        </DataTable>
      </div>
    );
  };


  return (
    <div>
      <Toast ref={toast} />

        <div>
          <div className="grid grid-cols-12 justify-between">
            <div className="col-span-6">
              <SectionHeading title="Employees" text={"Employee list here"} />
            </div>
          </div>

            <div className="mt-4">
              <DataTable
                value={employees}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="id"
                showGridlines={true}
                sortMode="multiple"
                emptyMessage={"No employee found"}
                tableStyle={{ minWidth: "50rem" }}
                loading={loading}
                paginator={employees?.length > 0}
                rows={10}
                paginatorTemplate={paginationTemplate}
                rowsPerPageOptions={tablePerPageOptions}
              >
                <Column
                  expander={allowExpansion}
                  style={{ width: "5rem" }}
                  className="dark:bg-gray-800 dark:text-white"
                />
                <Column
                  field="name"
                  header="Name"
                  body={nameTemplate}
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  header="Phone No"
                  body={phoneTemplate}
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  header="Salary"
                  body={salaryTemplate}
                  className="dark:bg-gray-800 dark:text-white"
                  sortable
                ></Column>
                <Column
                  field="general_kpi"
                  header="Kpi"
                  className="dark:bg-gray-800 dark:text-white"
                  sortable
                ></Column>
                <Column
                  header="Status"
                  body={statusTemplate}
                  className="dark:bg-gray-800 dark:text-white"
                  sortable
                ></Column>
                {isUpdateEmployee ? (
                  <Column
                    header="Options"
                    body={optionTemplate}
                    className="flex justify-end dark:bg-gray-800 dark:text-white"
                  ></Column>
                ) : null}
              </DataTable>
            </div>
        </div>

      {employeeStatusModal && (
        <EmployeeStatusModal
          visible={employeeStatusModal}
          setVisible={setEmployeeStatusModal}
          toast={toast}
          update={update}
          setUpdate={setUpdate}
          selectedEmployee={selectedEmployee}
        />
      )}
    </div>
  );
};

export default Salary;
