import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import CreateTaskModal from "../Modal/CreateTaskModal";
import useData from "../../hooks/useData";
import { baseUrl, checkAvailability, formatTimeAgo } from "../../Helper/helper";
import { Tooltip } from "primereact/tooltip";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi2";
import { RxCross2 } from "react-icons/rx";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import SectionHeading from "../SectionHeading/SectionHeading";
import DataLoader from "../DataLoader/DataLoader";

const Todo = () => {
  const menuLeft = useRef(null);
  const menuLeft1 = useRef(null);
  const menuLeft2 = useRef(null);
  const [allTodo, setAllTodo] = useState([]);
  const [update, setUpdate] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [todo, setTodo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const toast = useRef();
  const [activeTab, setActiveTab] = useState(1);
  const { authPermissions } = useData().permissions;
  const [datetime24h, setDateTime24h] = useState(null);
  const [data, setData] = useState({});
  const [documents, setDocuments] = useState([]);
  const [sectionId, setSectionId] = useState(null);
  const { token } = useData().store;
  const isGetUsers = checkAvailability(authPermissions, "get-user-list");
  const isGetToDos = checkAvailability(authPermissions, "get-todo-list");
  const isUpdateToDo = checkAvailability(authPermissions, "update-todo-list");
  const isDeleteToDo = checkAvailability(authPermissions, "delete-tasks");
  const isUpdateDocument = checkAvailability(
    authPermissions,
    "update-task-documents"
  );
  const isUpdateTodoStatus = checkAvailability(
    authPermissions,
    "update-todo-status"
  );

  const isAssignTask = checkAvailability(
    authPermissions,
    "assign-tasks-to-users"
  );
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };
  useEffect(() => {
    if (!isGetToDos) {
      return;
    }
    const todos = async () => {
      try {
        const res = await fetch(`${baseUrl?.url}/api/todo-get-all`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setAllTodo(data?.data);
        }
      } catch (err) {
        console.error(err?.message);
      }
    };
    todos().then();
  }, [token, isGetToDos, update]);

  useEffect(() => {
    if (!isGetUsers) {
      return;
    }
    const users = async () => {
      try {
        const res = await fetch(`${baseUrl?.url}/api/users?get_all`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          const newArray = data?.data?.map((data) => {
            return {
              value: data?.id,
              label: data?.name,
              avatar: data?.avatar,
            };
          });
          setUsers(newArray);
        }
      } catch (err) {
        console.error(err?.message);
      }
    };
    users().then();
  }, [token, isGetUsers]);
  let items = [
    {
      label: "Create Task",
      icon: <AiOutlinePlus />,
      command: () => {
        setShowModal(!showModal);
      },
    },
  ];
  let actionItems = [
    {
      label: "Delete Todo",
      // icon: <AiOutlinePlus />,
      permission: "delete-tasks",
      command: async () => {
        try {
          const res = await fetch(`${baseUrl?.url}/api/todo/${todo?.id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (res.ok) {
            setUpdate(!update);
            callToast(true, "Task deleted successfully");
          } else {
            const data = await res.json();
            callToast(false, data?.error);
          }
        } catch (err) {
          console.error(err?.message);
        }
      },
    },
    {
      label: "Move to complete",
      // icon: <AiOutlinePlus />,
      permission: "approve-or-cancel-tasks",
      status: "review",
      command: () => {
        handleAdminStatusChange(4).then();
      },
    },
    {
      label: "Move to Cancel",
      // icon: <AiOutlinePlus />,
      permission: "approve-or-cancel-tasks",
      status: "review",
      command: () => {
        handleAdminStatusChange(5).then();
      },
    },
  ];
  const filterItems = actionItems?.filter((item) =>
    authPermissions?.find((perm) => perm?.name === item?.permission)
  );
  const deleteItems = filterItems?.filter((item) => item.status !== "review");

  const handleAdminStatusChange = async (status) => {
    try {
      const res = await fetch(
        `${baseUrl?.url}/api/todo-change-admin-status/${todo?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ status: status }),
        }
      );
      const resData = await res.json();
      if (res.ok) {
        callToast(true, `Task ${status === 4 ? "Completed" : "Cancelled"}`);
        setUpdate(!update);
      } else {
        callToast(false, resData?.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const modifyDate = (data) => {
    return  new Date(data)?.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

  };
  const selectedUsersTemplate = (option) => {
    const data = users?.find((user) => user?.value === option);

    if (option) {
      return (
        <img
          src={`${baseUrl?.url}${data?.avatar}`}
          alt="user"
          className="w-5 h-5 rounded-full inline mx-1"
        />
      );
    }
  };
  const userOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={option.name}
          src={`${baseUrl?.url}${option?.avatar}`}
          className={`rounded-full w-6 h-6`}
          style={{ width: "18px" }}
        />
        <div>{option.label}</div>
      </div>
    );
  };

  const [selectedPriorityLevel, setSelectedPriorityLevel] = useState(null);
  const priorityLevels = [
    { name: "High Priority", code: "1" },
    { name: "Important", code: "2" },
    { name: "Neutral", code: "3" },
  ];

  const selectedPriorityLevelTemplate = (option, props) => {
    if (option) {
      return (
        <p
          className={`${
            +option?.code === 1
              ? "text-red-600"
              : +option?.code === 2
              ? "text-green-500"
              : "text-yellow-500"
          }`}
        >
          {option?.name}
        </p>
      );
    }
    return props?.placeholder;
  };
  const formateDate = (data) => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  const handleUpdate = async () => {
    if (!isUpdateToDo) {
      return;
    }

    const updatedData = {
      title: data?.title,
      detail: data?.detail,
      deadline: formateDate(datetime24h),
      priority_level: selectedPriorityLevel?.code,
    };

    try {
      const res = await fetch(`${baseUrl?.url}/api/update-todo/${todo?.id}`, {
        method: "PUT",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });
      const resData = await res.json();
      if (res.ok) {
        callToast(true, "ToDo updated successfully");
        setUpdate(!update);
      } else {
        callToast(false, resData?.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleUsers = async (e) => {
    const values = e.value;
    const obj = e.selectedOption;
    const isChecked = values.includes(obj?.value);
    if (isChecked) {
      try {
        const res = await fetch(`${baseUrl?.url}/api/assign-task/${todo?.id}`, {
          method: "POST",
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ user_id: obj?.value }),
        });
        const resData = await res.json();
        if (res.ok) {
          setUpdate(!update);
          setSelectedUsers(values);
          callToast(true, "User added successfully");
        } else {
          callToast(false, resData?.error);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const res = await fetch(
          `${baseUrl?.url}/api/task-user/${todo?.id}/${obj?.value}`,
          {
            method: "Delete",
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = await res.json();
        if (res.ok) {
          setUpdate(!update);
          setSelectedUsers(values);
          callToast(true, "User removed successfully");
        } else {
          callToast(false, resData?.error);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleStatusChange = async (id, status) => {
    try {
      const res = await fetch(`${baseUrl?.url}/api/todo-change-status/${id}`, {
        method: "PUT",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status: status }),
      });
      const resData = await res.json();
      if (res.ok) {
        callToast(true, "ToDo status updated successfully");
        setUpdate(!update);
      } else {
        callToast(false, resData?.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddDocument = async (e) => {
    if (!isUpdateDocument) {
      return;
    }
    const file = e?.target?.files[0];
    let formData = new FormData();
    formData.append("document", file);
    formData.append("todo_id", todo?.id);

    try {
      const res = await fetch(`${baseUrl?.url}/api/add-document`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const resData = await res.json();
      if (res.ok) {
        e.target.value = "";
        const document = resData?.data;
        const newDocuments = [...documents, document];
        setDocuments(newDocuments);
        callToast(true, "Document added successfully");
        setUpdate(!update);
      } else {
        callToast(false, resData?.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleRemoveDocument = async (id) => {
    try {
      const res = await fetch(`${baseUrl?.url}/api/remove-document/${id}`, {
        method: "Delete",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const resData = await res.json();
      if (res.ok) {
        setUpdate(!update);
        const newDocuments = documents?.filter(
          (document) => document?.id !== id
        );
        setDocuments(newDocuments);
        callToast(true, "Document removed successfully");
      } else {
        callToast(false, resData?.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (!allTodo.length) {
    return <DataLoader/>
  }

  return (
    <div className="relative">
      <Toast ref={toast} />
      <div className="grid grid-cols-12 justify-between">
        <div className="col-span-6">
          <SectionHeading
            title={"To-Do"}
            text={"All tasks are detailed below"}
          />
        </div>

        <div className="col-span-6 flex justify-end">
          <div className="zero">
            <Button
              icon={<BsThreeDotsVertical className="dark:text-white" />}
              className="dark:bg-gray-800 "
              onClick={(event) => menuLeft.current.toggle(event)}
              aria-controls="popup_menu_left"
              aria-haspopup
            />
          </div>
          <Menu
            model={items}
            popup
            ref={menuLeft}
            id="popup_menu_left"
            className="dark:bg-gray-800 "
          />
        </div>
      </div>

      <div className="flex h-full gap-4 overflow-auto min-w-full mt-3">
        {allTodo?.map((section, i) => (
          <div key={i} className="w-[390px] lg:w-[20%] p-4 bg-gray-50 dark:bg-gray-800 rounded-xl">
            <h1 className="text-lg font-semibold text-gray-700 whitespace-nowrap dark:text-white">
              {section?.name}
            </h1>
            <hr />
            <br />
            {section?.todos?.map((todo, j) => {
              const {
                title,
                detail,
                assignees,
                deadline,
                priority_level,
                documents,
              } = todo;
              const ids = assignees?.map((assignee) => {
                return assignee?.id;
              });
              const priority = priorityLevels?.find(
                (level) => +level?.code === priority_level
              );
              const expire_date = new Date(deadline);
              return (
                <div
                  key={j}
                  className="min-w-[300px] p-3 rounded my-2 hover:shadow bg-white lg:w-full shadow dark:bg-gray-900"
                >
                  <div className="relative">
                    <p className="text-xs text-purple-400 font-semibold -mt-2 text-left">
                      {formatTimeAgo(todo?.created_at)}
                    </p>
                    <span
                      className={`text-xs absolute -right-3 -top-1 bg-zinc-100  font-medium px-2 py-1  rounded-bl-lg ${
                        priority_level === 1
                          ? "text-red-500"
                          : priority_level === 2
                          ? "text-green-500"
                          : "text-gray-500"
                      }`}
                    >
                      {priority?.name}
                    </span>
                    <p
                      onClick={() => {
                        setTodo(todo);
                        setSelectedUsers(ids);
                        setData({
                          title: title,
                          detail: detail,
                        });
                        setSelectedPriorityLevel(priority);
                        setDateTime24h(expire_date);
                        setDocuments(documents);
                        setSectionId(section?.id);
                        setShowSidebar(true);
                      }}
                      className="cursor-pointer mt-1 dark:text-white"
                    >
                      {title}
                    </p>
                  </div>

                  <div className="grid grid-cols-8 gap-1 justify-start mt-5 my-3">
                    {assignees?.map((assignee, k) => (
                      <div key={k} className="relative">
                        <div className="card flex flex-wrap align-items-center justify-content-center gap-5 ">
                          <Tooltip
                            target=".logo"
                            mouseTrack
                            position="top"
                            className="text-sm"
                          />
                          <img
                            className="logo w-6 h-6 rounded-full"
                            alt="logo"
                            src={`${baseUrl?.url}${assignee?.avatar}`}
                            data-pr-tooltip={assignee?.name}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  {(+section?.id === 1 ||
                    +section?.id === 2 ||
                    section?.id === 3) && (
                    <div className="flex justify-between -mb-3">
                      <div>
                        {section?.id === 3 && filterItems?.length > 0 ? (
                          <div className=" flex justify-start">
                            <div className="zero">
                              <Button
                                icon={
                                  <BsThreeDotsVertical
                                    size="15"
                                    className="dark:text-white"
                                  />
                                }
                                className="dark:bg-gray-800 "
                                onClick={(event) => {
                                  setTodo(todo);
                                  menuLeft2.current.toggle(event);
                                }}
                                aria-controls="popup_menu_left"
                                aria-haspopup
                              />
                            </div>
                            <Menu
                              model={filterItems}
                              popup
                              ref={menuLeft2}
                              id="popup_menu_left"
                              className="dark:bg-gray-800 "
                            />
                          </div>
                        ) : (
                          <>
                            {isDeleteToDo && deleteItems?.length > 0 && (
                              <div className=" flex justify-start">
                                <div className="zero">
                                  <Button
                                    icon={
                                      <BsThreeDotsVertical
                                        size="15"
                                        className="dark:text-white"
                                      />
                                    }
                                    className="dark:bg-gray-800 "
                                    onClick={(event) => {
                                      menuLeft1.current.toggle(event);
                                      setTodo(todo);
                                    }}
                                    aria-controls="popup_menu_left"
                                    aria-haspopup
                                  />
                                </div>
                                <Menu
                                  model={deleteItems}
                                  popup
                                  ref={menuLeft1}
                                  id="popup_menu_left"
                                  className="dark:bg-gray-800 "
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      {isUpdateTodoStatus && (
                        <div className="flex justify-end gap-x-8">
                          {(section?.id === 2 || +section?.id === 3) && (
                            <div>
                              <Tooltip
                                target=".left"
                                mouseTrack
                                position="top"
                                className="text-sm"
                              />
                              <button
                                className="left hover:text-sky-600 hover:font-semibold"
                                data-pr-tooltip={`Move to ${
                                  section?.id === 2 ? "Todo" : "Progress"
                                }`}
                                onClick={() => {
                                  handleStatusChange(todo?.id, "left").then();
                                }}
                              >
                                <HiArrowLeft
                                  size={20}
                                  className="dark:text-white"
                                />
                              </button>
                            </div>
                          )}
                          {(+section?.id === 1 || +section?.id === 2) && (
                            <div>
                              <Tooltip
                                target=".right"
                                mouseTrack
                                position="top"
                                className="text-sm"
                              />
                              <button
                                className="right hover:text-sky-600 hover:font-semibold"
                                data-pr-tooltip={`Move to ${
                                  section?.id === 1 ? "Progress" : "Review"
                                }`}
                                onClick={() => {
                                  handleStatusChange(todo?.id, "right").then();
                                }}
                              >
                                <HiArrowRight
                                  size={20}
                                  className="dark:text-white"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div
        className={`transition-all duration-700 overflow-y-auto fixed h-screen top-14 z-40  bg-white w-full sm:w-96 border-l p-2 ${
          showSidebar ? "right-0 top-0" : "-right-full sm:-right-96"
        }`}
      >
        <div className="flex justify-end">
          <button
            className="text-gray-800 hover:text-red-600"
            onClick={() => {
              setShowSidebar(false);
              setTodo({});
              setData({});
              setActiveTab(1);
              setSectionId(null);
            }}
          >
            <RxCross2 size="21" />
          </button>
        </div>
        <div className="overflow-auto pb-16 lg:pb-10">
          <p className="text-slate-800 font-bold text-xl">{todo?.title}</p>
          <p className="text-xs font-medium">
            Created At: {modifyDate(todo?.created_at)}
          </p>

          <div className=" mt-4">
            <button
              onClick={() => setActiveTab(1)}
              className={`border-b-2 text-left px-4 font-mono w-1/2 ${
                activeTab === 1 ? "border-sky-600" : "border-gray-200"
              }`}
            >
              Details
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={`border-b-2  text-left pl-4 font-mono w-1/2 ${
                activeTab === 2 ? "border-sky-600" : "border-gray-300"
              }`}
            >
              Attachments
            </button>
          </div>

          {activeTab === 1 && (
            <div className="mt-4">
              {isUpdateToDo && (
                <div className="card my-2">
                  <label className="my-1 block">Users</label>
                  <MultiSelect
                    value={selectedUsers}
                    onChange={(e) => {
                      handleUsers(e).then();
                    }}
                    options={users}
                    selectedItemTemplate={selectedUsersTemplate}
                    itemTemplate={userOptionTemplate}
                    optionLabel="name"
                    filter
                    placeholder="Select users"
                    className="w-full md:w-20rem"
                  />
                </div>
              )}
              <div>
                <label className="block">Title</label>
                <InputText
                  name="title"
                  className="border border-gray-300 rounded p-2 w-full focus:outline-none"
                  onChange={(e) =>
                    setData({ ...data, [e.target.name]: e.target.value })
                  }
                  value={data?.title}
                  placeholder="title"
                  readOnly={!isUpdateToDo && true}
                />
              </div>

              <div className="mt-3">
                <label className="block">Expired Date</label>
                <Calendar
                  id="calendar-24h"
                  value={datetime24h}
                  className="w-full"
                  onChange={(e) => setDateTime24h(e.value)}
                  showTime
                  hourFormat="12"
                  placeholder="Date"
                  readOnly={!isUpdateToDo && true}
                />
              </div>
              <div className=" mt-3">
                <label className="block">Priority Level</label>
                <Dropdown
                  value={selectedPriorityLevel}
                  onChange={(e) => setSelectedPriorityLevel(e.value)}
                  options={priorityLevels}
                  valueTemplate={selectedPriorityLevelTemplate}
                  optionLabel="name"
                  placeholder="Select priority level"
                  className="w-full md:w-14rem"
                  disabled={!isUpdateToDo && true}
                />
              </div>
              <div className="mt-3">
                <label className="my-1 block">Detail</label>
                <InputTextarea
                  name="detail"
                  defaultValue={data?.detail}
                  onChange={(e) =>
                    setData({ ...data, [e.target.name]: e.target.value })
                  }
                  className="border border-gray-300 rounded p-2 w-full focus:outline-none text-gray-800"
                  rows={3}
                  placeholder="type here"
                  readOnly={!isUpdateToDo && true}
                />
              </div>
              {isUpdateToDo && (
                <div className="my-3">
                  <Button
                    onClick={handleUpdate}
                    type="submit"
                    size="small"
                    label={"Update"}
                    severity="info"
                  />
                </div>
              )}
            </div>
          )}
          {activeTab === 2 && (
            <div className="mt-4">
              {isUpdateDocument && sectionId !== 4 && sectionId !== 5 && (
                <>
                  <label className="block text-sm my-1 font-medium">
                    Upload Document
                  </label>
                  <input
                    id="document"
                    type="file"
                    onChange={(e) => handleAddDocument(e)}
                    className="border rounded p-1 hover:border-purple-800 w-full text-sm"
                  />
                </>
              )}
              <div className="mt-4">
                {documents?.length > 0 &&
                  documents?.map((document, i) => {
                    const name = document?.document?.slice(14);
                    return (
                      <div className="my-2 flex justify-between" key={i}>
                        <a
                          href={`${baseUrl?.url}${document?.document}`}
                          target="blank"
                          className="text-gray-600 hover:text-blue-600 text-sm font-medium"
                        >
                          {name}
                        </a>
                        {isUpdateDocument &&
                          sectionId !== 4 &&
                          sectionId !== 5 && (
                            <button
                              className="hover:text-red-600"
                              onClick={() => handleRemoveDocument(document?.id)}
                            >
                              <AiOutlineDelete size="16" />
                            </button>
                          )}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <CreateTaskModal
          visible={showModal}
          setVisible={setShowModal}
          token={token}
          users={users}
          selectedUsersTemplate={selectedUsersTemplate}
          userOptionTemplate={userOptionTemplate}
          priorityLevels={priorityLevels}
          selectedPriorityLevelTemplate={selectedPriorityLevelTemplate}
          formateDate={formateDate}
          update={update}
          setUpdate={setUpdate}
          isAssignTask={isAssignTask}
          callToast={callToast}
          toast={toast}
        />
      )}
    </div>
  );
};

export default Todo;
