import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import useData from "../../../hooks/useData";
import {
  baseUrl,
  checkAvailability,
  modifyDateTime,
} from "../../../Helper/helper";
import { BsFiletypeCsv, BsThreeDotsVertical } from "react-icons/bs";
import { Menu } from "primereact/menu";
import { AiOutlineFileExcel, AiOutlinePlus } from "react-icons/ai";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NoContent from "../../NoContent/NoContent";
import CreateTransactionModal from "../../Modal/CreateTransactionModal";
import { Toast } from "primereact/toast";
import Pagination from "../../Pagination/Pagination";
import SectionHeading from "../../SectionHeading/SectionHeading";
import DataLoader from "../../DataLoader/DataLoader";

const Transactions = () => {
  const [showModal, setShowModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const menuLeft = useRef(null);
  const { token } = useData().store;
  const { authPermissions } = useData().permissions;
  const isCreateTransaction = checkAvailability(
    authPermissions,
    "create-client-transactions"
  );
  const isGetTransactions = checkAvailability(
    authPermissions,
    "get-client-transactions"
  );
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [url, setUrl] = useState(null);
  const toast = useRef();

  const [searchData, setSearchData] = useState({
    start_date: "",
    end_date: "",
    name: "",
    trx_id: "",
    invoice_no: "",
  });
  const [searchedData, setSearchedData] = useState({
    start_date: "",
    end_date: "",
    name: "",
    trx_id: "",
    invoice_no: "",
  });

  const handleSearchData = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  let allItems = [
    {
      label: "Create Transaction",
      permission: "create-client-transactions",
      icon: <AiOutlinePlus />,
      command: () => {
        setShowModal(!showModal);
      },
    },

    {
      label: "Export",
      permission: "get-client-transactions",
      items: [
        {
          label: "XLSX",
          icon: <AiOutlineFileExcel />,
          command: () => handleExportData("xlsx"),
        },
        {
          label: "CSV",
          icon: <BsFiletypeCsv />,
          command: () => handleExportData("csv"),
        },
      ],
    },
  ];
  const items = allItems?.filter((item) =>
    authPermissions?.find((perm) => perm?.name === item?.permission)
  );

  const paymentTypeTemplate = (data) => {
    const type = data?.payment_type;
    return (
      <div>
        <p>{type?.name}</p>
        {type?.id !== 1 && <p>Transaction No:{data?.transaction_id}</p>}
      </div>
    );
  };
  const timeTemplate = (data) => {
    const date = modifyDateTime(data?.occurred_on);

    return <span>{date}</span>;
  };

  const actionTemplate = (data) => {
    return (
        <div>
          <Button icon="pi pi-file-pdf" size={"small"}    onClick={() => handleDownload(data?.id)} tooltip="Download payslip" tooltipOptions={{ position: 'top' }}/>
        </div>
    );
  };
  useEffect(() => {
    if (!isGetTransactions) {
      return;
    }

    const getTransactions = async () => {
      const { name, start_date, end_date, trx_id, invoice_no } = searchedData;
      setLoading(true);

      try {
        const res = await fetch(
          url
            ? url
            : `${baseUrl.url}/api/client-transactions${
                perPageLimit && `?per_page=${perPageLimit}`
              }${name && `&client=${name}`}${trx_id && `&trx_id=${trx_id}`}${
                invoice_no && `&invoice_no=${invoice_no}`
              }${start_date && `&start_date=${start_date}`}${
                start_date && end_date && `&end_date=${end_date}`
              }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.ok) {
          if (res.status === 200) {
            const data = await res.json();
            setTransactions(data?.data);
          } else {
            setTransactions([]);
          }
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    };
    getTransactions().then();
  }, [token, update, isGetTransactions, url, perPageLimit, searchedData]);
  const random_number = new Date().getTime();

  const handleExportData = async (type) => {
    try {
      const res = await fetch(
        `${baseUrl.url}/api/client-transactions/export?type=${type}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.blob();
      if (res.ok) {
        callToast(true, "Transaction download successfully");
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `client_transaction_${random_number}.${type}`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        callToast(false, data?.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = async (id) => {
    try {
      const res = await fetch(
        `${baseUrl.url}/api/client-transactions/export/pdf`,
        {
          method: "PuT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ids: [id] }),
        }
      );
      const data = await res.blob();
      if (res.ok) {
        callToast(true, "Transaction download successfully");
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `client_transaction_${random_number}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        callToast(false, data?.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchedData(searchData);
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="grid grid-cols-12 justify-between">
        <div className="col-span-6">
          <SectionHeading
            title={"Transactions"}
            text={"All client transactions are detailed below"}
          />
        </div>
        {isCreateTransaction && items?.length > 0 && (
          <div className="col-span-6 flex justify-end">
            <div className="zero">
              <Button
                icon={<BsThreeDotsVertical className="dark:text-white" />}
                className="dark:bg-gray-800 "
                onClick={(event) => menuLeft.current.toggle(event)}
                aria-controls="popup_menu_left"
                aria-haspopup
              />
            </div>
            <Menu
              model={items}
              popup
              ref={menuLeft}
              id="popup_menu_left"
              className="dark:bg-gray-800 "
            />
          </div>
        )}
      </div>
      <div className="w-full grid grid-cols-12 my-3">
        <form onSubmit={handleSearch} className="col-span-12 2xl:col-span-11">
          <div className="w-full lg:flex lg:flex-wrap items-center gap-2">
            <div className=" my-2 xl:my-0">
              <input
                type="text"
                name="name"
                onChange={(e) => handleSearchData(e)}
                className="bg-white w-full text-sm border p-1 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white"
                placeholder="search by client info"
              />
            </div>
            <div className=" my-2 xl:my-0">
              <input
                type="text"
                name="trx_id"
                onChange={(e) => handleSearchData(e)}
                className="bg-white w-full text-sm border p-1 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white"
                placeholder="Transaction number"
              />
            </div>
            <div className=" my-2 xl:my-0">
              <input
                type="text"
                name="invoice_no"
                onChange={(e) => handleSearchData(e)}
                className="bg-white w-full text-sm border p-1 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white"
                placeholder="Invoice number"
              />
            </div>
            <div className=" flex lg:flex-none my-2 xl:my-0">
              <input
                type="date"
                name="start_date"
                onChange={(e) => handleSearchData(e)}
                className="bg-white w-full text-sm border p-1 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white date-input"
              />
              <span className="mx-2 dark:text-white">to</span>
              <input
                type="date"
                name="end_date"
                onChange={(e) => handleSearchData(e)}
                className="bg-white w-full  text-sm border p-1 focus:outline-none  dark:bg-gray-800 dark:text-white dark:placeholder-white date-input"
                disabled={!searchData?.start_date && true}
              />
            </div>
            <div>
              <button
                type="submit"
                className=" cursor-pointer w-full text-white bg-sky-500 px-2 py-1 text-sm"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>

      {loading ? (
        <DataLoader />
      ) : (
        <>
          {transactions?.data?.length > 0 ? (
            <>
              <DataTable
                value={transactions?.data}
                size="small"
                sortMode="multiple"
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  header="#"
                  headerStyle={{ width: "3rem" }}
                  body={(data, options) => options.rowIndex + 1}
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="invoice_no"
                  header="Invoice"
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  header="Date"
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                  body={timeTemplate}
                ></Column>
                <Column
                  field="client.name"
                  header="Client"
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  header="Payment Type"
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                  body={paymentTypeTemplate}
                ></Column>
                <Column
                  field="amount"
                  header="Amount(tk)"
                  sortable
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  header="Action"
                  className=" dark:bg-gray-800 dark:text-white h-full"
                  body={actionTemplate}
                ></Column>
              </DataTable>
              <Pagination
                data={transactions}
                setUrl={setUrl}
                perPageLimit={perPageLimit}
                setPerPageLimit={setPerPageLimit}
              />
            </>
          ) : (
            <NoContent data="transaction" />
          )}
        </>
      )}

      {showModal && (
        <CreateTransactionModal
          visible={showModal}
          setVisible={setShowModal}
          update={update}
          setUpdate={setUpdate}
          callToast={callToast}
          toast={toast}
        />
      )}
    </div>
  );
};

export default Transactions;
