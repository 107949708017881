import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import AddPaymentCategoryModal from "../Modal/AddPaymentCategoryModal";
import { Toast } from "primereact/toast";
import useData from "../../hooks/useData";
import {checkAvailability, baseUrl, paginationTemplate, tablePerPageOptions} from "../../Helper/helper";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FiEdit } from "react-icons/fi";
import SectionHeading from "../SectionHeading/SectionHeading";

const Services = () => {
  const menuLeft = useRef(null);
  const toast = useRef();
  const { token } = useData().store;
  const parse = require("html-react-parser");
  const [showModal, setShowModal] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const { authPermissions } = useData().permissions;
  const isGetService = checkAvailability(authPermissions, "get-service");
  const isCreateService = checkAvailability(authPermissions, "create-service");
  const isUpdateService = checkAvailability(authPermissions, "update-service");

  const allowExpansion = (rowData) => {
    return rowData;
  };

  const rowExpansionTemplate = (data) => {
    let expandData = [];
    expandData.push(data);

    return (
      <div className="p-3">
        <DataTable value={expandData}>
          <Column
            header="Description"
            body={descriptionTemplate}
            className="dark:bg-gray-800 dark:text-white"
          ></Column>
        </DataTable>
      </div>
    );
  };

  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };
  useEffect(() => {
    if (!isGetService) {
      return;
    }
    const getServices = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/api/services`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setCategories(data?.data);
        }
      } catch (error) {
        console.error(error?.message);
      } finally {
        setLoading(false);
      }
    };
    getServices().then();
  }, [token, isGetService, update]);

  let items = [
    {
      label: "Add Service",
      icon: <AiOutlinePlus className="dark:text-white" />,

      command: () => {
        setShowModal(!showModal);
        setSelectedCategory(null);
      },
    },
  ];

  const optionTemplate = (data) => {
    return (
      <div>
        {
          <div className="flex justify-end items-center">
            {isUpdateService && (
              <button
                onClick={() => {
                  setSelectedCategory(data);
                  setShowModal(!showModal);
                }}
                className="bg-sky-500 hover:bg-sky-600 text-white font-semibold p-2"
              >
                <FiEdit />
              </button>
            )}
          </div>
        }
      </div>
    );
  };
  const descriptionTemplate = (data) => {
    return <span>{parse(`${data?.description}`)}</span>;
  };
  const statusTemplate = (data) => {
    const status = data?.status;
    const id = data?.id;
    return (
         <div
         onClick={() =>
          handleStatusChange(id, status)
         }
         className={`h-5 rounded-full flex items-center w-9 cursor-pointer transition-all duration-300 ${
          status === 1
             ? "bg-sky-500"
             : "bg-gray-400 dark:bg-gray-500 justify-end"
         }`}
       >
         <div
           className={`h-4 w-4 rounded-full flex items-center justify-center mx-[1px] ${
           status === 1 ? "bg-white" : " bg-white"
           }`}
         ></div>
       </div>
    );
  };
  const handleStatusChange = async (id, status) => {
    if (!isUpdateService) {
      return;
    }
    try {
      const res = await fetch(`${baseUrl?.url}/api/services/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      });
      if (res.ok) {
        callToast(
          true,
          `successfully ${status === 1 ? "deactivated" : "activated"} service`
        );
        setUpdate(!update);
      } else {
        const data = await res.json();
        if (data?.error) {
          callToast(false, data?.error);
        }
      }
    } catch (error) {
      callToast(false);
    }
  };

  return (
    <div>
      <Toast ref={toast} />

          <div className="flex items-center justify-between">
            <SectionHeading
              title="Services"
              text={"Services are detailed bellow"}
            />
            {isCreateService && (
              <div className="flex justify-end">
                <div className="zero ">
                  <Button
                    icon={<BsThreeDotsVertical className="dark:text-white" />}
                    onClick={(event) => menuLeft.current.toggle(event)}
                    aria-controls="popup_menu_left"
                    aria-haspopup
                  />
                </div>
                <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
              </div>
            )}
          </div>

            <div className="mt-4">
              <DataTable
                value={categories}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="id"
                sortMode="multiple"
                showGridlines={true}
                className="datatable-responsive myTable"
                emptyMessage="No service found."
                loading={loading}
                paginator={categories?.length > 0}
                rows={10}
                paginatorTemplate={paginationTemplate}
                rowsPerPageOptions={tablePerPageOptions}
              >
                <Column
                  expander={allowExpansion}
                  style={{ width: "5rem" }}
                  className="dark:bg-gray-800 dark:text-white"
                />
                <Column
                  field="name"
                  header="Name"
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  field="price"
                  header="Price"
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column
                  header="Status"
                  className="dark:bg-gray-800 dark:text-white"
                  body={statusTemplate}
                ></Column>

                {isUpdateService && (
                  <Column
                    header="Options"
                    body={optionTemplate}
                    className="flex justify-end dark:bg-gray-800 dark:text-white"
                  ></Column>
                )}
              </DataTable>
            </div>



      {showModal && (
        <AddPaymentCategoryModal
          visible={showModal}
          setVisible={setShowModal}
          toast={toast}
          update={update}
          setUpdate={setUpdate}
          selectedCategory={selectedCategory}
        />
      )}
    </div>
  );
};

export default Services;
