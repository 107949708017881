import React, { useEffect, useRef, useState } from 'react';
import { baseUrl, checkAvailability} from '../../Helper/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import useData from '../../hooks/useData';
import { Dropdown } from "primereact/dropdown";
import { Geolocation } from "@capacitor/geolocation";
import { Toast } from 'primereact/toast';

const AddFoodAllowance = () => {
  const { token } = useData().store;
  const [selectedClient, setSelectedClient] = useState(false);
  const { authPermissions } = useData().permissions;
  const [clients, setClients] = useState(false);
  const [selectedFollowUp, setSelectedFollowUp] = useState(false);
  const [followUpData, setFollowUpData] = useState([])
  const [location,setLocation] = useState(null)
  const navigate = useNavigate()
    const [data, setData] = useState({
        amount: "",
        document: "",
        note: "",
     })
      const handleChangeData = (e, value) => {
     setData({...data, [e.target.name]: value})
     
      }

      const isCreateDeleteAllowance =  checkAvailability(authPermissions, "create-delete-food-allowance")
      const isGetClientFollowUp =  checkAvailability(authPermissions, "get-client-follow-up")
  
      useEffect(()=>{
        const getCurrentPosition =async()=> {
          try {
            const position = await Geolocation.getCurrentPosition();
            setLocation({latitude:position.coords.latitude,longitude:position.coords.longitude});
            
          } catch (error) {
            console.error('Error getting current position', error);
          }
        }
        getCurrentPosition().then()
      },[])
       
      let url = null
      let perPageLimit = 12
      const loaction = useLocation();
      const params = loaction?.search?.split("=")[1];
  
      useEffect(() => {
        if(!isGetClientFollowUp){
          return
        }
        const getFollowUpData = async () => {
          try {
            const res = await fetch(`${baseUrl.url}/api/clients-follow-up/${selectedClient?.id}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            });
            const data = await res.json();
            if (res.ok) {
               setFollowUpData(data?.data)
            }
          } catch (error) {
            console.error(error?.message);
          }
        };
        getFollowUpData().then();
      }, [token, selectedClient?.id, isGetClientFollowUp]);
    
    
      // FETCHING ALL CLIENTS
      useEffect(() => {
        const IsGetClient = checkAvailability(authPermissions, "get-client-list");
        if (!IsGetClient) {
          return;
        }
        const clients = async () => {
          try {
            const res = await fetch(
              `${
                url
                  ? url + `&per_page=${perPageLimit}`
                  : `${baseUrl.url}/api/clients?${
                      params && `confirmed=0&search=${params}`
                    }&confirmed=0&${perPageLimit && `per_page=${perPageLimit}`}`
              }`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            const data = await res.json();
            if (res.ok) {
              setClients(data?.data);
            }
          } catch (err) {
            console.error(err?.message);
          } finally {
          }
        };
        clients().then();
      }, [
        token,
        url,
        perPageLimit,
        authPermissions,
        loaction,
        params,
      ]);

      const selectedFollowUpTemplate = (option, props) => {

        if (option) {
          return (
            <div className="flex align-items-center">
             {option?.occurred_on}
            </div>
          );
        }
    
        return <span>{props.placeholder}</span>;
      };

      const followUpTemplate = (option) => {

        return (
          <div className="flex align-items-center">
            <div>{option?.occurred_on}</div>
          </div>
        );
      };
     
      // TEMPLATE FUNCTION FOR CLIENT SEARCH DROPDOWN
      const selectedClientTemplate = (option, props) => {
        if (option) {
          return (
            <div className="flex align-items-center">
              <img
                alt={option.name}
                src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                className={`mr-2 flag flag-${option?.code?.toLowerCase()}`}
                style={{ width: "18px" }}
              />
              <div>{option.name}</div>
            </div>
          );
        }
    
        return <span>{props.placeholder}</span>;
      };
      // TEMPLATE FUNCTION FOR CLIENT SEARCH DROPDOWN
      const clientOptionTemplate = (option) => {
    
        return (
          <div className="flex align-items-center">
            <img
              alt={option.name}
              src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
              className={`mr-2 flag flag-${option?.code?.toLowerCase()}`}
              style={{ width: "18px" }}
            />
            <div>{option.name}</div>
          </div>
        );
      };
      

      const toast = useRef(null);
      const callToast = (type, detail) => {
        toast.current?.show({
          severity: type ? "success" : "error",
          summary: type ? "Success" : "Error",
          detail: detail,
          life: 3000,
        });
      };
    
    const handleSubmit= async(e) => {
      e.preventDefault()
      if(!isCreateDeleteAllowance){
        return
      }
      const formData = new FormData()
      const postData = {
        lat: location?.latitude,
        lng: location?.longitude,
        amount: data?.amount,
        document: data?.document,
        client_id: selectedClient?.id || null,
        follow_up_id: selectedFollowUp?.id || null,
        note: data?.note || null
      }


  try {
    for(let i in postData){
     if(postData[i]){
       formData.append(i, postData[i])
     }
     else{
       if(i === "amount"){
         callToast(false, "amount is required")
         return
       }
     
     }
    }
     const res = await fetch(
       `${baseUrl.url}/api/food-allowances`,
       {
         method: "POST",
         headers: {
           Authorization: `Bearer ${token}`,
         },
         body: formData,
       }
     );
     const resData = await res.json();
     if (res.ok) {
       callToast(true, "Food request sent successfully");
       setTimeout(() => {
         navigate("/fa")
      }, 400)
     } else {
       callToast(false, resData?.error);
     }
   } catch (err) {
     callToast(false, err?.message);
   }

    
    }
    return (
        <div>
          <Toast ref={toast}/>
      <h1 className="text-base text-sky-500 font-semibold">Food Allowance</h1>
      <p className="text-slate-600 text-sm dark:text-white">
        Request for food allowance bellow
      </p>
             <form onSubmit={handleSubmit}>
          <div className="mt-8">
        <div className="grid grid-cols-12 mb-2">
          <div className="col-span-2">
            <label htmlFor="" className="text-sm dark:text-white">
              Date
            </label>
          </div>
          <div className="col-span-8">
            <input
              type="date"
              readOnly
              value={new Date().toISOString().split("T")[0]}
              className="p-3 border border-slate-300 rounded-md focus:ring-4 focus:outline-none focus:ring-indigo-200 focus:border-violet-500 dark:bg-gray-700 dark:text-white date-input"
              placeholder="00.00 tk"
            />
          </div>
        </div>
      
    
        <div className="grid grid-cols-12 mb-2">
          <div className="col-span-2">
            <label htmlFor="" className="text-sm dark:text-white">
              Amount*
            </label>
          </div>
          <div className="col-span-8">
            <input
              type="number"
              name="amount"
              min={20}
              max={1000}
              onChange={(e) => handleChangeData(e, e.target.value)}
              className="w-full p-3 border border-slate-300 rounded-md focus:ring-4 focus:outline-none focus:ring-indigo-200 focus:border-violet-500 dark:bg-gray-700 dark:text-white"
              placeholder="00.00 tk"
         
            />
          </div>
        </div>
      

        <div className="mt-12">
          <div className="grid grid-cols-12 mb-2">
            <div className="col-span-2">
              <label htmlFor="" className="text-sm dark:text-white">
                Documents / File
              </label>
            </div>
            <div className="col-span-8">
              <input type="file" name="document"  onChange={(e) => handleChangeData(e, e.target.files[0])} className="dark:bg-gray-700 dark:text-white"/>
            </div>
          </div>
          <div className="grid grid-cols-12 mb-2">
            <div className="col-span-2">
              <label htmlFor="" className="text-sm dark:text-white">
                Clients
              </label>
            </div>
            <div className="col-span-8">
              <Dropdown
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                optionLabel="name"
                placeholder={ "Select Client"}
                className="w-full dark:bg-gray-800"
                filter
                valueTemplate={selectedClientTemplate}
                itemTemplate={clientOptionTemplate}
                options={clients?.data}
              />
            </div>
          </div>
          </div>
          <div className="grid grid-cols-12 mb-2">
            <div className="col-span-2">
              <label htmlFor="" className="text-sm dark:text-white">
                Follow up
              </label>
            </div>
            <div className="col-span-8">
              <Dropdown
                value={selectedFollowUp}
                onChange={(e) => setSelectedFollowUp(e.target.value)}
                optionLabel="name"
                placeholder={"Select Follow up"}
                className="w-full dark:bg-gray-800  "
                itemTemplate={followUpTemplate}
                valueTemplate={selectedFollowUpTemplate}
                options={followUpData}
          
              />
            </div>
          </div>
      
          <div className="grid grid-cols-12 mb-2">
            <div className="col-span-2">
              <label htmlFor="" className="text-sm dark:text-white">
               Note
              </label>
            </div>
            <div className="col-span-8">
              <textarea
                name="note"
                onChange={(e) => handleChangeData(e, e.target.value)}
                placeholder="Comments for this day"
                id=""
                className="w-full border focus:outline-none border-slate-300 rounded-md p-2 dark:bg-gray-700 dark:text-white"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div className="grid grid-cols-12 mt-4">
            <div className="col-span-2"></div>
            <div className="col-span-8">
              <button className="bg-sky-500 text-white p-2 px-4 rounded">
                 {"Submit"}
              </button>
            </div>
          </div>
   
    </div>
        </form>
        </div>
    );
};

export default AddFoodAllowance;