import React from 'react'
const MapC = () => {
  
    return (
      <>
        <iFrame
            className={'w-full'}
            style={{height:'89vh'}}
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed/v1/directions?origin=23.7468534,90.3946615&destination=24.7468534,91.3946615&key=AIzaSyBwhrTgTkNHAoeXcPVaJZ8TZUoDqINXFmI">
            </iFrame>
      </>
    )
}

export default MapC