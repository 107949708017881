import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineDownload,
  AiOutlineFileExcel,
  AiOutlinePlus,
} from "react-icons/ai";
import {
  TbFileInvoice
} from "react-icons/tb";
import {
  BsFileEarmarkCheck,
  BsFiletypeCsv,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { MdOutlineSpeakerNotesOff } from "react-icons/md";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  baseUrl,
  checkAvailability,
  modifyDateTime, paginationTemplate, tablePerPageOptions,
} from "../../Helper/helper";
import "./TAllowance.css";
import useData from "../../hooks/useData";
import { FiEdit } from "react-icons/fi";
import { VscPreview } from "react-icons/vsc";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { BiMapPin } from "react-icons/bi";
import PayslipDownloadModal from "../Modal/PayslipDownloadModal";
import SectionHeading from "../SectionHeading/SectionHeading";
const TAllowance = () => {
  // LOCAL STATES AND VARIABLES
  const toast = useRef(null);
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const { token } = useData().store;
  const { profile} = useData().profile;
  const [travelAllowances, setTravelAllowances] = useState([]);
  const [selectedTravelAllowance, setSelectedTravelAllowance] = useState(null);
  let rowClick = false;
  const { authPermissions } = useData().permissions;
  const [allowanceLocation, setAllowanceLocation] = useState({});
  const [showMap, setShowMap] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnLoading2, setBtnLoading2] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const menuRight = useRef(null);

  //Pagination
  const [limitData, setLimitData] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const webLocation = useLocation();
  const searchPage = webLocation?.search?.split("=")[1];


  // Check Permission
  const isCreateUpdateTransportAllowances = checkAvailability(
    authPermissions,
    "create-update-transport-allowance"
  );
  const isAllowanceStatusUpdate = checkAvailability(
    authPermissions,
    "change-transport-allowance-status"
  );
  const isExportTransportAllowance = checkAvailability(
    authPermissions,
    "export-transport-allowance"
  );
  const isPaySlipDownload = checkAvailability(
    authPermissions,
    "transport-allowance-payment-slip"
  );
  const isPayStatusChange = checkAvailability(
    authPermissions,
    "change-transport-allowance-status"
  );

  const [searchData, setSearchData] = useState({
    start_date: "",
    end_date: "",
    name: "",
    amount_start_range: "",
    amount_end_range: "",
    allowance_status: "",
  });
  const [searchedData, setSearchedData] = useState({
    start_date: "",
    end_date: "",
    name: "",
    amount_start_range: "",
    amount_end_range: "",
    allowance_status: "",
  });
  const [searched, setSearched] = useState();


  const handleSearchData = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };


  const callToast = (type, detail) => {
    toast.current.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };


  // Set default page query 1
  useEffect(() => {
    if(!searchPage){
      navigate(`?page=1`)
    }
    else{
      const calculatePage = (searchPage * limitData)-limitData
      setPageNumber(calculatePage)
    }

  }, [searchPage, navigate, limitData]);


  // TABLE DATA AND FUNCTIONS STARTS FROM HERE
  let allItems = [
    {
      label: "Add Allowance",
      icon: <AiOutlinePlus />,
      permission: "create-update-transport-allowance",
      command: () => navigate("/add-ta"),
    },
    {
      label: "Invoices",
      icon: <TbFileInvoice/>,
      permission: "create-update-transport-allowance",
      command: () => navigate("/ta/invoice"),
    },
    {
      label: "Export",
      permission: "export-transport-allowance",
      items: [
        {
          label: "XLSX",
          icon: <AiOutlineFileExcel />,
          command: () => handleExportData("xlsx"),
        },
        {
          label: "CSV",
          icon: <BsFiletypeCsv />,
          command: () => handleExportData("csv"),
        },
      ],
    },
  ];
  const items = allItems?.filter((item) =>
    authPermissions?.find((perm) => perm?.name === item?.permission)
  );


  useEffect(() => {
    const isGetTransportAllowances = checkAvailability(
      authPermissions,
      "get-transport-allowance"
    );
    if (!isGetTransportAllowances) {
      return;
    }
    const {
      start_date,
      end_date,
      name,
      amount_start_range,
      amount_end_range,
      allowance_status,
    } = searchedData;
    const getAllowances = async () => {
      setLoading(true);
      let transport_url = `${
        baseUrl.url
      }/api/transport-allowances/filter?page=${searchPage}&per_page=${limitData}`

      if(start_date || end_date || name || amount_start_range || amount_end_range ||allowance_status){
          transport_url += `${
            start_date && `&start_date=${start_date}`
          }${start_date && end_date && `&end_date=${end_date}`}${
            name && `&search=${name}`
          }${
            amount_start_range &&
            amount_end_range &&
            `&amount_start_range=${amount_start_range}`
          }${
            amount_end_range && `&amount_end_range=${amount_end_range}`
          }${allowance_status && `&status=${allowance_status}`}`
      }

      try {
        const res = await fetch(
          transport_url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setTravelAllowances(data?.data);
          setSearched(data?.search);
        }
      } catch (error) {
        console.error(error?.message);
      } finally {
        setLoading(false);
      }
    };
    getAllowances().then();
  }, [token, authPermissions, update, limitData, searchedData, searchPage]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchedData(searchData);
    navigate(`?page=1`)
  };

  const timeTemplate = (data) => {
    const {start_time, end_time, from_address, to_address} = data
    const start = modifyDateTime(start_time);
    const end = modifyDateTime(end_time);
    return (
      <span>
        {start && end ? (
          <div className={"flex gap-2 justify-around items-center"}>
            <div>
              <p>  {from_address}</p>
              <p className={"text-sm text-gray-600"}>{start}</p>
            </div>
            {
                to_address && <p>-</p>
            }
            {
                to_address &&  <div>
                  <p>  {to_address}</p>

                  <p className={"text-sm text-gray-600"}>{end_time ? end : ".          .            ."}</p>
                </div>
            }
            {/*{start} {data?.end_time ? `- ${end}` : ""}*/}
          </div>
        ) : (
          <p>N/A</p>
        )}
      </span>
    );
  };

  const transportTypeTemplate = (data) => {
    const type = data?.transport_type;

    return <p>{type ? type : "N/A"}</p>;
  };

  const statuses = [
    { name: "Pending", code: 0 },
    { name: "Paid", code: 1 },
    { name: "Rejected", code: 2 },
  ];


  // Status template
  const statusTemplate = (data) => {
    const {id, end_time, allowance_status} = data
    console.log("status", allowance_status, data)
    return (
      <div>
        {(allowance_status === 0 || allowance_status === 1 ) ? (
          <>
            {isAllowanceStatusUpdate && end_time ? (
              <select
                value={data?.allowance_status}
                onChange={(e) => handleChangeStatus(id, e.target.value)}
                className="bg-white focus:p-1 focus:outline-slate-500 dark:bg-slate-800 dark:text-white"
              >
                {statuses?.map((status, i) => {
                  return (
                      <option key={i} value={status?.code}>
                        {status?.name}
                      </option>
                  )
                    }

                )}
              </select>
            ) : (
              <p className="">Pending</p>
            )}
          </>
        ) : (
          <p className="text-red-500">Rejected</p>
        )}
      </div>
    );
  };

  // Table Map Template
  const mapTemplate = (data) => {
    const { from_lat, from_lng, to_lat, to_lng } = data;

    return (
      <button
        onClick={() => {
          setAllowanceLocation({ from_lat, from_lng, to_lat, to_lng });
          setShowMap(!showMap);
        }}
        className="dark:bg-gray-800 "
      >
        {" "}
        <BiMapPin />
      </button>
    );
  };

  // Table Action Template
  const actionTemplate = (data) => {
    const {created_by, end_time, document} = data
    const profileId = profile?.id
    return (
      <div className="flex items-center justify-center dark:bg-gray-800 dark:text-white h-full">
        {!end_time && profileId === created_by ? (
          <button
            onClick={() => handleComplete(data)}
            className="bg-green-500 text-white px-3 py-1"
          >
            Ongoing
          </button>
        ) : (
          <div className="flex">
            <button
              onClick={() => handleViewDocument(document)}
              className="bg-sky-600 text-white font-semibold p-2"
            >
              <VscPreview />
            </button>

            {isCreateUpdateTransportAllowances && (
              <Link to={`/ta/${data?.id}`}>
                <button
                  className="bg-sky-500 text-white font-semibold
               p-2"
                >
                  <FiEdit />
                </button>
              </Link>
            )}
          </div>
        )}
      </div>
    );
  };

  // View Document Function
  const handleViewDocument = (document) => {
    if (document) {
      window.open(`${baseUrl.url}/${document}`);
    } else {
      callToast(false, "No document found");
    }
  };

  // TABLE DATA AND FUNCTIONS ENDS FROM HERE
  const handleChangeStatus = async (id, statusId) => {
    const updatedStatus = {
      status: statusId,
      allowances: [id],
    };
    try {
      const res = await fetch(
        `${baseUrl.url}/api/transport-allowances/change-status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedStatus),
        }
      );
      const resData = await res.json();
      console.error(resData);
      if (res.ok) {
        callToast(true, "Allowance Status updated successfully");
        setTimeout(() => {
          setUpdate(!update);
        }, 400);
      } else {
        callToast(false, resData?.error);
      }
    } catch (err) {
      callToast(false, err?.message);
    }
  };
  const handleComplete = () => {
    navigate(`/ta/end`);
  };

  const handleExportData = (type) => {
    if (!isExportTransportAllowance) {
      return;
    }
    const random_number = new Date().getTime();
    const exportData = async () => {
      const res = await fetch(
        `${baseUrl.url}/api/transport-allowances/export/all?type=${type}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.blob();

      if (res.ok) {
        callToast(true, `Transport  allowances ${type} download successfully`);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `transport_allowances_${random_number}.${type}`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        callToast(false, data?.error);
      }
    };
    exportData().then();
  };


  const random_number = new Date().getTime();
  const handleDownload = async (status) => {
    if (!isPaySlipDownload) {
      return;
    }

    let downLoadSlipIds = [];
    const createdBy = selectedTravelAllowance[0]?.created_by;
    for (let i = 0; i < selectedTravelAllowance.length; i++) {
      const element = selectedTravelAllowance[i];
      if (status) {
        if (element?.allowance_status !== 0) {
          callToast(false, "You can only changed pending allowance status.");
          return;
        }
      }
      if (!element?.end_time) {
        if (status) {
          callToast(false, "You can not change  ongoing allowance status.");
          return;
        } else {
          callToast(
            false,
            "You can not download ongoing allowance status payslip."
          );
          return;
        }
      }
      if (createdBy !== element?.created_by) {
        callToast(false, "At a time you can download only one person payslip");
        return;
      }
      downLoadSlipIds.push(element.id);
    }
    const newObj = {
      allowances: downLoadSlipIds,
    };
    if (status) {
      newObj.pay_status = 1;
    }
    try {
      if (status) {
        setBtnLoading2(true);
      } else {
        setBtnLoading(true);
      }
      const res = await fetch(
        `${baseUrl.url}/api/transport-allowance-payment-slip`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          body: JSON.stringify(newObj),
        }
      );
      const data = await res.blob();
      if (res.ok) {
         setUpdate(!update)
        callToast(true, "Payslip Download Successfully");
        setShowDownloadModal(false);
        setSelectedTravelAllowance([]);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `transport_allowance_payslip_${random_number}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        callToast(false, "payslip Download Failed");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBtnLoading(false);
      setBtnLoading2(false);
    }
  };
  const itemsRight = [
    {
      items: [
        {
          label: "Download payslip",
          permission: "transport-allowance-payment-slip",
          icon: <AiOutlineDownload />,
          command: async () => {
            setShowDownloadModal(true);
          },
        },
        {
          label: "Mark as Paid",
          permission: "change-transport-allowance-status",
          icon: <BsFileEarmarkCheck />,
          command: async () => {
            if (!isPayStatusChange) {
              return;
            }
            let allowances = [];
            for (let i = 0; i < selectedTravelAllowance.length; i++) {
              const element = selectedTravelAllowance[i];
              // if (element?.allowance_status !== 0) {
              //   callToast(
              //     false,
              //     "You can change only pending allowance status."
              //   );
              //   return;
              // } else
                if (!element?.end_time) {
                callToast(
                  false,
                  "You can not change ongoing allowance status."
                );
                return;
              }
              allowances.push(element.id);
            }
            const newObj = {
              status: 1,
              allowances: allowances,
            };
            try {
              const res = await fetch(
                `${baseUrl.url}/api/transport-allowances/change-status`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${token}`,
                  },
                  body: JSON.stringify(newObj),
                }
              );
              if (res.ok) {
                console.log("okkk")
                setUpdate(!update);
                callToast(true, "Allowance Status Updated Successfully");
                setSelectedTravelAllowance([]);
              } else {
                const resData = await res.json();
                callToast(false, resData?.error);
              }
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "Mark as Rejected",
          permission: "change-transport-allowance-status",
          icon: <MdOutlineSpeakerNotesOff />,
          command: async () => {
            if (!isPayStatusChange) {
              return;
            }
            let allowances = [];
            for (let i = 0; i < selectedTravelAllowance.length; i++) {
              const element = selectedTravelAllowance[i];
              // if (element?.allowance_status !== 0) {
              //   callToast(
              //     false,
              //     "You can change only pending allowance status."
              //   );
              //   return;
              // } else
                if (!element?.end_time) {
                callToast(
                  false,
                  "You can not change ongoing allowance status."
                );
                return;
              }
              allowances.push(element.id);
            }
            const newObj = {
              status: 2,
              allowances: allowances,
            };
            try {
              const res = await fetch(
                `${baseUrl.url}/api/transport-allowances/change-status`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${token}`,
                  },
                  body: JSON.stringify(newObj),
                }
              );
              if (res.ok) {
                setUpdate(!update);
                callToast(true, "Allowance Status Updated Successfully");
                setSelectedTravelAllowance([]);
              } else {
                const resData = await res.json();
                callToast(false, resData?.error);
              }
            } catch (error) {
              console.error(error);
            }
          },
        },
      ],
    },
  ];

  const modifyMenuData = itemsRight?.map((menu) => {
    const items = menu?.items?.filter((item) =>
      authPermissions?.find((perm) => perm?.name === item?.permission)
    );
    return {
      items: items?.length > 0 ? items : [],
    };
  });

  const totalAmount = () => {
    return selectedTravelAllowance?.reduce((acc, data) => {
      return data?.amount + acc
    }, 0)
  }

  return (
    <div>
      <Toast ref={toast} />
        <>
          {showDownloadModal && (
            <PayslipDownloadModal
              visible={showDownloadModal}
              setVisible={setShowDownloadModal}
              btnLoading={btnLoading}
              btnLoading2={btnLoading2}
              handleDownload={handleDownload}
              toast={toast}
            />
          )}
          <Dialog
            header="Locations"
            visible={showMap}
            modal={false}
            className="w-11/12 sm:w-10/12 lg:w-8/12 2xl:w-2/4"
            onHide={() => {
              setShowMap(false);
              setAllowanceLocation({});
            }}
          >
            {allowanceLocation?.from_lat &&
            allowanceLocation?.from_lng &&
            allowanceLocation?.to_lat &&
            allowanceLocation?.to_lng ? (
              <iFrame
                className={"w-full"}
                style={{ height: "70vh" }}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/directions?origin=${allowanceLocation?.from_lat},${allowanceLocation?.from_lng}&destination=${allowanceLocation?.to_lat},${allowanceLocation?.to_lng}&key=AIzaSyBwhrTgTkNHAoeXcPVaJZ8TZUoDqINXFmI`}
              ></iFrame>
            ) : (
              <div>
                <div>
                  <img
                    src="https://s1.eestatic.com/2015/10/12/elandroidelibre/el_androide_libre_71003130_179986200_1706x960.jpg"
                    className="w-3/4 lg:w-1/4 mx-auto"
                    alt=""
                  />
                </div>
                <h1 className="text-xl text-center">No location found</h1>
                <p className="text-base text-center">
                  No coordinates added or found nothing
                </p>
              </div>
            )}
          </Dialog>

          <div className="flex items-center justify-between">
            <SectionHeading
              title={"Transport Allowance"}
              text={"Transport allowances are detailed bellow"}
            />
            {items?.length > 0 && (
              <div className="flex justify-end">
                <div className="zero">
                  <Button
                    icon={<BsThreeDotsVertical className="dark:text-white" />}
                    onClick={(event) => menuLeft.current.toggle(event)}
                    aria-controls="popup_menu_left"
                    aria-haspopup
                  />
                </div>
                <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
              </div>
            )}
          </div>

          <div className="w-full grid grid-cols-12 py-3">
                  <form
                    onSubmit={handleSearch}
                    className="col-span-12 2xl:col-span-11"
                  >
                    <div className="w-full lg:flex lg:flex-wrap items-center gap-2">
                      <div className=" my-2 xl:my-0">
                        <input
                          type="search"
                          name="name"
                          defaultValue={searched?.name}
                          onChange={(e) => handleSearchData(e)}
                          className="bg-white w-full text-sm border px-1 py-2 focus:outline-none dark:bg-gray-800 dark:text-white dark:placeholder-white"
                          placeholder="Search by name"
                        />
                      </div>
                      <div className=" flex lg:flex-none items-center my-2 xl:my-0">
                        <input
                          type="date"
                          name="start_date"
                          defaultValue={searched?.start_date}
                          onChange={(e) => handleSearchData(e)}
                          className="bg-white w-full text-sm border px-1 py-2  focus:outline-none dark:bg-gray-800 dark:text-white dark:placeholder-white date-input"
                        />
                        <span className="mx-2 dark:text-white">to</span>
                        <input
                          type="date"
                          name="end_date"
                          defaultValue={searched?.end_date}
                          onChange={(e) => handleSearchData(e)}
                          className="bg-white w-full  text-sm border px-1 py-2  focus:outline-none dark:bg-gray-800 dark:text-white dark:placeholder-white date-input"
                          disabled={!searchData?.start_date && true}
                        />
                      </div>

                      <div className=" flex lg:flex-none items-center  my-2 xl:my-0">
                        {/* <span className="text-sm">Amount</span> */}
                        <input
                          type="number"
                          name="amount_start_range"
                          defaultValue={searched?.amount_start_range}
                          onChange={(e) => handleSearchData(e)}
                          className="bg-white w-full text-sm border px-1 py-2  focus:outline-none dark:bg-gray-800 dark:text-white dark:placeholder-white"
                          placeholder="Start amount"
                        />
                        <span className="mx-2 dark:text-white">to</span>
                        <input
                          type="number"
                          name="amount_end_range"
                          defaultValue={searched?.amount_end_range}
                          onChange={(e) => handleSearchData(e)}
                          className="bg-white w-full  text-sm border px-1 py-2  focus:outline-none dark:bg-gray-800 dark:text-white dark:placeholder-white"
                          placeholder="End amount"
                        />
                      </div>
                      <div className=" my-2 xl:my-0">
                        <select
                          name="allowance_status"
                          defaultValue={searched?.status}
                          onChange={(e) => handleSearchData(e)}
                          className="bg-white w-full text-sm border px-1 py-2  focus:outline-none dark:bg-gray-800 dark:text-white"
                        >
                          <option className="text-gray-400">
                            Select status
                          </option>
                          {statuses?.map((status, i) => (
                            <option key={i} value={status?.code}>
                              {status?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className=" cursor-pointer w-full text-white bg-sky-500 p-2 text-sm"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>


          <div className="pt-3">
            <DataTable
                value={travelAllowances?.data}
                dataKey="id"
                tableStyle={{ minWidth: "50rem" }}
                sortMode="multiple"
                showGridlines={true}
                key={pageNumber}
                className="datatable-responsive myTable"
                emptyMessage="No allownace found."
                rowHover={true}
                loading={loading}
                lazy
                paginator={travelAllowances?.total > 0 || false}
                rows={limitData}
                totalRecords={travelAllowances?.total || 0}
                first={pageNumber}
                onPage={(e) => {
                  setLimitData(e.rows);
                  let pages = Math.ceil(e.first / e.rows + 1);
                  navigate(`?page=${pages}`)

                }}
                paginatorTemplate={paginationTemplate}
                pageLinkSize={10}
                rowsPerPageOptions={tablePerPageOptions}
                selectionMode={
                  rowClick
                      ? null
                      : modifyMenuData?.length > 0 &&
                      modifyMenuData[0]?.items?.length > 0 &&
                      "checkbox"
                }

                selection={selectedTravelAllowance}
                onSelectionChange={(e) =>
                    modifyMenuData?.length > 0 &&
                    modifyMenuData[0]?.items?.length > 0 &&
                    setSelectedTravelAllowance(e.value)
                }

                footer={  <div className="w-full h-16 sm:h-10 flex flex-wrap items-center gap-4 justify-between">
                  {selectedTravelAllowance?.length > 0 &&
                      modifyMenuData[0]?.items?.length > 0 && (
                          <div>
                            <Menu
                                model={modifyMenuData}
                                popup
                                ref={menuRight}
                                id="popup_menu_left"
                                popupAlignment="left"
                            />
                            <Button  label={"Options"}  size={"small"}
                                     aria-controls="popup_menu_left"
                                     aria-haspopup
                                     onClick={(event) => menuRight.current.toggle(event)}/>
                          </div>
                      )}

                  {
                      selectedTravelAllowance?.length > 0 && <div className={"flex justify-end"}>
                        <span className={" font-medium border bg-sky-500 text-sm sm:text-base p-1 sm:p-2 text-white"}>Total Amount : {totalAmount()}</span>
                      </div>
                  }


                </div>}
            >
              {modifyMenuData?.length > 0 &&
                  modifyMenuData[0]?.items?.length > 0 && (
                      <Column
                          selectionMode="multiple"
                          headerStyle={{ width: "3rem" }}
                          className="dark:bg-gray-800 dark:text-white"
                      ></Column>
                  )}
              <Column header={"ID"} field={"id"}/>
              <Column
                  header="Travel Area"
                  headerStyle={{ width: "30rem" }}
                  body={timeTemplate}
                  className="dark:bg-gray-800 dark:text-white"
              />
              <Column
                  header="Transport Type"
                  body={transportTypeTemplate}
                  className="dark:bg-gray-800 dark:text-white"
              />
              <Column
                  header="Amount"
                  field="amount"
                  className="dark:bg-gray-800 dark:text-white"
              />
              {(travelAllowances?.data?.length > 0 && travelAllowances?.data[0]?.name) && (
                  <Column
                      header="Created By"
                      field="name"
                      className="dark:bg-gray-800 dark:text-white"
                  />
              )}
              <Column
                  header="Allowance Status"
                  body={statusTemplate}
                  className="dark:bg-gray-800 dark:text-white"
              />
              <Column
                  header="Map"
                  body={mapTemplate}
                  className="dark:bg-gray-800 dark:text-white"
              />
              <Column
                  header={"Action"}
                  body={actionTemplate}

              />
            </DataTable>
            {/*<Pagination*/}
            {/*  data={travelAllowances}*/}
            {/*  setUrl={setUrl}*/}
            {/*  perPageLimit={perPageLimit}*/}
            {/*  setPerPageLimit={setPerPageLimit}*/}
            {/*  pageNo={pageNo}*/}
            {/*  setPageNo={setPageNo}*/}
            {/*/>*/}
          </div>


        </>
      
    </div>
  );
};

export default TAllowance;
