import React, { useRef } from "react";
import "./Sidebar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  MdFormatListBulletedAdd,
  MdListAlt,
  MdOutlineInsertChartOutlined,
  MdSupervisedUserCircle,
  MdTransferWithinAStation,
} from "react-icons/md";
import { baseUrl } from "../../Helper/helper";
import {
  HiCurrencyBangladeshi,
  HiOutlineCurrencyBangladeshi,
} from "react-icons/hi2";
import useData from "../../hooks/useData";
import { Toast } from "primereact/toast";
import { FaUserCheck, FaUserPlus, FaUserShield, FaUsers } from "react-icons/fa";
import { IoFastFood } from "react-icons/io5";
import { BiCategoryAlt, BiListPlus, BiTransfer } from "react-icons/bi";
import { ImUserPlus } from "react-icons/im";
import { AiFillHome } from "react-icons/ai";
import { LuClipboardList } from "react-icons/lu";
import {RiBillLine} from "react-icons/ri";

const Sidebar = ({ show, setShow }) => {
  const { token } = useData().store;
  const navigate = useNavigate();
  const width = window?.innerWidth;
  const { authPermissions } = useData().permissions;
  const toast = useRef();

  // Side menu items array
  const menuItems = [
    {
      id: 0,
      group_name: "Home",
      group: [
        {
          id: 1,
          title: "Analytics",
          path: "/analytics",
          icon: <AiFillHome className="text-white dark:text-white" />,
        },
      ],
    },
    {
      id: 1,
      group_name: "Role",
      group: [
        {
          id: 1,
          title: "Role List",
          path: "/role-list",
          icon: <FaUserShield className={`text-white dark:text-white `} />,
          permission: "get-role-list",
        },
      ],
    },

    {
      id: 2,
      group_name: "User",
      group: [
        {
          id: 1,
          title: "Add User",
          path: "/add-user",
          icon: <ImUserPlus size={20} className="text-white dark:text-white" />,
          permission: "create-user",
        },
        {
          id: 2,
          title: "Users",
          path: "/user",
          icon: (
            <MdSupervisedUserCircle
              size={25}
              className="text-white dark:text-white"
            />
          ),
          permission: "get-user-list",
        },
        {
          id: 3,
          title: "Employees",
          path: "/employee",
          icon: <BiListPlus size={25} className="text-white dark:text-white" />,
          permission: "employee-payable-salary-list",
        },
        {
          id: 3,
          title: "Salary",
          path: "/salary",
          icon: (
            <HiOutlineCurrencyBangladeshi
              size={25}
              className="text-white dark:text-white"
            />
          ),
          permission: "employee-payable-salary-list",
        },
        {
          id: 4,
          title: "Todo",
          path: "/todo",
          icon: <MdFormatListBulletedAdd />,
          permission: "get-todo-list",
        },
      ],
    },
    {
      id: 3,
      group_name: "Clients",
      group: [
        {
          id: 1,
          title: "Add Client",
          path: "/add-client",
          icon: <FaUserPlus size={16} className="text-white dark:text-white" />,
          permission: "create-client",
        },
        {
          id: 2,
          title: "Clients",
          path: "/clients",
          icon: <FaUsers className="text-white dark:text-white" />,
          permission: "get-client-list",
        },
        {
          id: 3,
          title: "Confirmed Clients",
          path: "/confirm-clients",
          icon: <FaUserCheck className="text-white dark:text-white" />,
          permission: "get-client-list",
        },
      ],
    },

    {
      id: 4,
      group_name: "Payment",
      group: [
        {
          id: 1,
          title: "Bill",
          path: "/bill",
          icon: (
            <LuClipboardList size={25} className="text-white dark:text-white" />
          ),
          permission: "get-bills",
        },
        {
          id: 1,
          title: "Custom Bill",
          path: "/custom/bill",
          icon: (
              <RiBillLine size={25} className="text-white dark:text-white" />
          ),
          permission: "get-bills",
        },
        {
          id: 1,
          title: "Transaction",
          path: "/transaction",
          icon: <BiTransfer size={25} className="text-white dark:text-white" />,
          permission: "get-client-transactions",
        },
        {
          id: 2,
          title: "Payment",
          path: "/payment",
          icon: (
            <HiCurrencyBangladeshi
              size={25}
              className="text-white dark:text-white"
            />
          ),
          permission: "create-client-payment",
        },
      ],
    },
    {
      id: 5,
      group_name: "Allowance",
      group: [
        {
          id: 1,
          title: "Transport Allowance",
          path: "/ta",
          icon: (
            <MdTransferWithinAStation
              size={25}
              className="text-white dark:text-white"
            />
          ),
          permission: "get-transport-allowance",
        },
        {
          id: 2,
          title: "Food Allowance",
          path: "/fa",
          icon: <IoFastFood size={22} className="text-white" />,
          permission: "get-food-allowance",
        },
      ],
    },
    {
      id: 6,
      group_name: "Settings",
      group: [
        {
          id: 1,
          title: "Point Types",
          path: "/point-types",
          icon: <MdListAlt size={22} className="text-white dark:text-white" />,
          permission: "get-point-type-list",
        },
        {
          id: 2,
          title: "KPI",
          path: "/kpi",
          icon: (
            <MdOutlineInsertChartOutlined
              size={22}
              className="text-white dark:text-white"
            />
          ),
          permission: "update-kpi-lookup",
        },
        {
          id: 3,
          title: "Services",
          path: "/services",
          icon: (
            <BiCategoryAlt size={20} className="text-white dark:text-white" />
          ),
          permission: "get-service",
        },
      ],
    },
  ];

  // Filter menu item by menu permission
  const filterSidebar = menuItems.map((group) => {
    const filterSlateGroup = {
      ...group,
      group: group.group.filter((item) => {
        return authPermissions?.some(
          (permission) => permission.name === item.permission
        );
      }),
    };
    if (filterSlateGroup.group?.length > 0) {
      return filterSlateGroup;
    }
    return {};
  });

  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  // Delete cookie function
  const delete_cookie = function (name) {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };

  // Logout function
  const handleLogout = async () => {
    try {
      const res = await fetch(`${baseUrl.url}/api/logout`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const resData = await res.json();
      if (res.ok) {
        callToast("Logout Successful");
        delete_cookie("access_token");
        setTimeout(() => {
          navigate("/login");
        }, 400);
      } else {
        callToast(false, resData?.error);
      }
    } catch (error) {
      callToast(false, error?.message);
    } finally {
      navigate("/login");
    }
  };

  return (
    <aside className="w-full">
      <Toast ref={toast} />
      {show && (
        <div
          onClick={() => setShow(!show)}
          className="w-full bg-black fixed top-0 left-0 z-10 opacity-10 block lg:hidden h-screen"
        ></div>
      )}
      <div
        className={`transition-all duration-700 overflow-y-auto fixed top-0 z-40 h-full  bg-gray-900 w-64 ${
          show ? "ml-0 lg:-ml-72" : "-ml-72 lg:ml-0  "
        }`}
      >
        <div
          style={{ padding: "5.5px 0" }}
          className="flex justify-center bg-gray-800 dark:border-gray-800 border-b border-gray-950"
        >
          <div
            style={{ paddingTop: "1px", paddingBottom: "0px" }}
            className="w-44 relative z-50"
          >
            <Link to="/">
              <img
                src={
                  "https://selopia.com/wp-content/uploads/2022/08/logo_white.png"
                }
                alt="selopia"
                className="w-full h-full"
              />
            </Link>
          </div>
        </div>

        <div className="pb-12">
          <div>
            {filterSidebar?.map((items, i) => {
              const objectLength = Object.keys(items).length;
              const { group_name, group } = items;
              if (objectLength <= 0) {
                return null;
              }
              return (
                <div key={i}>
                  <p className="text-sm text-slate-300 py-1 px-3 pt-3 dark:text-white">
                    {group_name}
                  </p>

                  {group?.map((item, index) => {
                    const { title, icon, path } = item;
                    return (
                      <NavLink
                        key={index}
                        onClick={() => width < 1023 && setShow(!show)}
                        to={path}
                        className={({ isActive }) =>
                          `p-1 px-3 block ${
                            isActive
                              ? " bg-gray-700 text-white font-bold "
                              : "text-white"
                          }`
                        }
                      >
                        <p className="flex gap-3 items-center">
                          <span className="w-8 h-8 flex items-center shadow justify-center rounded-full ">
                            {icon}
                          </span>
                          <span className="text-sm">{title}</span>
                        </p>
                      </NavLink>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        <div className="fixed bottom-0 w-56 text-center mx-4 mb-2">
          <button
            onClick={handleLogout}
            className="w-full py-1 text-white bg-red-500 hover:border-0 rounded"
          >
            Logout
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
