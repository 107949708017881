import { Dialog } from "primereact/dialog";
import React, {useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { baseUrl} from "../../Helper/helper";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";

const CreateTaskModal = ({ visible, setVisible, token, toast, users, formateDate, update, setUpdate, isAssignTask,selectedUsersTemplate, userOptionTemplate, callToast, priorityLevels, selectedPriorityLevelTemplate}) => {
  const [selectedUsers, setSelectedUsers] = useState();
  const [data, setData] = useState({})
  const [selectedPriorityLevel, setSelectedPriorityLevel] = useState(null);
  const [datetime24h, setDateTime24h] = useState(null);
//   const priorityLevels = [
//       { name: 'High Priority', code: '1' },
//       { name: 'Important', code: '2' },
//       { name: 'Neutral', code: '3' }
//   ];

// const selectedPriorityLevelTemplate = (option, props) => {
//     if(option){
//         return <p className={`${+option?.code === 1 ? "text-red-600" : +option?.code ===2 ? "text-green-500": "text-yellow-500"}`}>{option?.name}</p>
//     }
//     return props?.placeholder
// }
//   const selectedUsersTemplate = (option) => {

//    if(option){
//     return (
//         <img src={`${baseUrl?.url}${option?.avatar}`} alt="user" className="w-5 h-5 rounded-full"/>
//  );
//    }
    
//   };
//   const userOptionTemplate = (option) => {

//     return (
//       <div className="flex align-items-center gap-2">
//         <img
//           alt={option.name}
//           src={`${baseUrl?.url}${option?.avatar}`}
//           className={`rounded-full w-6 h-6`}
//           style={{ width: "18px" }}
//         />
//         <div>{option.name}</div>
//       </div>
//     );
//   };
  const handleSubmit = async() => {
    if(!isAssignTask){
        return
    }
    if(!data?.title){
        callToast(false, "Title is required")
        return
    }
    else if(!datetime24h){
      callToast(false, "Task detail is required")
      return
  }
    else if(!data?.detail){
        callToast(false, "Task detail is required")
        return
    }
    else if(selectedUsers?.length ===0){
        callToast(false, "No user is selected")
        return
    }
    else if(!selectedPriorityLevel?.code){
        callToast(false, "Priority level is required")
        return
    }
    // const selectedUsersIds = selectedUsers?.map(user => user?.value)
    // console.log("se", selectedUsers)
    const postData = {
        title: data?.title,
        detail: data?.detail,
        priority_level: selectedPriorityLevel?.code,
        users: selectedUsers,
        deadline: formateDate(datetime24h)

    }
   try{
    const res = await fetch(`${baseUrl?.url}/api/assign-task`, {
        method: "POST",
        headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(postData)
    })
    const resData = await res.json()
    if(res.ok){
      setUpdate(!update)
      setVisible(false)
      callToast(true, "Task created successfully")
    }
    else{
        callToast(false, resData?.error)
    }
   }
   catch(error){
    console.error(error)
   }
  }
  return (
    <div className="card flex flex-wrap justify-content-center w-full">
        <Toast ref={toast}/>
      <Dialog
        header={`Create task`}
        visible={visible}
        className="w-11/12 sm:w-10/12 lg:w-8/12 2xl:w-1/3"
        onHide={() => {
          setVisible(false);
        }}
      >
        <div>
            <div className="my-3">
            <label className="text-gray-500 text-sm py-2 font-semibold">Title</label>
           <InputText
                type='text'
                name="title"
                onChange={(e) => setData({...data, [e.target.name] : e.target.value})}
                className="w-full"
                placeholder='title'
              
              />
            </div>
          <div className="card">
          <label className="text-gray-500 text-sm py-2 font-semibold block">Users</label>
            <MultiSelect
              value={selectedUsers}
              onChange={(e) => setSelectedUsers(e.value)}
              options={users}
              selectedItemTemplate={selectedUsersTemplate}
              itemTemplate={userOptionTemplate}
              optionLabel="name"
              filter
              placeholder="Select users"
              className="w-full md:w-20rem"
            />
          </div>
          <div className=" mt-3">
          <label className="text-gray-500 text-sm py-2 font-semibold block">Priority Level</label>
            <Dropdown value={selectedPriorityLevel} onChange={(e) => setSelectedPriorityLevel(e.value)} options={priorityLevels} valueTemplate={selectedPriorityLevelTemplate} optionLabel="name" 
                placeholder="Select priority level" className="w-full md:w-14rem" />
        </div>
        <div className="mt-3">
                <label  className="text-gray-500 text-sm py-2 font-semibold block">Expired Date</label>
                <Calendar
                  id="calendar-24h"
                  value={datetime24h}
                  className="w-full"
                  onChange={(e) => setDateTime24h(e.value)}
                  showTime
                  hourFormat="12"
                  placeholder="Date"
                />
              </div>
          <div className="mt-3">
            <label className="my-1 block">Detail</label>
            <InputTextarea
              className="w-full"
              name="detail"
              onChange={(e) => setData({...data, [e.target.name] : e.target.value})}
              rows={3}
              placeholder="type here"
            />
          </div>
          <div  className='mt-4'>
            <Button onClick={handleSubmit} type="submit" size="small" label="Submit" severity="info"/>
            </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateTaskModal;
