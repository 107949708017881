import React, {useEffect, useRef, useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AddDocumentModal from "../Modal/AddDocumentModal";
import {baseUrl, checkAvailability, paginationTemplate, tablePerPageOptions} from "../../Helper/helper";
import {Toast} from "primereact/toast";
import {HiOutlineDocumentDownload} from "react-icons/hi";
import {VscPreview} from "react-icons/vsc";
import {AiOutlineFileExcel, AiOutlinePaperClip} from "react-icons/ai";
import {TfiReload} from "react-icons/tfi";
import useData from "../../hooks/useData";
import {Button} from "primereact/button";
import {
    BsFiletypeCsv,
    BsFiletypePdf,
    BsThreeDotsVertical,
} from "react-icons/bs";
import {Menu} from "primereact/menu";
import SectionHeading from "../SectionHeading/SectionHeading";
import {FiEdit} from "react-icons/fi";

const ConfirmedClients = () => {
    // LOCAL STATES
    const [confirmClients, setConfirmClients] = useState();
    const {token} = useData().store;
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);
    const [obj, setObj] = useState(null);
    const [value, setValue] = useState();
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [search, setSearch] = useState(null);
    const {authPermissions} = useData().permissions;
    const isClientUpdate = checkAvailability(authPermissions, "update-client");
    const isGetClientFollowUp = checkAvailability(
        authPermissions,
        "get-client-follow-up"
    );
    const isGetPayslip = checkAvailability(authPermissions, "get-payslip");
    const isExportClientList = checkAvailability(
        authPermissions,
        "export-client-list"
    );

    const menuLeft = useRef(null);
    const navigate = useNavigate();

    const callToast = (type, detail) => {
        toast.current?.show({
            severity: type ? "success" : "error",
            summary: type ? "Success" : "Error",
            detail: detail,
            life: 3000,
        });
    };

    //Pagination
    const [limitData, setLimitData] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);

    // Web Location
    const webLocation = useLocation();
    const params = webLocation?.search?.split("=")[1] || "";
    const searchPage = webLocation?.hash?.split("=")[1];

    // Set default page query 1 and calculate prime page number
    useEffect(() => {
        if(!searchPage){
            navigate(`#page=1`)
        }
        else{
            const calculatePage = (searchPage * limitData)-limitData
            setPageNumber(calculatePage)
        }

    }, [searchPage, navigate, limitData]);



    useEffect(() => {
        const IsGetClient = checkAvailability(authPermissions, "get-client-list");
        if (!IsGetClient) {
            return;
        }
        const clients = async () => {
            setLoading(true);
            try {
                const res = await fetch(
                    `${baseUrl.url}/api/clients?confirmed=1&page=${searchPage}${
                                params ? `&search=${params}` : ""}${limitData && `&per_page=${limitData}`}`
                    ,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const data = await res.json();
                if (res.ok) {
                    setConfirmClients(data?.data);
                    setSearch(data?.search);
                }
            } catch (err) {
                console.error(err?.message);
            } finally {
                setLoading(false);
            }
        };
        clients().then();
    }, [token, params, update, limitData, searchPage, authPermissions]);

    // TABLE FUNCTIONS FOR COLUMN DATA
    const handleSearch = (e) => {
        e.preventDefault();
        const IsGetClient = checkAvailability(authPermissions, "get-client-list");
        if (!IsGetClient) {
            return;
        }
        if (value !== null) {
            navigate(`?search=${value}#page=${searchPage}`);
        }
    };
    const nameTemplate = (data) => {
        const name = data?.name;
        const id = data?.id;
        return (
            <Link to={`/confirm-client/${id}`} className="underline">
                {name}
            </Link>
        );
    };
    const actionTemplate = (data) => {
        const paymentId = data?.payment_id;
        return (
            <div className="flex">
                <button
                    onClick={() => {
                        setVisible(true);
                        setObj(data);
                    }}
                    className="bg-sky-500 text-white font-semibold
          p-2"
                >
                    <AiOutlinePaperClip/>
                </button>
                {isGetPayslip && (
                    <button
                        onClick={() => handleDownload(paymentId)}
                        className="bg-sky-600 text-white font-semibold p-2"
                    >
                        <HiOutlineDocumentDownload className="text-white"/>
                    </button>
                )}
                <Link to={`/client/${data?.id}`}>
                    <button
                        onClick={() => {
                            setVisible(true);
                            setObj(data);
                        }}
                        className="bg-sky-500 text-white font-semibold
          p-2"
                    >
                        <FiEdit/>
                    </button>
                </Link>
            </div>
        );
    };
    const followUpTemplate = (data) => {
        let id = data?.id;
        return (
            <div className="flex w-full justify-center">
                {isGetClientFollowUp && (
                    <Link to={`/follow_up/${id}`}>
                        <button className="bg-sky-600 text-white p-2">
                            <VscPreview className="text-white"/>
                        </button>
                    </Link>
                )}
            </div>
        );
    };

    const confirmationDateTemplate = (data) => {
        let splitDate = data?.confirmation_date?.split(" ")[0];
        let date = new Date(splitDate)?.toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
        return <span>{date}</span>;
    };


    const exportTransactionTemplate = (data) => {
        const id = data?.id;
        const transactions = data?.transactions_count;

        return (
            <div className="flex w-full justify-center gap-2">
                <button
                    onClick={() => {
                        handleExportClientTransactions(id, "pdf", transactions);
                    }}
                >
                    <BsFiletypePdf className="" size="22"/>
                </button>
            </div>
        );
    };
    const random_number = new Date().getTime();
    const handleReset = () => {
        setValue("");
        setUpdate(!update);
    };
    const handleDownload = async (paymentId) => {
        if (!isGetPayslip) {
            return;
        }
        try {
            const res = await fetch(`${baseUrl.url}/api/payslip/${paymentId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await res.blob();
            if (res.ok) {
                callToast(true, "Invoice download successfully");
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `payslip_${random_number}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                callToast(false, data?.error);
            }
        } catch (err) {
            callToast(false, err?.message);
        }
    };



    const handleExportData = (type) => {
        const exportData = async () => {
            const res = await fetch(
                `${baseUrl.url}/api/clients/export/all?type=${type}&confirmed=1`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await res.blob();
            if (res.ok) {
                callToast(true, `Confirmed Clients${type} download successfully`);
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `confirmed_clients_${random_number}.${type}`
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                callToast(false, data?.error);
            }
        };
        exportData().then();
    };

    const handleExportClientTransactions = (id, type, counts) => {
        if (counts === 0) {
            callToast(false, "No transactions found");
            return;
        }
        const exportData = async () => {
            const res = await fetch(
                `${baseUrl.url}/api/client-transactions/export?type=${type}&client_id=${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await res.blob();
            if (res.ok) {
                callToast(true, `Transactions ${type} download successfully`);
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `transactions_${random_number}.${type}`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                callToast(false, data?.error);
            }
        };
        exportData().then();
    };


    let items = [
        {
            label: "Export",
            items: [
                {
                    label: "XLSX",
                    icon: <AiOutlineFileExcel/>,
                    command: () => handleExportData("xlsx"),
                },
                {
                    label: "CSV",
                    icon: <BsFiletypeCsv/>,
                    command: () => handleExportData("csv"),
                },
            ],
        },
    ];

    return (
        <div>
            <Toast ref={toast}/>
            <div>
                <div className="flex items-center justify-between">
                    <SectionHeading
                        title={"Confirm Clients"}
                        text={"Confirm & paid clients for ecommerce"}
                    />

                    {isExportClientList && (
                        <div className="flex justify-end">
                            <div className="zero">
                                <Button
                                    icon={<BsThreeDotsVertical className="dark:text-white"/>}
                                    onClick={(event) => menuLeft.current.toggle(event)}
                                    aria-controls="popup_menu_left"
                                    aria-haspopup
                                />
                            </div>
                            <Menu
                                model={items}
                                popup
                                ref={menuLeft}
                                id="popup_menu_left"
                            />
                        </div>
                    )}
                </div>

                {/*Search*/}
                <form className="" onSubmit={handleSearch}>
                        <div className="grid grid-cols-12 mt-3 items-center mb-1 w-full sm:mb-3 gap-2">
                            <div
                                className="col-span-10 lg:col-span-8 xl:col-span-5 border p-1 rounded-sm flex items-center w-full gap-2 bg-white dark:bg-gray-800 dark:border-gray-700">
                                <input
                                    type="search"
                                    defaultValue={search}
                                    className="w-full focus:outline-0 pl-4 bg-white dark:bg-gray-800 dark:text-white"
                                    onChange={(e) =>{
                                        const inputValue = e?.target?.value
                                        if(!inputValue){
                                            navigate(`#page=${searchPage}`)
                                        }
                                        setValue(inputValue)
                                    }}
                                    placeholder="Search by company/name/email/invoice"
                                />
                                <button
                                    type="submit"
                                    className="bg-sky-500 text-white px-2 py-1 lg:px-3 ml-2 dark:bg-transparent "
                                >
                                    Search
                                </button>
                            </div>



                            <div className="col-span-2 lg:col-span-2 xl:col-span-1 flex justify-start">
                                <button
                                    onClick={handleReset}
                                    className="bg-cyan-600 text-white w-10 h-10 flex justify-center items-center rounded-sm"
                                >
                                    <TfiReload className="w-5 h-6"/>
                                </button>
                            </div>
                        </div>
                    </form>


                {/*Data Table*/}

                <DataTable
                    value={confirmClients?.data}
                    sortMode="multiple"
                    tableStyle={{minWidth: "50rem"}}
                    showGridlines={true}
                    key={pageNumber}
                    className="datatable-responsive myTable"
                    emptyMessage="No client found."
                    rowHover={true}
                    loading={loading}
                    lazy
                    paginator={confirmClients?.total > 0 }
                    rows={limitData}
                    totalRecords={confirmClients?.total}
                    first={pageNumber}
                    onPage={(e) => {
                        setLimitData(e.rows);
                        let pages = Math.ceil(e.first / e.rows + 1);
                        navigate(`${value ? `?search=${value}` : ""}#page=${pages}`)
                    }}
                    pageLinkSize={10}
                    paginatorTemplate={paginationTemplate}
                    rowsPerPageOptions={tablePerPageOptions}
                >
                    <Column
                        header="#"
                        headerStyle={{width: "3rem"}}
                        body={(data, options) => options.rowIndex + 1}
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>
                    <Column
                        field="payment_invoice"
                        header="Invoice"
                        sortable
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>

                    <Column
                        field="name"
                        header="Name"
                        sortable
                        className="dark:bg-gray-800 dark:text-white"
                        body={nameTemplate}
                    ></Column>
                    <Column
                        field="company"
                        header="Company Name"
                        sortable
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>
                    <Column
                        header="Domain"
                        body={(data) => <span>{data?.website ? data?.website?.domain : "N/A"}</span>}
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>

                    <Column
                        field="confirmation_date"
                        header="Confirmation Date"
                        body={confirmationDateTemplate}
                        sortable
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>
                    <Column
                        header="Transaction"
                        body={exportTransactionTemplate}
                        className="dark:bg-gray-800 dark:text-white"
                    ></Column>
                    {isGetClientFollowUp ? (
                        <Column
                            header="Follow up"
                            body={followUpTemplate}
                            className="dark:bg-gray-800 dark:text-white"
                        ></Column>
                    ) : null}

                    {isClientUpdate || isGetPayslip ? (
                        <Column
                            field="action"
                            header="Options"
                            body={actionTemplate}
                            className="dark:bg-gray-800 dark:text-white"
                        ></Column>
                    ) : null}
                </DataTable>
            </div>
            {visible && (
                <AddDocumentModal
                    visible={visible}
                    setVisible={setVisible}
                    obj={obj}
                    setObj={setObj}
                    update={update}
                    setUpdate={setUpdate}
                />
            )}
        </div>
    );
};

export default ConfirmedClients;
