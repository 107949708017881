import React from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

const GoBack = () => {
    const navigate = useNavigate()

  return (
    <button className="text-gray-700 flex items-center" onClick={()=>navigate(-1)}><BsArrowLeft className='mr-2'/>Back</button>
  )
}

export default GoBack