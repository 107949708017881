import React from "react";
import "./Pagination.css";
import {
  HiChevronLeft,
  HiChevronRight,
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi2";

const Pagination = ({ data, setUrl, perPageLimit, setPerPageLimit, setPageNo=null, pageNo }) => {
  const pages = data?.links;
  return (
    <div className="overflow-x-auto p-2">
      {data?.data?.length > 0 && (
        <div className="flex items-center gap-1 lg:gap-3 mt-2 overflow-x-auto">
          <p className="font-medium text-sm  whitespace-nowrap dark:text-white">
            {data?.from} - {data?.to} of {data?.total}
          </p>
          <div>
            <select
              onClick={(e) => setPerPageLimit(e.target.value)}
              defaultValue={perPageLimit}
              className="border text-sm p-1 bg-transparent rounded-sm focus:outline-none block  border-gray-300 dark:text-white"
            >
              <option value="5" >5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="70">70</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="flex gap-2 items-center">

            <button
                onClick={() => {
                  setUrl(data?.first_page_url)
                }}
                className={`text-xl font-bold px-2 py-1 border  dark:bg-gray-900 dark:text-white dark:border-0 ${data?.current_page === 1 && "bg-gray-50"}`}
                disabled={data?.current_page === 1 && true}
            >
              <HiOutlineChevronDoubleLeft className="dark:text-white"/>
            </button>
            {pages?.map((d, i) => (
              <div key={i}>
                {i === 0 ? (
                      <button
                        onClick={() => {
                          setUrl(d.url)
                          setPageNo(pageNo - 1)
                        }}
                        className={`text-xl font-bold px-2 py-1 border dark:bg-gray-900 dark:text-white dark:border-0 ${data?.current_page === 1 && `bg-gray-50`}`}
                        disabled={data?.current_page === 1 && true}
                      >
                        <HiChevronLeft className="dark:text-white"/>
                      </button>

                ) : pages?.length - 1 === i ? (
                      <button
                        onClick={() => {
                          setUrl(d.url)
                          setPageNo(pageNo +1)
                        }}
                        className={`text-xl px-2 py-1 border dark:bg-gray-900 dark:text-white dark:border-0 ${
                          data?.current_page === data?.last_page &&
                          `bg-gray-50`
                        }`}
                        disabled={
                          data?.current_page === data?.last_page && true
                        }
                      >
                        <HiChevronRight className="dark:text-white"/>
                      </button>

                ) : (
                  <button
                    className={`cursor-pointer text-md font-medium rounded-sm font-mono px-3 py-1  text-sm  dark:text-white dark:border-0 ${
                      d.active === true ? `bg-sky-500  text-white` : "border dark:bg-gray-900"
                    }`}
                    onClick={() => {
                      console.log("url", d.url)
                      if(!d.url){
                      return
                      }
                      setUrl(d.url)
                      setPageNo(Number(d.label))
                    }}
                  >
                    {d.label}
                  </button>
                )}
              </div>
            ))}

              <button
                onClick={() => {
                  setUrl(data?.last_page_url)
                  setPageNo(data?.last_page)
                }}
                className={`text-xl font-bold px-2 py-1 border dark:bg-gray-900 dark:text-white dark:border-0 ${data?.current_page === data?.last_page && 'bg-gray-50'}`}
                disabled={data?.current_page === data?.last_page && true}
              >
                <HiOutlineChevronDoubleRight  className="dark:text-white"/>
              </button>

          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
