import React from 'react';

const NoContent = ({data}) => {
    return (
        <div className='h-full flex justify-center py-16'>
          <div>
          <div className='flex justify-center'>
           <img className='w-28 h-28' src="https://static.vecteezy.com/system/resources/thumbnails/006/208/684/small/search-no-result-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg" alt="no"/>
           </div>
            <p className='text-center text-xl text-slate-700'>
              {data? `   No ${data} found`: "404 - No Data Found"}
            </p>
          </div>
        </div>
    );
};

export default NoContent;