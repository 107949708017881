import React, { useEffect, useState } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import { baseUrl, checkAvailability } from "../../Helper/helper";
import useData from "../../hooks/useData";
import Pagination from "../Pagination/Pagination";
import Loader from "../Loader/Loader";
import NoContent from "../NoContent/NoContent";

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [url, setUrl] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(1);
  const [loading, setLoading] = useState(false);
  const { authPermissions } = useData().permissions;
  const { token } = useData().store;
  const isGetTransportAllowanceInvoices = checkAvailability(
    authPermissions,
    "transport-allowance-payment-slip"
  );
  useEffect(() => {
    if (!isGetTransportAllowanceInvoices) {
      return;
    }

    const getInvoices = async () => {
      setLoading(true);
      try {
        const res = await fetch(
          `${
            url
              ? url
              : `${baseUrl?.url}/api/allowance-invoices?per_page=${perPageLimit}`
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (res.ok) {
          setInvoices(data?.data);
        }
      } catch (error) {
        console.error(error?.message);
      } finally {
        setLoading(false);
      }
    };
    getInvoices().then();
  }, [
    token,
    authPermissions,
    isGetTransportAllowanceInvoices,
    url,
    perPageLimit,
  ]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <SectionHeading
        title={"Invoices"}
        text={"All transport allowance invoices are detailed bellow"}
      />
      {!loading && (
        <div className="my-4">
          {invoices?.data?.length > 0 ? (
            <>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
                {invoices?.data?.map((invoice, i) => (
                  <div key={i} className="w-full h-full p-3 shadow">
                    <div className="w-full flex justify-center h-40 ">
                      <iframe
                        src={`${baseUrl?.url}${invoice?.url}`}
                        title="invoice"
                      ></iframe>
                    </div>
                    <p
                      className="cursor-pointer text-center py-1 text-lg text-gray-700 font-semibold"
                      onClick={() =>
                        window.open(`${baseUrl?.url}${invoice?.url}`)
                      }
                    >
                      {invoice?.payslip_uuid}
                    </p>
                  </div>
                ))}
              </div>

              <Pagination
                data={invoices}
                url={url}
                setUrl={setUrl}
                perPageLimit={perPageLimit}
                setPerPageLimit={setPerPageLimit}
              />
            </>
          ) : (
            <NoContent data={"invoice"} />
          )}
        </div>
      )}
    </div>
  );
};

export default Invoices;
