import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { baseUrl } from "../../Helper/helper";
import useData from "../../hooks/useData";
import "./SearchedClients.css";
import GoogleMap from "google-maps-react-markers";
import Marker from "./Marker";
import mapOptions from "./map-options.json";
const SearchedClients = () => {
  const mapRef = useRef(null);
  const [report, setReport] = useState([]);
  const { token } = useData().store;
  const [salesPerson, setSalesPerson] = useState([]);
  const [searchedClients, setSearchedClients] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const selectedClientTemplate = (option, props) => {
    if (option) {
      return <div>{option?.name}</div>;
    }
    return <span>{props?.placeholder}</span>;
  };
  const clientOptionTemplate = (option) => {
    return <div>{option.name}</div>;
  };
  useEffect(() => {
    const getSalesPersons = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/api/sales-person`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.status) {
          setSalesPerson(data.data);
        }
      } catch (error) {}
    };
    getSalesPersons().then();
  }, [token]);

  const searchResult = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${baseUrl.url}/api/client-gps-data?user_id=${searchedClients.id}&date=${startDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let resData = await res.json();
      if (resData.status) {
        setReport(resData.data);
      }
    } catch (error) {}
  };

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map;
  };

  const onMarkerClick = (e, { markerId, lat, lng, interest }) => {
    alert(interest);

    // inside the map instance you can call any google-maps method
    mapRef.current.setCenter({ lat, lng });
    // rif. https://developers.google.com/maps/documentation/javascript/reference?hl=it
  };
  return (
    <div>
      <form
        onSubmit={searchResult}
        className="grid grid-cols-12 items-center gap-4 date mb-4"
      >
        <div className="col-span-8">
          <div className="">
            <Dropdown
              value={searchedClients}
              onChange={(e) => setSearchedClients(e.target.value)}
              options={salesPerson}
              optionLabel="name"
              placeholder="Select salesforce"
              filter
              valueTemplate={selectedClientTemplate}
              itemTemplate={clientOptionTemplate}
              className="w-full md:w-14rem"
            />
          </div>
        </div>
        <div className="col-span-2">
          <div className="datepik">
            <input type="date" onChange={(e) => setStartDate(e.target.value)} />
          </div>
        </div>
        <div className="col-span-2">
          <input
            type="submit"
            value={"Search"}
            className="py-2 px-4 bg-blue-500 text-white w-full"
          />
        </div>
      </form>
      <div className="grid grid-cols-12 gap-4">
        <div className={report.length > 0 ? "col-span-10" : "col-span-12"}>
          <GoogleMap
            apiKey="AIzaSyBwhrTgTkNHAoeXcPVaJZ8TZUoDqINXFmI"
            defaultCenter={{ lat: 23.7469302, lng: 90.3947394 }}
            defaultZoom={20}
            options={mapOptions}
            mapMinHeight="85vh"
            onGoogleApiLoaded={onGoogleApiLoaded}
          >
            {report?.map(
              ({ latitude, longitude, name, interest_status }, index) => (
                <Marker
                  key={index}
                  lat={+latitude}
                  lng={+longitude}
                  markerId={name}
                  interest={interest_status}
                  onClick={onMarkerClick}
                />
              )
            )}
          </GoogleMap>
        </div>
        {report?.length > 0 && (
          <div className="col-span-2">
            <div className="p-2">
              <h1>
                <span>{report?.length}</span> result found
              </h1>
              <div className="grid grid-cols-12">
                <div className="col-span-2">
                  SL
                </div>
                <div className="col-span-7">
                  Name
                </div>
                <div className="col-span-3">
                  Interest
                </div>
              </div>
              {report?.map(({ name, interest_status }, index) => (
                <div className="mb-1 grid grid-cols-12 items-center">
                  <div className="col-span-2">
                    <div className="flex items-center justify-start">
                      <span>
                        {index + 1 > 0 && index + 1 < 9 && 0}
                        {index + 1}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-8">
                    <h1 className="text-sm">
                      <span className="font-bold">Name</span> : {name}
                    </h1>
                  </div>
                  <div className="col-span-2">
                    <span
                      className={`font-bold ${
                        +interest_status >= 0 && +interest_status <= 60
                          ? "text-red-500"
                          : +interest_status > 60 && +interest_status <= 90
                          ? "text-yellow-500"
                          : "text-blue-500"
                      }`}
                    >
                      {interest_status}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchedClients;
