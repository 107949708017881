import React, { useEffect, useRef, useState } from "react";
import GoBack from "../GoBack/GoBack";
import { useParams } from "react-router-dom";
import { baseUrl, checkAvailability } from "../../Helper/helper";
import useData from "../../hooks/useData";
import { AiOutlineEye, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineDownload } from "react-icons/hi";
import { Toast } from "primereact/toast";
import { TbWorldWww } from "react-icons/tb";
import DataLoader from "../DataLoader/DataLoader";
import SectionHeading from "../SectionHeading/SectionHeading";

const ConfirmedClientDetails = () => {
  const { id } = useParams();
  const { token } = useData().store;
  const { authPermissions } = useData().permissions;
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const isGetPayslip = checkAvailability(authPermissions, "get-payslip");
  const IsGetClient = checkAvailability(authPermissions, "get-client-info");
  const toast = useRef();
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    if (!IsGetClient && !id) {
      return;
    }
    const getClientDetails = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${baseUrl.url}/api/clients/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setDetails(data?.data);
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    };
    getClientDetails().then();
  }, [token, IsGetClient, id]);

  const modifyDate = (data) => {
    return new Date(data)?.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  };
  const random_number = new Date().getTime();
  const handleDownload = async () => {
    if (!isGetPayslip) {
      return;
    }
    try {
      const res = await fetch(
        `${baseUrl.url}/api/payslip/${details?.payment?.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.blob();
      if (res.ok) {
        callToast(true, "Invoice download successfully");
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `payslip_${random_number}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        callToast(false, data?.error);
      }
    } catch (err) {
      callToast(false, err?.message);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex items-center justify-between">
        <SectionHeading
          title={"Confirmed Client"}
          text={"Confirmed client details are detailed bellow"}
        />
        <GoBack />
      </div>
      {loading ? (
        <DataLoader />
      ) : (
        <div className="mt-3">
          <p className="text-xl font-medium dark:text-white">{details?.name}</p>
          <p className="font-semibold text-lg dark:text-white">
            Company: {details?.company}
          </p>
          <p className=" text-base dark:text-white">
            Product Type: {details?.product_type}
          </p>
          <p className="flex items-center text-base gap-2 dark:text-white">
            <AiOutlineMail /> {details?.email}
          </p>
          <p className="flex items-center text-base gap-2 dark:text-white">
            <AiOutlinePhone /> {details?.phone_no}
          </p>
          <p className="flex items-center text-base  gap-2 dark:text-white">
            <IoLocationOutline className="dark:text-white" /> {details?.area}
          </p>
          {details?.website?.id && (
            <p className="flex items-center text-base dark:text-white gap-2">
              <TbWorldWww />{" "}
              <a
                className="text-blue-600"
                href={details?.website?.domain}
                target="blank"
              >
                {details?.website?.domain}
              </a>
            </p>
          )}
          <div>
            <a
              href={`${baseUrl?.url}${details?.document}`}
              target="blank"
              className="text-blue-500 text-base flex gap-1 items-center"
            >
              <AiOutlineEye size="20" />
              View Document
            </a>
          </div>
          <div className="w-full lg:w-10/12 xl:w-9/12 2xl:w-1/2 mt-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {details?.added_by?.id && (
              <div>
                <p className="font-semibold text-gray-700 dark:text-white">
                  Added By
                </p>
                <p className="text-sky-500">{details?.added_by?.name}</p>
              </div>
            )}
            <div>
              <p className="font-semibold text-gray-700 dark:text-white">
                Registered Date
              </p>
              <p className="text-sky-500">
                {modifyDate(details?.created_at?.split("T")[0])}
              </p>
            </div>
            <div>
              <p className="font-semibold text-gray-700 dark:text-white">
                Confirmation Date
              </p>
              <p className="text-sky-500">
                {modifyDate(details?.confirmation_date?.split(" ")[0])}
              </p>
            </div>
          </div>

          <div className="mt-2">
            <h1 className="text-slate-800 text-xl font-semibold dark:text-white">
              Payment Information
            </h1>
            <div className="w-full lg:w-10/12 xl:w-9/12 2xl:w-1/2 mt-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
              <div>
                <p className="font-semibold text-gray-700 dark:text-white">
                  Category
                </p>
                <p className="text-sky-500 whitespace-nowrap">
                  {details?.payment?.service?.name}
                </p>
              </div>

              <div>
                <p className="font-semibold text-gray-700 dark:text-white whitespace-nowrap">
                  Payment Type
                </p>
                <p className="text-sky-500">{details?.payment?.type?.name}</p>
              </div>

              {details?.payment?.transaction_id && (
                <div>
                  <p className="font-semibold text-gray-700 dark:text-white whitespace-nowrap">
                    Transaction Number
                  </p>
                  <p className="text-sky-500">
                    {details?.payment?.transaction_id}
                  </p>
                </div>
              )}
              <div>
                <p className="font-semibold text-gray-700 dark:text-white">
                  Amount
                </p>
                <p className="text-sky-500">{details?.payment?.amount}</p>
              </div>
              <div>
                <p className="font-semibold text-gray-700 dark:text-white">
                  Invoice
                </p>
                <p className="text-sky-500 whitespace-nowrap">
                  {details?.payment?.invoice_no}
                </p>
              </div>
            </div>
            <button
              onClick={handleDownload}
              className="bg-sky-600 text-white px-5 py-2 mt-3 flex items-center gap-1"
            >
              <HiOutlineDownload size="23" /> Invoice
            </button>
          </div>
          <div className="mt-2">
            <div>
              <p className="text-slate-700 font-semibold dark:text-white">
                Client Opinion
              </p>
              <p className="dark:text-white">{details?.client_opinion}</p>
            </div>
            <div>
              <p className="text-slate-700 font-semibold dark:text-white">
                Officer Opinion
              </p>
              <p className="dark:text-white">{details?.officer_opinion}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmedClientDetails;
