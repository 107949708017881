import React, { useEffect, useState } from 'react';
import useData from '../../hooks/useData';
import { baseUrl, checkAvailability } from '../../Helper/helper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../Loader/Loader';
import NoContent from '../NoContent/NoContent';

const FollowupReminder = () => {
    const { token } = useData().store;
    const [loading, setLoading] = useState(false)
    const [upcomingFollowUps, setUpcomingFollowups] = useState([])
    const { authPermissions } = useData().permissions;
    const [expandedRows, setExpandedRows] = useState(null);
    const { profile } = useData().profile;
    const roleId = profile?.roles[0]?.id
    const isGetPendingFollowup = checkAvailability(
        authPermissions,
        "get-pending-client-follow-up"
      );

    useEffect(() => {
        if (!isGetPendingFollowup) {
          return;
        }
        const getFollowups = async () => {
          setLoading(true);
          try {
            const res = await fetch(`${`${baseUrl?.url}/api/pending-follow-ups`}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            const data = await res.json();
            if (res.ok) {
                setUpcomingFollowups(data?.data);
            }
          } 
          catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        };
        getFollowups().then();
      }, [token, isGetPendingFollowup]);

      const allowExpansion = (rowData) => {
        return rowData;
      };

      const rowExpansionTemplate = (data) => {
        let expandData=[];
        expandData.push(data)
  
          return (
              <div className="p-3">
                  <p className='text-lg font-semibold'>Client Information</p>
                <div>
                  <p>Name: {data?.client?.name}</p>
                  <p>Email: {data?.client?.email}</p>
                  <p>Phone: {data?.client?.phone_no}</p>
                </div>
                <p className='text-lg font-semibold'>Description</p>
             <p>{data?.notes || "N/A"}</p>
              </div>
          );
      };

const dateTemplate = (data) => {
   const dateObj = new Date(data?.followup_session);
   const formattedDate = dateObj.toLocaleDateString("en-US", {
     day: "numeric",
     month: "long",
     year: "numeric",
     hour: 'numeric',
     minute: 'numeric',
   })
   return <p>{formattedDate}</p>
}

    return (
        <div>
            <div className="">
              <h1 className="text-base text-sky-500 font-semibold">Pending Followup</h1>
              <p className="text-slate-600 text-sm dark:text-white">
               All upcoming followups are detailed below
              </p>
            </div>
               {
                loading ? <Loader/> : <>
                    {
                upcomingFollowUps?.length > 0 ?
                 <div className='mt-4'>
                   <DataTable
                value={upcomingFollowUps}
                expandedRows={expandedRows} 
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="id" 
                size="small"
                sortMode="multiple"
              >
                <Column expander={allowExpansion} style={{ width: '5rem' }} className="dark:bg-gray-800 dark:text-white" />
                <Column
                  header="#"
                  headerStyle={{ width: "3rem" }}
                  body={(data, options) => options?.rowIndex + 1}
                  className="dark:bg-gray-800 dark:text-white"
                ></Column>
                <Column field="followup_session" header="Date" className="dark:bg-gray-800 dark:text-white" body={dateTemplate}></Column>
                <Column field="client.name" header="Client" className="dark:bg-gray-800 dark:text-white"></Column>
               {
                roleId === 1 &&  <Column field="added_by_info.name" header="Added by" className="dark:bg-gray-800 dark:text-white"></Column>
               }
            
              </DataTable>
                </div>
                :
                <NoContent data="pending followup"/>
              }
                </>
               }
        
        </div>
    );
};

export default FollowupReminder;