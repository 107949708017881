import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { baseUrl, checkAvailability } from "../../../Helper/helper";
import { useNavigate, useParams } from "react-router-dom";
import useData from "../../../hooks/useData";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import GoBack from "../../GoBack/GoBack";
import SectionHeading from "../../SectionHeading/SectionHeading";

const AddUser = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [image, setImage] = useState();
  const [roleId, setRoleId] = useState();
  const { id } = useParams();
  const { token } = useData().store;
  const { authPermissions } = useData().permissions;
  const isCreateUser = checkAvailability(authPermissions, "create-user");
  const isUpdateUser = checkAvailability(authPermissions, "update-user");
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  // const [incentiveData, setIncentiveData] = useState(null)

  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };

  //get user by id
  useEffect(() => {
    if (!id) {
      setUser(null);
    }
    else {
      const isGetUserInfo = checkAvailability(authPermissions, "get-user-info");
      if (!isGetUserInfo) {
        return;
      }
      const UserById = async () => {
        try {
          const res = await fetch(`${baseUrl.url}/api/users/${id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await res.json();
          if (res.ok) {
            setUser(data?.data);
            setRoleId(data?.role?.id);
            if (data?.data?.employee) {
              setIsEmployee(true);
            } else {
              setIsEmployee(false);
            }
          }
        } catch (error) {
          callToast(false, error?.message);
        }
      };
      UserById().then();
    }
  }, [token, id, authPermissions]);
  const [roleList, setRoleList] = useState(false);

  //get role
  useEffect(() => {
    const IsGetRoleList = checkAvailability(authPermissions, "get-role-list");
    if (!IsGetRoleList) {
      return;
    }
    const roles = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/api/roles`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (res.ok) {
          setRoleList(data?.data);
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    roles().then();
  }, [token, authPermissions]);

  //Image show
  const handleImage = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setImage(fileReader.result);
      };
    }
  };

  // post and put user
  const onSubmit = async (data) => {
    const formData = new FormData();
    if (!user?.id) {
      data.is_employee = isEmployee ? 1 : 0;
      formData.append("is_employee", isEmployee ? 1 : 0);
    }
    for (const d in data) {
      if (user?.id) {
        if (!data[d]) {
          if (d === "role_id") {
            data[d] = roleId;
          } else {
            data[d] = user[d];
          }
        }
        delete data.password;
        delete data.confirm_password;
      }
    }

    for (let i in data) {
      const element = data[i];
      if (element) {
        if (i === "avatar") {
          const file = element[0];
          if (file) {
            formData.append(`${i}`, file);
            delete data.avatar;
          } else {
            delete data.avatar;
          }
        } else if (i === "document") {
          const file = element[0];
          if (file) {
            formData.append(`${i}`, file);
            delete data.document;
          } else {
            delete data.document;
          }
        } else {
          formData.append(i, element);
        }
      }
    }

    if (user?.id) {
      if (!isUpdateUser) {
        return;
      }
      try {
        const res = await fetch(`${baseUrl.url}/api/users-update/${user?.id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "User Updated Successfully");
          setTimeout(() => {
            navigate("/user");
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
      } catch (error) {
        callToast(false, error?.message);
      }
    } else {
      if (!isCreateUser) {
        return;
      }
      if (data?.password === data?.confirm_password) {
        try {
          const res = await fetch(`${baseUrl.url}/api/users`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });
          const resData = await res.json();
          if (res.ok) {
            callToast(true, "User Added Successfully");
            setTimeout(() => {
              navigate("/user");
            }, 400);
          } else {
            callToast(false, resData?.error);
          }
        } catch (error) {
          callToast(false, error?.message);
        }
      } else {
        callToast(false, "Two password didn't match");
      }
    }
  };

  return (
    <div className="w-full">
      {/* <GoBack/> */}
      <Toast ref={toast} />
      <div className="w-full">
        <div className="flex items-center justify-between">
          <SectionHeading title={user?.id ? "Update User" : "Add A User"} text={"Add user/admin, permissions and roles"}/>
          <GoBack />
        </div>

        <form className="w-full mt-4 " onSubmit={handleSubmit(onSubmit)}>
          <div>
            {user?.id && (
              <img
                className="w-24 h-24 rounded-sm"
                src={`${image ? image : `${baseUrl?.url}${user?.avatar}`}`}
                alt="user"
              />
            )}
          </div>
          <div className="flex gap-2 my-3 mt-5">
            {/* <label>Upload Image: </label> */}
            <input
              {...register("avatar", {
                required: {
                  value: !user?.id && true,
                  message: "Image is Required",
                },
              })}
              onChange={(e) => handleImage(e)}
              type="file"
              multiple
              className="dark:bg-gray-700 dark:text-white"
            />
          </div>
          {errors.avatar?.type === "required" && (
            <span className="label-text-alt text-xs text-red-600 block">
              {errors.avatar.message}
            </span>
          )}

          <div className="my-3">
            <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
              Name
            </label>
            <input
              type="text"
              className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
              placeholder="Name"
              defaultValue={user?.id && user?.name}
              {...register("name", {
                required: {
                  value: !user?.id && true,
                  message: "Name is Required",
                },
              })}
            />
            {errors.name?.type === "required" && (
              <span className="label-text-alt text-xs text-red-600">
                {errors.name.message}
              </span>
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 lg:gap-2 items-center my-3">
            <div>
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                Email
              </label>
              <input
                type="email"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                placeholder="Email Address"
                defaultValue={user?.id && user?.email}
                {...register("email", {
                  required: {
                    value: !user?.id && true,
                    message: "Email Address is required",
                  },
                })}
                autoComplete="new-email"
              />

              {errors.email?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div>
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                Phone Number
              </label>
              <input
                type="number"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                placeholder="Phone Number"
                defaultValue={user?.id && user?.phone}
                {...register("phone", {
                  required: {
                    value: !user?.id && true,
                    message: "Phone is Required",
                  },
                })}
              />
              {errors.phone?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.phone.message}
                </span>
              )}
            </div>
          </div>

          <div className="my-3">
            <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
              Role
            </label>
            <select
              // defaultValue={role}
              // onChange={(e) => setRole(e.target.value)}
              className="w-full border-b focus:outline-none focus:border-blue-400 p-2 bg-transparent text-gray-600 dark:bg-gray-700 dark:text-white"
              {...register("role_id", {
                required: {
                  value: !user?.id && true,
                  message: "Role is required",
                },
              })}
            >
              <option className="text-gray-200 dark:text-white" value="">
                Select role
              </option>

              {roleList?.length > 0 &&
                roleList?.map((newRole, i) => (
                  <option
                    key={i}
                    value={newRole?.id}
                    selected={newRole?.id === roleId && true}
                  >
                    {newRole?.name}
                  </option>
                ))}
            </select>
            {errors.role_id?.type === "required" && (
              <span className="label-text-alt text-xs text-red-600">
                {errors.role_id.message}
              </span>
            )}
          </div>
          <div>
            {!id && (
              <p className="flex gap-2 items-center">
                {" "}
                <input
                  type="checkbox"
                  onChange={(e) => setIsEmployee(e.target.checked)}
                  checked={isEmployee}
                />
                <span className="text-gray-600 font-medium text-base dark:text-white">
                  Is Employee
                </span>
              </p>
            )}

            {isEmployee && (
              <div>
                <div>
                  <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                    Joining Date
                  </label>
                  <input
                    type="date"
                    defaultValue={
                      user?.employee &&
                      new Date(user?.employee?.joining_date.split(" ")[0])
                        .toISOString()
                        .substr(0, 10)
                    }
                    className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white date-input"
                    {...register("joining_date", {
                      required: {
                        value: !user?.id && true,
                        message: "Joining date is Required",
                      },
                    })}
                  />
                  {errors.joining_date?.type === "required" && (
                    <span className="label-text-alt text-xs text-red-600">
                      {errors.joining_date.message}
                    </span>
                  )}
                </div>

                <div>
                  <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                    Salary
                  </label>
                  <input
                    type="number"
                    className="w-full border-b focus:outline-none focus:border-blue-400 p-2 bg-transparent dark:bg-gray-700 dark:text-white"
                    placeholder="Salary"
                    defaultValue={user?.id && user?.employee?.salary}
                    {...register("salary", {
                      required: {
                        value: !user?.id && true,
                        message: "Salary is Required",
                      },
                    })}
                  />
                  {errors.salary?.type === "required" && (
                    <span className="label-text-alt text-xs text-red-600">
                      {errors.salary.message}
                    </span>
                  )}
                </div>

                <div>
                  <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                    General Kpi
                  </label>
                  <input
                    type="number"
                    className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                    placeholder="General kpi"
                    defaultValue={user?.id && user?.employee?.general_kpi}
                    {...register("general_kpi", {
                      required: {
                        value: !user?.id && true,
                        message: "General kpi is Required",
                      },
                    })}
                  />
                  {errors.general_kpi?.type === "required" && (
                    <span className="label-text-alt text-xs text-red-600">
                      {errors.general_kpi.message}
                    </span>
                  )}
                </div>
                {/* <div>
            <label className="text-gray-500 text-sm p-2 font-semibold">Incentive Kpi</label>
              <input
                type="number"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2"
                placeholder="Incentive kpi"
                defaultValue={user?.id && user?.phone}
                {...register("incentive_kpi", {
                  required: {
                    value: !user?.id && incentiveData && true,
                    message: "Incentive kpi is Required",
                  },
                })}
                onChange={(e) => setIncentiveData(e.target.value)}
              />
              {errors.incentive_kpi?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.incentive_kpi.message}
                </span>
              )}
            </div>
            <div>
            <label className="text-gray-500 text-sm p-2 font-semibold">Incentive Bonus</label>
              <input
                type="number"
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2"
                placeholder="Incentive bonus"
                defaultValue={user?.id && user?.phone}
                {...register("incentive_bonus", {
                  required: {
                    value: !user?.id && incentiveData && true,
                    message: "Incentive bonus is Required",
                  },
                })}
                onChange={(e) => setIncentiveData(e.target.value)}
              />
              {errors.incentive_bonus?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.incentive_bonus.message}
                </span>
              )}
            </div> */}
              </div>
            )}
          </div>
          <div className="my-3">
            <div className="flex items-center gap-2">
              {" "}
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                Document:
              </label>{" "}
              <input
                {...register("document", {
                  required: {
                    value: !user?.id ? isEmployee && true : false,
                    message: "Document is Required",
                  },
                })}
                onChange={(e) => handleImage(e)}
                type="file"
                className="dark:bg-gray-700 dark:text-white"
              />
            </div>
            {errors.document?.type === "required" && (
              <span className="label-text-alt text-xs text-red-600">
                {errors.document.message}
              </span>
            )}
          </div>
          <div className="my-3">
            <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
              Address
            </label>
            <input
              type="text"
              className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
              placeholder="Address"
              defaultValue={user?.id && user?.address}
              {...register("address")}
            />
          </div>
          <div className="my-3">
            <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
              Details
            </label>
            <textarea
              className="w-full border focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
              placeholder="Details"
              defaultValue={user?.id && user?.details}
              {...register("details")}
              rows={3}
            />
          </div>

          {!user?.id && (
            <div className="my-3 relative">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                Password
              </label>
              <input
                type={showPass ? "text" : "password"}
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white"
                placeholder="Password"
                {...register("password", {
                  required: {
                    value: !user?.id && true,
                    message: "Password is Required",
                  },
                })}
                autoComplete="new-password"
              />
              <div
                onClick={() => setShowPass(!showPass)}
                className="absolute right-2 bottom-2 cursor-pointer"
              >
                {!showPass ? (
                  <BsEyeSlash className="dark:text-white" />
                ) : (
                  <BsEye className="dark:text-white" />
                )}
              </div>
              {errors.password?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.password.message}
                </span>
              )}
            </div>
          )}
          {!user?.id && (
            <div className="my-3 relative">
              <label className="text-gray-500 text-sm p-2 font-semibold dark:text-white">
                Confirm password
              </label>
              <input
                type={showConfirmPass ? "text" : "password"}
                className="w-full border-b focus:outline-none focus:border-blue-400 p-2 dark:bg-gray-700 dark:text-white "
                placeholder="Confirm Password"
                {...register("confirm_password", {
                  required: {
                    value: !user?.id && true,
                    message: "Confirm Password is Required",
                  },
                })}
              />
              <div
                onClick={() => setShowConfirmPass(!showConfirmPass)}
                className="absolute right-2 bottom-2 cursor-pointer"
              >
                {!showConfirmPass ? (
                  <BsEyeSlash className="dark:text-white" />
                ) : (
                  <BsEye className="dark:text-white" />
                )}
              </div>
              {errors.confirm_password?.type === "required" && (
                <span className="label-text-alt text-xs text-red-600">
                  {errors.confirm_password.message}
                </span>
              )}
            </div>
          )}
          <div>
            <Button
              size="small"
              severity="info"
              label={user?.id ? "Update" : "Submit"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
