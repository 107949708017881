import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';



const ChartFour = ({ allowances, isDarkMode}) => {
  //chart data get
    const clientsData = allowances?.map(item => item.paid_allowance_total);
    const confirmedClientsData = allowances?.map(item => item.unpaid_allowance_total);
    const monthsData = allowances?.map(item => item.month);
//max axis findings
    const maxClientsValue = Math.max(...clientsData);
const maxConfirmedClientsValue = Math.max(...confirmedClientsData);
const maxYAxisValue = Math.max(maxClientsValue, maxConfirmedClientsValue);
    
    const options = {
      theme: {
        mode: isDarkMode ? "dark" : "light",
      },
        series: [
            {
              name: "Paid",
              data: clientsData
            },
            {
              name: "Unpaid",
              data: confirmedClientsData
            }
          ],
        legend: {
          show: false,
          position: 'top',
          horizontalAlign: 'left',
        },
        colors: ['#3C50E0', '#80CAEE'],
        chart: {
          fontFamily: 'Satoshi, sans-serif',
          height: 335,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#623CEA14',
            top: 10,
            blur: 4,
            left: 0,
            opacity: 0.1,
          },
      
          toolbar: {
            show: true,
          },
        },
        responsive: [
          {
            breakpoint: 1024,
            options: {
              chart: {
                height: 400,
              },
            },
          },
          {
            breakpoint: 1366,
            options: {
              chart: {
                height: 350,
              },
            },
          },
        ],
        stroke: {
          width: [2, 5],
          curve: 'smooth',
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], 
              opacity: 0.5
            },
          },
        dataLabels: {
          enabled: true,
        },
        markers: {
          size: 4,
          colors: '#fff',
          strokeColors: ['#3056D3', '#80CAEE'],
          strokeWidth: 3,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          hover: {
            size: undefined,
            sizeOffset: 5,
          },
        },
        xaxis: {
          type: 'category',
          categories:monthsData,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          title: {
            style: {
              fontSize: '0px',
            },
          },
          min: 0,
          max: maxYAxisValue, 
        },
        tooltip: {
          theme: "dark",
          style: {
            background: "#FF5733",
            color: "#ffffff",
          },
        },
      };


    const [series] = useState([
        {
          name: 'Paid',
          data: clientsData,
        },
        {
          name: 'Unpaid',
          data: confirmedClientsData,
        },
      ]);

  return (
    <div className=" rounded border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default sm:px-7.5 dark:bg-gray-900 dark:text-white dark:border-none">
      <h4 className="flex items-center justify-center font-bold text-xl dark:text-white pt-2 text-gray-800 ">Employee Allowances</h4>

      <div>
      <div id="chartOne" className="-ml-5">
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={350}
            className="dark:bg-gray-900 dark:text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default ChartFour;
