import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import useData from "../../../hooks/useData";
import {
  baseUrl,
  checkAvailability,
  handleExportData,
  paginationTemplate,
  tablePerPageOptions
} from "../../../Helper/helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {useLocation, useNavigate} from "react-router-dom";
import { Toast } from "primereact/toast";
import SectionHeading from "../../SectionHeading/SectionHeading";

const Bills = () => {
  // Get data from context
  const { token } = useData().store;
  const { authPermissions } = useData().permissions;

  const toast = useRef();
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bills, setBills] = useState([]);
  const [expandedRows, setExpandedRows] = useState( []);

  //Pagination
  const [limitData, setLimitData] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  // Check Permissions
  const isGetBills = checkAvailability(authPermissions, "get-bills");

  const webLocation = useLocation();
  const searchPage = webLocation?.search?.split("=")[1];

  // Set default page query 1
  useEffect(() => {
    if(!searchPage){
      navigate(`?page=1`)
    }
    else{
      const calculatePage = (searchPage * limitData)-limitData
      setPageNumber(calculatePage)
    }

  }, [searchPage, navigate, limitData]);


  // Get Custom Bills Data
  useEffect(() => {
    if (!isGetBills) {
      return;
    }
    const getBills = async () => {
      try {
        setLoading(true);
        const res = await fetch(`${baseUrl.url}/api/bills?page=${searchPage}&per_page=${limitData}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.ok) {
          if (res.status === 200) {
            const resData = await res.json();
            setBills(resData?.data);
          } else {
            setBills([])
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getBills().then();
  }, [token, isGetBills, limitData, searchPage]);

  // Menu right items array
  let items = [
    {
      label: "Create Bill",
      icon: <AiOutlinePlus />,
      command: () => {
        navigate("/create-bill");
      },
    },
  ];

  // Date Template
  const dateTemplate = (data) => {
    const dateObj = new Date(data?.created_at);
    const formattedDate = dateObj.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return <p>{formattedDate}</p>;
  };

  // Table action template
  const actionTemplate = (data) => {
    return (
        <div>
          <Button icon="pi pi-file-pdf" size={"small"} tooltip="Download payslip" tooltipOptions={{ position: 'top' }}
                  onClick={() => handleExportData("bills/slip", token, data?.id, toast, "bills", "Payslip download successfully")}/>
        </div>
    );
  };

  // Allow expansion function
  const allowExpansion = (rowData) => {
    return rowData;
  };

  // Table row expansion template
  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <p className="text-lg font-semibold">Services</p>
        <DataTable value={data.services}>
          <Column
            field="service.name"
            header="Service"
            className="dark:bg-gray-800 dark:text-white"
          ></Column>
          <Column
            field="service.price"
            header="Price"
            className="dark:bg-gray-800 dark:text-white"
          ></Column>
  
        </DataTable>

        {data?.remarks && (
          <div>
            <label className="text-gray-500 text-sm py-2 font-semibold">
              Notes
            </label>
            <p>{data?.remarks}</p>
          </div>
        )}
      </div>
    );
  };

  // Amount Template
  const AmountTemplate = (data) => {
    const total = data?.services?.reduce((acc, service) => {
      return acc + service?.service?.price
    }, 0)
    return <p>৳ {total}</p>
  }


  return (
    <div>
      <Toast ref={toast} />
      <div className="grid grid-cols-12 justify-between">
        <div className="col-span-6">
          <SectionHeading title={"Bills"} text={"Bills are detailed bellow"} />
        </div>
        <div className="col-span-6 flex justify-end">
          <div className="zero">
            <Button
              icon={<BsThreeDotsVertical className="dark:text-white" />}
              className="dark:bg-gray-800 "
              onClick={(event) => menuLeft.current.toggle(event)}
              aria-controls="popup_menu_left"
              aria-haspopup
            />
          </div>
          <Menu
            model={items}
            popup
            ref={menuLeft}
            id="popup_menu_left"
            className="dark:bg-gray-800 "
          />
        </div>
      </div>

        <div className="mt-4">
          <DataTable
            value={bills?.data}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            size="small"
            sortMode="multiple"
            className="datatable-responsive myTable"
            emptyMessage="No bill found."
            rowHover={true}
            loading={loading}
            lazy
            paginator={bills?.total > 0 || false}
            rows={limitData}
            totalRecords={bills?.total || 0}
            first={pageNumber}
            onPage={(e) => {
              setPageNumber(e.first);
              setLimitData(e.rows);
              let pages = Math.ceil(e.first / e.rows + 1);
              navigate(`?page=${pages}`)

            }}
            paginatorTemplate={paginationTemplate}
            pageLinkSize={10}
            rowsPerPageOptions={tablePerPageOptions}
          >
            <Column
              expander={allowExpansion}
              style={{ width: "5rem" }}
              className="dark:bg-gray-800 dark:text-white"
            />
            {/*<Column*/}
            {/*  header="#"*/}
            {/*  headerStyle={{ width: "3rem" }}*/}
            {/*  body={(data, options) => options?.rowIndex + 1}*/}
            {/*  className="dark:bg-gray-800 dark:text-white"*/}
            {/*></Column>*/}
            <Column
              field="bill_no"
              header="Bill No"
              className="dark:bg-gray-800 dark:text-white"
            ></Column>
            <Column
              field="client.name"
              header="Client"
              className="dark:bg-gray-800 dark:text-white"
            ></Column>
            <Column
              header="Date"
              className="dark:bg-gray-800 dark:text-white"
              body={dateTemplate}
            ></Column>
            <Column
              header="Total Amount"
              className="dark:bg-gray-800 dark:text-white"
              body={AmountTemplate}
            ></Column>
            <Column
              header="Action"
              className="dark:bg-gray-800 dark:text-white"
              body={actionTemplate}
            ></Column>
          </DataTable>
        </div>
    </div>
  );
};

export default Bills;
