import React, { useEffect, useState } from "react";
import { BsClock } from "react-icons/bs";
import useData from "../../hooks/useData";
// import { Link } from "react-router-dom";

import { FiUserCheck, FiUsers } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import { MdFollowTheSigns, MdMiscellaneousServices } from "react-icons/md";
import { baseUrl } from "../../Helper/helper";
import { RiUserStarLine } from "react-icons/ri";
import ChartOne from "../../Components/Charts/ChartOne";
import ChartTwo from "../../Components/Charts/ChartTwo";
import ChartThree from "../../Components/Charts/ChartThree";
import ChartFour from "../../Components/Charts/ChartFour";
import { useNavigate } from "react-router-dom";
import ChartFive from "../../Components/Charts/ChartFive";

const Home = () => {
  const [dateState] = useState(new Date());
  const [dashboardData, setDashboardData] = useState();
  const { token, isDarkMode } = useData().store;
  //Dasboard
  useEffect(() => {
    const dashboard = async () => {
      try {
        const res = await fetch(`${baseUrl.url}/api/dashboard`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await res.json();
        if (res.ok) {
          setDashboardData(resData?.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    dashboard().then();
  }, [token]);


  const navigate = useNavigate();
  const items = [
    {
      id: 0,
      name: "Employees",
      total: dashboardData?.total_employees,
      bg: "bg-teal-400",
      color: "text-teal-700",
      border: "border-teal-600",
      icon: <FiUsers size={25} />,
      route: "/employee",
    },
    {
      id: 1,
      name: "Clients",
      total: dashboardData?.total_client,
      bg: "bg-sky-400",
      color: "text-sky-700",
      border: "border-sky-600",
      icon: <FaUsers size={25} />,
      route: "/clients",
    },
    {
      id: 2,
      name: "Confirm Clients",
      total: dashboardData?.total_confirmed_client,
      bg: "bg-indigo-400",
      color: "text-indigo-700",
      border: "border-red-400",
      icon: <FiUserCheck size={25} />,
      route: "/confirm-clients",
    },
    {
      id: 3,
      name: "Services",
      total: dashboardData?.services,
      route: "/services",
      icon: <MdMiscellaneousServices size={25} />,
    },
    {
      id: 4,
      name: "Star Employee",
      total: dashboardData?.star_employee?.data?.username || 0,
      icon: <RiUserStarLine size={25} />,
      route: "/employee",
    },
    {
      id: 5,
      name: "Pending Followup",
      total: dashboardData?.pending_followups,
      bg: "bg-orange-400",
      color: "text-orange-700",
      border: "border-orange-600",
      route: "/pending/followup",
      icon: <MdFollowTheSigns size={25} />,
    },
  ];

  //CURRENT DATE
  const todayDate = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Array containing day names
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get today's date
    const today = new Date();

    // Get the day, month, and year
    const dayOfWeek = days[today.getDay()];
    const dayOfMonth = today.getDate();
    const month = months[today.getMonth()];
    const year = today.getFullYear();

    // Format the date string
     return `${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;
  };

  return (
    <div className="">
      <div className=" mx-auto grid grid-cols-12  lg:mx-0">
        <div className="col-span-12">
          <div className="flex items-center justify-between p-2">
            <div>
              <h2 className="text-lg font-bold tracking-tight text-slate-700 dark:text-white">
                Hello <span className="text-sky-500">Admin</span>
              </h2>
              <p className="dark:text-white text-xs">Today is {todayDate()}</p>
            </div>
            <div className="flex gap-1 items-center">
              <BsClock className="text-sky-600 dark:text-sky-500" />
              <p className="font-bold tracking-tight text-slate-700 dark:text-white">
                {dateState.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="grid md:grid-cols-2 xl:grid-cols-6 lg:gap-4 mx-auto mt-4  grid-cols-2 gap-4">
        {items.map((item, i) => (
          <div
            key={i}
            className={`flex items-center p-4 bg-white shadow rounded-lg dark:bg-gray-900 cursor-pointer `}
            onClick={() => navigate(`${item?.route}`)}
          >
            <div
              className={`inline-flex flex-shrink-0 items-center justify-center h-10 w-10 text-white ${item?.bg} rounded-full mr-6`}
            >
              {item?.icon}
            </div>
            <div>
              <span className="block text-sm lg:text-base  text-gray-500 dark:text-white">
                {item?.name}
              </span>
              <span className="block text-lg lg:text-2xl font-bold dark:text-white">
                {item?.total}
              </span>
            </div>
          </div>
        ))}
      </section>

      <div className="grid grid-cols-12 mt-4 gap-4 mx-auto">
        <div className="lg:col-span-8 col-span-12">
          {dashboardData?.monthly_client_data?.length ? (
            <ChartOne clients={dashboardData?.monthly_client_data} isDarkMode={isDarkMode}/>
          ) : (
            <p className="flex items-center justify-center dark:text-white">
              Client Chart is not available at this moments
            </p>
          )}
        </div>
        <div className="lg:col-span-4 col-span-12">
          {dashboardData?.user_point_report?.length > 0 ? (
            <ChartTwo points={dashboardData?.user_point_report} isDarkMode={isDarkMode}/>
          ) : (
            <p className="flex items-center justify-center dark:text-white">
              User point Chart is not available at this moments
            </p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-12 mt-4 gap-4">
        <div className="lg:col-span-4 col-span-12">
          {dashboardData?.employee_kpi?.length > 0 ? (
            <ChartThree kpis={dashboardData?.employee_kpi} isDarkMode={isDarkMode}/>
          ) : (
            <p className="flex items-center justify-center dark:text-white">
              Employee kpi Chart is not available at this moments
            </p>
          )}
        </div>
        <div className="lg:col-span-8 col-span-12">
          {dashboardData?.allowances?.length > 0 ? (
            <ChartFour allowances={dashboardData?.allowances} isDarkMode={isDarkMode}/>
          ) : (
            <p className="flex items-center justify-center dark:text-white">
              Transport Allowances Chart is not available at this moments
            </p>
          )}
        </div>
      </div>

      <div className="mt-4">
        <ChartFive data={dashboardData?.client_transactions} isDarkMode={isDarkMode}/>
      </div>
      <div className="flex items-center justify-center dark:text-white">
        <p className="lg:text-sm text-xs">
          Copyright 2023 © Selopia All rights reserved. Powered by Selopia.
        </p>
      </div>
    </div>
  );
};

export default Home;
