import React from "react";
import './Loader.css'
const Loader = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center fixed top-0 left-0 bg-white opacity-75">
        <div className="loader"/>
    </div>
  );
};

export default Loader;
