 import React, { useRef, useState } from "react";
import image1 from "../../images/meet.jpg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import "./Login.css"
import { baseUrl, setTokenInCookie } from "../../Helper/helper";
import selopia from "../../images/logo.png";
import useData from "../../hooks/useData";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const {setToken} = useData().store
  const toast = useRef()
  const [showResetModal, setShowResetModal]  = useState(false)
  const [resetToken, setResetToken] = useState()
  const [resetData, setResetData] = useState({
    code: "",
    password: "", 
    confirm_password: ""
  })
  const handleChangeResetData = (e) => {
       setResetData({...resetData, [e.target.name]: e.target.value})
  }
  // const [email, setEmail] = useState("")

  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };
  const onSubmit = async (data) => {
   try {
    const res = await fetch(`${baseUrl.url}/api/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    const resData = await res.json();
    if (res.ok) {
      const { admin_access_token} = resData;
      setTokenInCookie(admin_access_token);
      setToken(admin_access_token)
      callToast(true, "Login Successful")
      setTimeout(() => {
        navigate("/");
        window.location.reload()
      }, 400)
   
    } else {
      callToast(false, resData?.error)
    }
   } catch (error) {
     callToast(false, error?.message)
   }
  };
  const handleReset = async() => {
    const email = document.getElementById("email").value;
    const postData = {
      email: email
    }
   if(email){
    try {
      const res = await fetch(`${baseUrl.url}/api/reset-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      });
  
      const resData = await res.json();
      if (res.ok) {
        const { reset_token} = resData;
        callToast(true, "Email send")
        setResetToken(reset_token)
        setTimeout(() => {
          setShowResetModal(true)
        }, 400)
     
      } else {
        callToast(false, resData?.error)
      }

     } catch (error) {
       callToast(false, error?.message)
     }
   }
   else{
    callToast(false, "Email is not provided")
   }
  }
  const handleResetSubmit = async(e) => {
    e.preventDefault()
    resetData.token = resetToken
    for(let i in resetData){
      if(!resetData[i]){
       
        if(i === "code"){
          callToast(false, "otp is required")
          return
        }
        else if(i === "password"){
          callToast(false, "New password is required")
          return
        }
        else if(i === "confirm_password"){
          callToast(false, "Confirm password is required")
          return
        }
      }
    }

   if(resetData?.password === resetData?.confirm_password){
    try {
      const res = await fetch(`${baseUrl.url}/api/confirm-password`, {
        method: "POST",
        headers: { 
        "Content-Type": "application/json" ,
      },
        body: JSON.stringify(resetData),
      });
  
      const resData = await res.json();
      if (res.ok) {
        const { reset_token} = resData;
        callToast(true, "Password reset successfully")
        setResetToken(reset_token)
        setTimeout(() => {
          setShowResetModal(false)
        }, 400)
     
      } else {
        callToast(false, resData?.error)
      }

     } catch (error) {
       callToast(false, error?.message)
     }
   }
   else{
    callToast(false, "Two password didn't match")
   }
   

  }

  return (
    <div>
      <Toast ref={toast}/>
      <div className="card flex flex-wrap justify-content-center w-full">
        <Dialog
          header="Reset Password"
          visible={showResetModal}
          className="w-11/12 sm:w-10/12 lg:w-8/12 xl:1/2 2xl:w-1/3"
          onHide={() => {
            setShowResetModal(false);
          }}
        >
         <form onSubmit={handleResetSubmit}>
         <div>
         <InputText
              name="code"
              onChange={(e) => handleChangeResetData(e)}
              className="w-full"
              placeholder='Otp'
            />
        
         </div>
         <div className='my-3'>
         <InputText
              name="password"
              onChange={(e) => handleChangeResetData(e)}
              type="password"
              className="w-full"
              placeholder='New Password'
            />
        
         </div>
         <div className='my-3'>
         <InputText
               name="confirm_password"
               onChange={(e) => handleChangeResetData(e)}
               type="password"
              className="w-full"
              placeholder='Confirm Password'
       
            />
        
         </div>
          <div  className='mt-4'>
          <Button type="submit" size="small" label="Submit" severity="info"/>
          </div>
         </form>
        </Dialog>
      </div>
      <div className="grid grid-cols-12">
        <div className="h-screen w-full hidden lg:flex relative lg:col-span-6 xl:col-span-5 2xl:col-span-6 flex items-center">
          <img src={"https://img.freepik.com/free-vector/customer-relationship-management-concept-illustration_114360-7652.jpg?w=826&t=st=1687669277~exp=1687669877~hmac=9579bee5440ba845446cb9e1a5fd69ce5e4976047533f03436390547e39bff84" || image1} className="w-full object-cover" alt="" />
          {/* <div className="bgBlur"></div> */}
        </div>

        <div className="h-screen col-span-12 lg:col-span-6 xl:col-span-7 2xl:col-span-6">
          <div className="flex justify-center items-center h-full w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="lg:shadow-lg xl:w-1/2 lg:w-2/3 md:w-8/12 w-11/12 lg:p-8 p-4 bg-white rounded"
            >
              <div className="flex justify-center w-full">
                <div className="h-24 w-24 my-4">
                  <img src={selopia} alt="logo" className="h-full w-full" />
                </div>
              </div>
              <div>
                <p className="text-5xl md:text-xl text-center tracking-wide lg:tracking-widest font-bold -mt-8 lg:mt-0">
                  <span className="text-violet-700">C</span>
                  <span className="text-cyan-500">M</span>
                  <span className="text-orange-500">S</span>
                </p>
                <p className="text-xs text-center">
                  Custommer Management System
                </p>
              </div>
              <div className="flex flex-col">
                <label className="py-2">E-mail</label>
                <input
                   {...register("email", {
                    required: {
                      value: true,
                      message: "Email is Required",
                    },
                  })}
                  id="email"
                  // type="email"
                  className="rounded p-2 focus:outline-0 bg-sky-50 sm:bg-white text-black"
                  placeholder="Email / Phone"
                  //  onChange={(e) =>setEmail(e.target.value)}
                />

                {errors.email?.type === "required" && (
                  <span className="label-text-alt text-xs text-red-600">
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col pt-4 relative">
                <label className="pb-2">Password</label>
                <input
                  type={show ? "text" : "password"}
                  className="rounded p-2 focus:outline-0 bg-sky-50 sm:bg-white text-black"
                  placeholder="Password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password is Required",
                    },
                  })}
                />
                <div
                  onClick={() => setShow(!show)}
                  className="absolute right-2 bottom-4 cursor-pointer"
                >
                  {!show ? <BsEyeSlash /> : <BsEye />}
                </div>
                {errors.password?.type === "required" && (
                  <span className="label-text-alt text-xs text-red-600">
                    {errors.password.message}
                  </span>
                )}
              </div>
              <div className=" flex justify-center ">
                <input
                  type="submit"
                  value="Get Started"
                  className=" w-full p-2 bg-blue-600 rounded mt-6 text-white cursor-pointer"
                />
              </div>
              <div className="mt-2">
                <p className="text-sm">Forgot password? <span onClick={handleReset} className="cursor-pointer underline text-sky-500">Reset password</span></p>
              </div>
              <div>
                <p className="text-xs text-center mt-6">
                  Copyrights 2022-23 © Selopia
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
