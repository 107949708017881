import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import usePaymentTypes from "../../hooks/usePaymentTypes";
import { baseUrl, checkAvailability } from "../../Helper/helper";
import useData from "../../hooks/useData";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";


const CreateTransactionModal = ({ visible, setVisible, toast, callToast, update, setUpdate }) => {
  const [paymentTypes] = usePaymentTypes();
  const [confirmClients, setConfirmClients] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [date, setDate] = useState(null)
  const [searchValue, setSearchValue] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [amount, setAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [transactionNo, setTransactionNo] = useState("");
  const { token } = useData().store;
  const { authPermissions } = useData().permissions;
  const IsGetClient = checkAvailability(authPermissions, "get-client-list");


  const selectedClientTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option?.code?.toLowerCase()}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const clientOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option?.code?.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };
  useEffect(() => {
    if (!IsGetClient) {
      return;
    }

    const clients = async () => {
      try {
        const res = await fetch(
          `${baseUrl.url}/api/clients?confirmed=1${
            searchValue && `&search=${searchValue}`
          }&per_page=10`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();

        if (res.ok) {
          setConfirmClients(data?.data?.data);
        }
      } catch (err) {
        console.error(err?.message);
      }
    };
    clients().then();
  }, [token, searchValue, IsGetClient]);

  const handleSubmit = async() => {
    if(!date){
        callToast(false, "Date is required")
        return
    }
    const modifyDate = date?.split("T").join(" ");
    const postData = {
     occurred_on: modifyDate,
      client_id: selectedClient?.id,
      payment_type_id: selectedPaymentType?.id,
      amount: amount,
      transaction_id: transactionNo,
      remarks: remarks ? remarks : ""
    };
    for (let i in postData) {
      if (!postData[i]) {
        if (i === "client_id") {
          callToast(false, "Client id is required");
          return;
        } else if (i === "payment_type_id") {
          callToast(false, "Payment type is required");
          return;
        }else if (i === "amount") {
            callToast(false, "Amount is required");
            return;
          } else if (i === "transaction_id") {
          if (selectedPaymentType?.id !== 1) {
            callToast(false, "Transaction number is required");
            return;
          }
        }
      }
    }
    try{
        const res = await fetch(`${baseUrl?.url}/api/client-transactions`, {
            method: "POST",
            headers: {
                "content-type": "Application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(postData)
        })
        const resData = await res.json()
        if(res.ok){
            setUpdate(!update)
            callToast(true, "Transaction created successfully")
            setVisible(false)
        }
        else{
            callToast(false, resData?.error)
        }
    }
    catch(error){
    }
  };

  return (
    <div className="card flex flex-wrap justify-content-center w-full">
     <Toast ref={toast}/>
      <Dialog
        header="Create Transaction"
        visible={visible}
        className="w-11/12 sm:w-10/12 lg:w-5/12 "
        onHide={() => {
          setVisible(false);
        }}
      >
        <div>
        <div className="card">
        <label className="text-gray-500 text-sm py-2 font-semibold">
               Date
              </label>
            <input
            type="datetime-local"
              onChange={(e) => setDate(e.target.value)}
              className="w-full focus:outline-none"
              placeholder="select date"
            />
          </div>
          <div>
            <label className="text-gray-500 text-sm py-2 font-semibold">
              Clients
            </label>
            <Dropdown
              value={selectedClient}
              onChange={(e) => {
                setSelectedClient(e.target.value);
                //   setSearchValue("")
              }}
              onFilter={(e) => setSearchValue(e.filter)}
              optionLabel="name"
              placeholder={"Select Client"}
              className="w-full dark:bg-transparent"
              filter
              filterPlaceholder="Search"
              valueTemplate={selectedClientTemplate}
              itemTemplate={clientOptionTemplate}
              options={confirmClients}
            />
          </div>
          <div className="my-1">
            <label className="text-gray-500 text-sm py-2 font-semibold">
              Payment Types
            </label>
            <Dropdown
              optionLabel="name"
              className="w-full my-3  dark:bg-gray-800"
              value={selectedPaymentType}
              onChange={(e) => setSelectedPaymentType(e.value)}
              options={paymentTypes}
              placeholder="Select payment type"
            />
          </div>
          <div className="my-1">
            <label className="text-gray-500 text-sm py-2 font-semibold">
              Amount
            </label>
            <InputText
              id="amount"
              type="number"
              onChange={(e) => setAmount(e.target.value)}
              placeholder="amount"
              className="w-full  dark:bg-gray-800 dark:text-white"
            />
          </div>
          {selectedPaymentType && selectedPaymentType?.id !== 1 && (
            <div className="my-1">
              <label className="text-gray-500 text-sm py-2 font-semibold">
                Transaction Number
              </label>
              <InputText
                type="number"
                onChange={(e) => setTransactionNo(e.target.value)}
                className="w-full dark:bg-gray-800 dark:text-white"
                placeholder="Transaction Number"
              />
            </div>
          )}
             <div className="my-1">
              <label className="text-gray-500 text-sm py-2 font-semibold">
               Notes
              </label>
              <InputTextarea
                type='text'
                onChange={(e) => setRemarks(e.target.value)}
                className="w-full"
                placeholder='type here'
                rows={3} cols={30}
              />
            </div>
          <div className="mt-3">
            <Button
              type="submit"
              onClick={handleSubmit}
              size="small"
              label="Submit"
              severity="info"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateTransactionModal;
