import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { baseUrl} from "../../Helper/helper";
import { Toast } from "primereact/toast";
import useData from "../../hooks/useData";

const AddDocumentModal = ({
  visible,
  setVisible,
  obj,
  setObj,
  update,
  setUpdate,
}) => {
  const [document, setDocument] = useState();
  const {token} = useData().store
  const toast = useRef();
  const [image, setImage] = useState();
  const callToast = (type, detail) => {
    toast.current?.show({
      severity: type ? "success" : "error",
      summary: type ? "Success" : "Error",
      detail: detail,
      life: 3000,
    });
  };
  const handleImage = (e) => {
    setDocument(e.target.files);
    const file = e.target.files[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setImage(fileReader.result);
      };
    }
  };
  const handleDocument = async () => {
    let formData = new FormData();
    if (document?.length > 0) {
      formData.append(`document`, document[0]);
       try{
        const res = await fetch(
          `${baseUrl.url}/api/clients-document-update/${obj?.id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const resData = await res.json();
        if (res.ok) {
          callToast(true, "Document Added Successfully");
          setTimeout(() => {
            setVisible(false);
            setUpdate(!update);
          }, 400);
        } else {
          callToast(false, resData?.error);
        }
       }
       catch(err){
        callToast(false, err?.message)
       }
    }

  };
  return (
    <div className="card flex flex-wrap justify-content-center w-full">
      <Toast ref={toast} />
      <Dialog
        header="Add Document"
        visible={visible}
        className="w-11/12 sm:w-10/12 lg:w-5/12 xl:w-1/4"
        onHide={() => {
          setVisible(false);
          setObj({});
        }}
      >
        {obj?.document && (
          <div className="w-1/2 h-32 mb-5">
            <img
              className="w-full h-full"
              src={`${image ? image : `${baseUrl.url}${obj?.document}`}`}
              alt="document"
            />
          </div>
        )}
        <div className="">
          <p className="block sm:flex items-center gap-3 mb-4">
           <span className="text-gray-500 text-sm p-2 rounded-sm whitespace-nowrap block font-semibold">Upload File:</span>  <input type="file" onChange={(e) => handleImage(e)} />
          </p>

          <Button onClick={handleDocument} size="small" label="Submit" severity="info" />
        </div>
      </Dialog>
    </div>
  );
};

export default AddDocumentModal;
